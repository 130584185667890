import "../../../style/login.css";
import "firebase/messaging";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Footer from "../../Footer";
import api from "../../../Api/AuthApi";
import agentApi from "../../../Api/Agent/AgentAuth";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AgentForgotPassword from "./AgentForgotPassword";
import AgentOtpValidation from "./AgentOtpValidation";
import AgentResetPassword from "./AgentResetPassword";
import { firebaseApp, generateToken } from "../../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  getFcmToken,
  setFCMToken,
} from "../../../redux/features/profile";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { Helmet } from "react-helmet";
import Header from "../Header";

const AgentLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fcmToken = useSelector(getFcmToken);
  const firebaseDB = getFirestore(firebaseApp);
  const [loginData, setLoginData] = useState({
    userName: "",
    userNameError: "",
    password: "",
    passwordError: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState({
    status: false,
    message: "",
  });
  const [modalType, setModalType] = useState("");
  const [otpExpiration, setOtpExpiration] = useState(0);
  const [forgotPassword, setForgotPassword] = useState({
    user: "",
    authToken: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/agent/login");
      return;
    } else {
      navigate("/agent");
    }
  }, [navigate]);

  useEffect(() => {
    handleFCMToken();
  }, []);

  const handleFCMToken = async () => {
    await generateToken().then((data) => {
      dispatch(setFCMToken(data));
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUser = (e) => {
    let { name, value } = e.target;
    if (name === "username") {
      if (value) {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "",
        }));
      } else {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "Please enter Mobile number/Email id",
        }));
      }

      setLoginData((prevValue) => ({
        ...prevValue,
        userName: value,
      }));
    } else if (name === "password") {
      if (value) {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "",
        }));
      } else {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "Please enter your password",
        }));
      }

      setLoginData((prevValue) => ({
        ...prevValue,
        password: value,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (!loginData?.userName) {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "Please enter Mobile number/Email id",
        }));
      }

      if (!loginData?.password) {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "Please enter your password",
        }));
      }

      if (loginData?.userName && loginData?.password) {
        const payload = {
          userName: loginData?.userName,
          password: loginData?.password,
          fcmtoken: fcmToken,
        };
        agentApi.login(payload).then(async ({ data }) => {
          if (data.status) {
            console.log("data", data?.data);
            localStorage.setItem("authToken", data?.data);
            // dispatch(setProfileDetails({ profile_id: data?.data?.userCode }));
            // await updateOnlineStatus(data?.data?.userCode);
            navigate("/agent", { state: { token: data?.data } });
          } else {
            setLoginError({
              status: true,
              message: data?.message,
            });
          }
        });
      }
    } catch (err) {
      console.log("err", err);
      setLoginError({
        status: true,
        message: "Sorry, something went wrong",
      });
    }
  };

  const updateOnlineStatus = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Agent Login | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <div className="registerbody">
        <Header />
        <section className="loginbody">
          <MDBContainer className="my-5 loginbackground">
            <MDBCard className="loginregister rounded-4">
              <MDBRow className="g-0 loginonly">
                <MDBCol
                  md="6"
                  className="agent-loginform py-2 order-2 order-md-1"
                >
                  <MDBCardBody className="d-flex flex-column">
                    <Form className="formfull">
                      <MDBContainer className="d-flex flex-column">
                        <h3
                          className="fw-normal"
                          style={{
                            letterSpacing: "1px",
                            color: "#65044A",
                            fontSize: "22px",
                            marginBottom: "69px",
                          }}
                        >
                          Agent Login
                        </h3>
                        <MDBInput
                          wrapperClass="mb-3 custom-input-agent-bottom-border"
                          className="mailid"
                          label="Email ID/Mobile Number"
                          id="username"
                          type="text"
                          size="lg"
                          name="username"
                          value={loginData?.userName}
                          onChange={handleUser}
                        />
                        {loginData?.userNameError && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "12px", lineHeight: "1" }}
                          >
                            {loginData?.userNameError}
                          </p>
                        )}

                        <div style={{ position: "relative" }}>
                          <MDBInput
                            wrapperClass="mb-2"
                            label="Password"
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            size="lg"
                            value={loginData?.password}
                            onChange={handleUser}
                          />
                          <span
                            className="agent-password-toggle-icon-login"
                            onClick={handleTogglePassword}
                            style={{
                              position: "absolute",
                              top: "48%",
                              color: "#65044A",
                              right: 17,
                              transform: "translateY(-70%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? <BsEyeSlash /> : <BsEye />}
                          </span>
                        </div>
                        {loginData?.passwordError && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "12px", lineHeight: "1" }}
                          >
                            {loginData?.passwordError}
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          <MDBBtn
                            type="submit"
                            className="mb-2 mt-4 loginsection agent-login-custom-btn"
                            onClick={onSubmit}
                          >
                            Login
                          </MDBBtn>
                        </div>
                        {loginError.status && (
                          <p className="text-danger">{loginError.message}</p>
                        )}
                      </MDBContainer>
                    </Form>
                    <MDBBtn
                      onClick={() => setModalType("forgot")}
                      className="small agent-smollforgot"
                    >
                      <span>
                        Forgot{" "}
                        <span style={{ color: "#0AB93B" }}>Password?</span>
                      </span>
                    </MDBBtn>

                    <div className="d-flex justify-content-center">
                      <MDBBtn
                        className="agent-login-register-btn agent-login-custom-btn"
                        onClick={() => navigate("/agent/register")}
                      >
                        Register
                      </MDBBtn>
                    </div>

                    {/* modals */}
                    {modalType === "forgot" && (
                      <AgentForgotPassword
                        modalType={modalType}
                        setModalType={setModalType}
                        setOtpExpiration={setOtpExpiration}
                        setForgotPassword={setForgotPassword}
                      />
                    )}
                    {modalType === "otp" && (
                      <AgentOtpValidation
                        modalType={modalType}
                        setModalType={setModalType}
                        otpExpiration={otpExpiration}
                        setOtpExpiration={setOtpExpiration}
                        forgotPassword={forgotPassword}
                        setForgotPassword={setForgotPassword}
                      />
                    )}
                    {modalType === "password" && (
                      <AgentResetPassword
                        modalType={modalType}
                        setModalType={setModalType}
                        forgotPassword={forgotPassword}
                      />
                    )}
                    {modalType === "success" && (
                      <MDBModal
                        show={modalType === "success" ? true : false}
                        onHide={() => {
                          setModalType("");
                        }}
                        tabIndex="-1"
                        className="model-full"
                      >
                        <MDBModalDialog>
                          <MDBModalContent>
                            <MDBModalHeader className="forgotpassword-header">
                              <div className="forgot-header">
                                <MDBModalTitle>Successfully</MDBModalTitle>
                              </div>
                              <MDBBtn
                                className="btn-close btn-close-white"
                                color="none"
                                onClick={() => setModalType("")}
                              ></MDBBtn>
                            </MDBModalHeader>
                            <div className="my-3">
                              <p
                                className="password-reset"
                                style={{ color: "black", textAlign: "center" }}
                              >
                                Your password has been reset. Please Login
                              </p>
                            </div>
                          </MDBModalContent>
                        </MDBModalDialog>
                      </MDBModal>
                    )}
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md="6" className="position-relative order-1 order-md-2">
                  <MDBCardImage
                    src="../Images/agent/login.png"
                    alt="login form"
                    className="rounded w-100 position-relative"
                  />
                  <MDBCardImage
                    src="../Images/agent/logo.png"
                    alt="login-logo"
                    className="position-absolute top-50 start-50 translate-middle w"
                    style={{
                      width: "340px",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBContainer>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default AgentLogin;
