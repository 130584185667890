import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "./CanvasUtils";
import { Button, Slider, Typography } from "@mui/material";
import { CropStyle } from "./CropStyle";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const CropPhoto = ({ selectedImage, setCropedImage, onClose, from, handleSubmit }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //const [croppedImage, setCroppedImage] = useState(null);
  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = async () => {
    let imageDataUrl = selectedImage?.imageUrl;
    // try {
    //   const orientation = await getOrientation(selectedImage?.file);
    //   const rotation = ORIENTATION_TO_ANGLE[orientation];
    //   if (rotation) {
    //     imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
    //   }
    // } catch (e) {
    //   console.warn("failed to detect the orientation");
    // }
    setImageSrc(imageDataUrl);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      // Convert blob to base64
      setCropedImage(croppedImage);
      if (from==="list") {
        handleSubmit(croppedImage)
      }
      onClose()
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className="modal fade show d-flex justify-content-center"
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog"
        role="document"
        style={{ width: "400px", color: "#fff" }}
      >
        <div className="modal-content" style={{ backgroundColor: "#282828" }}>
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              type="button"
              className="close border-0"
              style={{ color: "white", background: "none" }}
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {imageSrc ? (
              <React.Fragment>
                <div className="crop-container">
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 6}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="crop-controls">
                  <div className="crop-slider-container">
                    <Typography
                      variant="overline"
                      className="crop-slider-label"
                    >
                      Zoom
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      className="crop-slider"
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className="crop-slider-container">
                    <Typography
                      variant="overline"
                      //   className="crop-slider-label"
                    >
                      Rotation
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      className="crop-slider"
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </div>
                  <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    className="crop-button"
                  >
                    Save
                  </Button>
                </div>
                {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
              </React.Fragment>
            ) : (
              <input type="file" accept="image/*" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropPhoto;
