import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userDetails: {},
  noteCount: 0,
  fcmToken: "",
  counts: {},
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.noteCount = action.payload;
    },
    setFCMToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setInteractionCounts: (state, action) => {
      state.counts = action.payload;
    },
  },
});

export const {
  setProfileDetails,
  setNotificationCount,
  setFCMToken,
  setInteractionCounts,
} = profileSlice.actions;

export const getProfileDetails = (state) => state.profile.userDetails;
export const getNotificationCount = (state) => state.profile.noteCount;
export const getFcmToken = (state) => state.profile.fcmToken;
export const getInteractionCount = (state) => state.profile.counts;

export default profileSlice.reducer;
