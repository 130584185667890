import { axios, constants } from "../Config";

class StoryApiFetch {
  getStory(payload, token) {
    let config = {
      headers: {}
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return axios
      .post(`${constants.baseURL}view-success-stories`, payload, config)      
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  postStory(payload, token) {
    return axios
      .post(`${constants.baseURL}success-stories`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getPostStoryPayloads(token) {
    return axios
      .get(`${constants.baseURL}success-stories-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}

export default new StoryApiFetch();
