import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  useAccordionButton,
} from "react-bootstrap";
/* import "bootstrap/dist/css/bootstrap.min.css"; */

function CustomToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  return (
    <button
      type="button"
      style={{
        border: "none",
        backgroundColor: "transparent",
        marginLeft: "auto",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function FAQItem({ question, answer, eventKey, activeKey }) {
  const isOpen = eventKey === activeKey;

  return (
    <Card className="mb-2 ">
      <Card.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: isOpen ? "bold" : "normal" }}>
          {question}
        </span>
        <CustomToggle eventKey={eventKey}>
          {isOpen ? "-" : "+"}
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <div dangerouslySetInnerHTML={{ __html: answer }} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

function FAQSection() {
  const [activeKey, setActiveKey] = useState("0"); // Set the first FAQ item as opened

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey); // Toggle between open and closed states
  };

  return (
    <Container className="mt-5">
      <div className="searchprofile">
        <h4>FAQs</h4>
      </div>
      <Row>
        <Col md={6}>
          <Accordion activeKey={activeKey} onSelect={handleToggle}>
            <FAQItem
              question="1. How to register?"
              answer={`
                        Go to <span>https://ibgmatrimony.com/</span><br />
                        You can choose to sign up as a free member.<br />
                        For free registration : <br />
                        Enter your name and mobile number. Click the Register link, and then you'll need to provide details about the person seeking a marriage partner across a few pages.
                        Once you submit your information, your profile will be automatically created, and you'll receive a 'Matrimony ID' for future reference and login.
                    `}
              eventKey="0"
              activeKey={activeKey}
            />
            <FAQItem
              question="2. How do I log in?"
              answer={`
                Log in from the Home page and enter your Matrimony ID and password into the respective boxes at the top band and click Login button.`}
              eventKey="1"
              activeKey={activeKey}
            />
            <FAQItem
              question="3. Profile description - what to enter?"
              answer={`
                What should I include in the profile description field?
                Your profile description is your opportunity to showcase who you are. This is where you should detail your personality, preferences, educational and family background, career, hobbies, and what you’re looking for in a partner. Aim to provide a clear and comprehensive overview of yourself and your expectations. A well-crafted description will attract more responses. Refer to the sample profiles below, which are concise, organized, and include essential information.
                Can I see some sample profiles?

                Sample 1:
                I'm a sociable and cheerful individual with a pleasant demeanour. I hold a Bachelor's degree in Dental Surgery and have been working as an Associate Doctor at a private clinic in Chennai for the past three years. My interests include listening to a variety of soothing music and traveling.
                Our family is open-minded and religious. My father is a retired Assistant Engineer from the KSEB, and my mother is a devoted and loving homemaker. I have one older brother, he is married and serves as an Engineer in TCS.
                I am looking for a partner who is intelligent, compassionate, open-minded, and possesses a good sense of humour. I believe that love, trust, understanding, and commitment are the foundation of a strong relationship, and I am seeking someone who shares these values.

                `}
              eventKey="2"
              activeKey={activeKey}
            />
            <FAQItem
              question="4.	What all information from my profile will be displayed to other members?"
              answer={`Free members can view only some of your details. They cannot view your mobile number, horoscope, etc. Paid members can view all your details. `}
              eventKey="3"
              activeKey={activeKey}
            />

            <FAQItem
              question="5.	Search results"
              answer={`This is a broad search that considers factors such as age, height, marital status, regional location, religion, caste/division, education etc the date of the posted results. `}
              eventKey="5"
              activeKey={activeKey}
            />
            <FAQItem
              question="6.	Search by Member ID"
              answer={`
               Enter the Matrimony ID of the member whose profile you would like to see. Click on View Profile link.  `}
              eventKey="6"
              activeKey={activeKey}
            />
            <FAQItem
              question="7.	How do I view a particular profile?"
              answer={`You can find and view a specific profile by entering the Member ID in the 'Search by ID' field. `}
              eventKey="7"
              activeKey={activeKey}
            />
            <FAQItem
              question="8. What is partner preference?"
              answer={`Partner Preference is a feature that helps you define your ideal spouse and set your suitability criteria.`}
              eventKey="8"
              activeKey={activeKey}
            />
          </Accordion>
        </Col>
        <Col md={6}>
        <Accordion activeKey={activeKey} onSelect={handleToggle}>
            <FAQItem
              question="9. 	What are Short-list profiles?"
              answer={`
                Log in with your 'Matrimony ID' and 'Password'. Choose the "Search" option, and enter the member's ID in the 'By Member's Matrimony ID' field. Click the "Short-list" option. The profile will be added to your list of short-listed profiles. `}
              eventKey="9"
              activeKey={activeKey}
            />
            <FAQItem
              question="10.Block Profiles"
              answer={`
             Log in with your 'Matrimony/e-mail ID' and 'Password.' You will then see options to block profiles. These profiles will be added to your 'Block List.' This means that these members will be unable to contact you or show interest in your profile, and similarly, you will not be able to reach out to them. `}
              eventKey="10"
              activeKey={activeKey}
            />
            <FAQItem
              question="11.	How to make a profile complete?"
              answer={`A brief review of the following topics will assist you in adding photos, horoscope, voice profile, references, and other elements to ensure your profile is complete.`}
              eventKey="11"
              activeKey={activeKey}
            />
            <FAQItem
              question="12.	Photos details"
              answer={`
                How many photos can I add?

                You can add up to ten photos to your profile.
                Photo must be a .gif, .jpg format, no larger than 20MB.

                How to upload photos?

                Login using your Matrimony ID and Password.
                Click on the Add/Edit Photo link.
                In the Add Photo page, click on the Browse button.
                Select your photo in jpg/gif format and click Upload Photo.
                Crop your photo as required to show the image appropriately.

                `}
              eventKey="12"
              activeKey={activeKey}
            />

            <FAQItem
              question="13.	Show interest"
              answer={`The 'Express Interest' feature allows you to send automated messages to members at no cost, letting them know that you are interested in them.
                        You can also login to your account and select the 'Interested Profile and Interested Received option  to know how many members have accepted/declined your interest.
                    `}
              eventKey="13"
              activeKey={activeKey}
            />

            <FAQItem
              question="14.	Change Password"
              answer={`
                        You can change your Password. Go to Profile Settings < Change Password >.
                        Enter Current Password, then New password and Confirm the New Password.
                        Then Click on Change password button.
                    `}
              eventKey="14"
              activeKey={activeKey}
            />

            <FAQItem
              question="15.	How do I contact customer support?"
              answer={`
                         Click Contact us link to find out about our contact details where you can send the messages/email/directly contact us.
                    `}
              eventKey="15"
              activeKey={activeKey}
            />
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default FAQSection;
