import React, { useEffect, useState } from "react";
import api from "../../../../Api/InterationApi";
import ReactPaginate from "react-paginate";
import Chat from "../../../Chat";
import UserCard from "./UserCard";
import GeneralApi from "../../../../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getInteractionCount, setInteractionCounts } from "../../../../redux/features/profile";

function AcceptedProfile() {
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch()
  const getCounts = useSelector(getInteractionCount)
  const [acceptedProfile, setAcceptedProfile] = useState("");
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchAcceptedProfile();
  }, [pageDetails]);

  const fetchAcceptedProfile = async () => {
    const interactionCounts = {
      ...getCounts,
      acceptedProfiles: 0
    }
    dispatch(setInteractionCounts(interactionCounts))
    setAcceptedProfile([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getAcceptedProfile(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setAcceptedProfile(data?.data?.interest);
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  const handleBlockUser = () => {
    GeneralApi.blockUser(chatData?.userId, token).then(({ data }) => {
      if (data?.status) {
        const newAcceptedProfile = acceptedProfile.filter(
          (user) => user.profile_code !== chatData?.userId
        );
        setAcceptedProfile(newAcceptedProfile);
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setChatData({
          status: false,
          userId: "",
          name: "",
          image: "",
        });
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pictures-client">
        {acceptedProfile?.length > 0 ? (
          acceptedProfile.map((interation, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={{
                  name: interation?.name,
                  userId: interation?.profile_code,
                  image: interation?.profile_image,
                  createdBy: interation?.profile_created_for,
                  age: interation?.age,
                  height_cm: interation?.height_cm,
                  height_feet: interation?.height_feet,
                  religion: interation?.religion,
                  education: interation?.education,
                  district: interation?.district,
                  date: interation?.date,
                  occupation: interation?.occupation,
                }}
                chatData={chatData}
                setChatData={setChatData}
              />
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              {" "}
              No data found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from="PROFILE"
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
}

export default AcceptedProfile;
