import React, { useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import ProfileSideBar from "../ProfileSideBar";
import Footerinner from "../Footerinner";
import { MDBRow } from "mdb-react-ui-kit";
import { Container } from "react-bootstrap";
import InterationSection from "./InterationSection";

const Interations = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  return (
    <section className="matches-background">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      <Container>
        <MDBRow className="myhome-matches-all">
          <ProfileSideBar
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
          <InterationSection
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            titleDiscription={titleDiscription}
            setTitleDiscription={setTitleDiscription}
          />
        </MDBRow>
      </Container>
      <Footerinner />
    </section>
  );
};

export default Interations;
