import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MDBContainer } from "mdb-react-ui-kit";
import "../../style/successpage.css";
import api from "../../Api/StoryApi";

function Post({ setSelectTab }) {
  const token = localStorage.getItem("authToken");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [description, setDescription] = useState("");
  const [validationError, setValidationError] = useState("");
  const [groomName, setGroomName] = useState("");
  const [groomNameError, setGroomNameError] = useState("");
  const [brideName, setBrideName] = useState("");
  const [brideNameError, setBrideNameError] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [photoValidationError, setPhotoValidationError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const validationSchema = yup.object().shape({});
  const { register } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const isAllowedFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    return allowedTypes.includes(file.type);
  };
  const isFileSizeValid = (file) => {
    const maxSize = 5 * 1024 * 1024;
    return file.size <= maxSize;
  };

  useEffect(() => {
    getDatas()
  }, []);

  const getDatas = () => {
    api.getPostStoryPayloads(token).then(({ data }) => {
      if (data.status) {
        setStateList(data?.data?.stateDistrict)
      }
    }).catch((err) => {
      console.log("err", err)
    })
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!brideName) {
      setBrideNameError("Please enter bride name");
    }
    if (!groomName) {
      setGroomNameError("Please enter groom name");
    }
    if (!description) {
      setValidationError("Please enter description");
    }
    if (!selectedPhoto) {
      setPhotoValidationError("Please upload your photo");
    }
    if (!selectedState) {
      setStateError("Please select a state"); // Set state error message
    }
    if (!selectedCity) {
      setCityError("Please select your city");
    }
    if (brideName && groomName && description && selectedPhoto && selectedCity && selectedState) {
      try {
        let newformData = new FormData();
        newformData.append("bname", brideName);
        newformData.append("gname", groomName);
        newformData.append("state", selectedState);
        newformData.append("city", selectedCity);
        newformData.append("photo", selectedPhoto);
        newformData.append("description", description);

        await api.postStory(newformData, token).then(({ data }) => {
          if (data.status) {
            setBrideName("");
            setGroomName("");
            setSelectedState("")
            setSelectedCity("")
            setSelectedPhoto("");
            setSuccessMessage("");
            setShowSuccessModal(true);
          }
        });
      } catch (error) {
        setSuccessMessage(`Error in uploading: ${error.message}`);
        setShowSuccessModal(true);
        console.error("Error:", error);
      }
    }
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessMessage("");
    setSelectTab("");
  };

  const handleCityChange = (event) => {

    setCityError("");
    const selectedCityValue = event.target.value;
    setSelectedCity(selectedCityValue);
  };
  const handleStateChange = (event) => {
    setStateError("");
    const selectedStateValue = event.target.value;
    setSelectedState(selectedStateValue);
    setSelectedCity("")
    const findState = stateList.find(state => state.states === selectedStateValue)
    if (findState) {
      setCityList([...findState.districts])
    } else {
      setCityList([])
    }
  };
  const handlePhotoChange = (event) => {
    const selectedPhoto = event.target.files[0];
    if (!selectedPhoto) {
      setPhotoValidationError("Please upload your photo");
    } else {
      const file = event.target.files[0];
      if (!isAllowedFileType(file)) {
        setPhotoValidationError(
          "Only .jpeg, .jpg, and .png image files are allowed."
        );
        return;
      }
      if (!isFileSizeValid(file)) {
        setPhotoValidationError("File size should be no larger than 5MB.");
        return;
      }
      setPhotoValidationError("");
      setSelectedPhoto(selectedPhoto);
    }
  };


  //description validation//
  const handleChangeDescition = (e) => {
    const { value } = e.target;
    setDescription(value);

    if (!value.trim()) {
      setValidationError("Please enter description");
    } else {
      setValidationError("");
    }
  };
  //groomname//
  const handleChangeGroomName = (e) => {
    const { value } = e.target;
    const maxLength = 25;
    setGroomName(value);
    // Add your validation logic here
    if (!value.trim()) {
      setGroomNameError("Please enter groom name");
    } else if (value.length > maxLength) {
      setGroomNameError(`Maximum characters limit ${maxLength} `);
    } else {
      setGroomNameError("");
    }
  };
  //bridename//
  const handleChangeBrideName = (e) => {
    const { value } = e.target;
    const maxLength = 25;
    setBrideName(value);

    if (!value.trim()) {
      setBrideNameError("Please enter bride name");
    } else if (value.length > maxLength) {
      setBrideNameError(`Maximum characters limit ${maxLength} `);
    } else {
      setBrideNameError("");
    }
  };
  return (
    <div>
      <MDBContainer className="post-sucessbody">
        <div>
          <div className="success-heading">
            <h3 className="mt-5 mb-4"> Post Your Success Story</h3>
            <Container>
              <form>
                <Row className="registerone-postsucess">
                  <Form>
                    <Row
                      className="post-your-sucess justify-content-between"
                      style={{ margin: "0px 1.75rem", marginBottom: "1rem" }}
                    >
                      <Col size="6" sm="5" className="register-aligns">
                        <Form.Group className="mb-3" controlId="bname">
                          <Form.Control
                            type="text"
                            placeholder="Bride Name (Female) *"
                            className="SelectBoxs"
                            style={{ width: 420 }}
                            value={brideName}
                            onChange={handleChangeBrideName}
                          />
                          {brideNameError && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingBottom: "2%",
                                textAlign: "left",
                              }}
                            >
                              {brideNameError}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col size="6" sm="5" className="register-aligns">
                        <Form.Group className="mb-3" controlId="groomName">
                          <Form.Control
                            type="text"
                            placeholder="Groom Name (Male)*"
                            className="SelectBoxs"
                            style={{ width: 420 }}
                            value={groomName}
                            onChange={handleChangeGroomName}
                          />
                          {groomNameError && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingBottom: "2%",
                                textAlign: "left",
                              }}
                            >
                              {groomNameError}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row
                      className="post-your-sucess justify-content-between"
                      style={{ margin: "0px 1.75rem", marginBottom: "1rem" }}
                    >
                      <Col size="6" sm="5" className="register-aligns">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <select
                            id="state"
                            name="state"
                            className="SelectBoxs-dropdown"
                            style={{ width: 420 }}
                            {...register("state")}
                            onChange={handleStateChange}
                          >
                            <option key="" value="">
                              Select State*
                            </option>
                            {stateList.map((stateItem, index) => (
                              <option
                                key={index}
                                value={stateItem.states}
                              >
                                {stateItem.states}
                              </option>
                            ))}
                          </select>
                          {stateError && <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingBottom: "2%",
                              textAlign: "left",
                            }}
                          >
                            {stateError}
                          </div>}
                        </Form.Group>
                      </Col>
                      <Col size="6" sm="5" className="register-aligns">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <select
                            id="city"
                            name="city"
                            className="SelectBoxs-dropdown"
                            style={{ width: 420 }}
                            {...register("city")}
                            onChange={handleCityChange}
                          >
                            <option key="" value="">
                              Select City*
                            </option>
                            {cityList.map((city, index) => (
                              <option
                                key={index}
                                value={city.district}
                              >
                                {city.district}
                              </option>
                            ))}
                          </select>

                          {cityError && <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingBottom: "2%",
                              textAlign: "left",

                            }}
                          >
                            {cityError}
                          </div>}

                        </Form.Group>

                      </Col>
                    </Row>
                    <Row
                      className="post-your-sucess justify-content-between"
                      style={{ margin: "0px 1.75rem", marginBottom: "1rem" }}
                    >
                      <Col size="6" sm="5" className="register-aligns">
                        <Form.Group className="mb-3" controlId="bname">
                          <Form.Control
                            type="text"
                            placeholder="Description *"
                            className="SelectBoxs"
                            style={{ width: 420 }}
                            value={description}
                            onChange={handleChangeDescition}
                          />
                          {validationError && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingBottom: "2%",
                              }}
                            >
                              {validationError}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col size="6" sm="5" className="register-aligns">
                        <div className="upload-photo">
                          <label htmlFor="formFileSm" className="upload-label">
                            Upload Photo*
                          </label>
                          <div className="custom-file-input ">
                            <span className="custom-browse-button ">
                              Choose File
                            </span>
                            <input
                              type="file"
                              id="formFileSm"
                              className="upload-input"
                              accept="image/*"
                              onChange={handlePhotoChange}
                            />
                          </div>
                        </div>
                        <div className="SelectBoxs"></div>
                        {selectedPhoto && (
                          <p className="selected" style={{ textAlign: "left" }}>
                            File: {selectedPhoto.name}
                          </p>
                        )}
                        {photoValidationError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            {photoValidationError}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                  <div className="d-flex justify-content-center post-sucess-submit mt-4  ">
                    <Button
                      className="searchButtons-postsucess"
                      type="submit"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Row>
              </form>
            </Container>
          </div>
        </div>
      </MDBContainer>
      {/* Success Popup*/}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <div className="postsuccesss">
          <Modal.Header closeButton>
            <Modal.Title className="postsucess-title text-center">
              Success
            </Modal.Title>
          </Modal.Header>
        </div>
        <Modal.Body>
          <p className="successMessage">{successMessage}Successfully added your story</p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default Post;
