import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const ProfessionalDetails = ({
  userData,
  educationList,
  employedInList,
  occupationList,
  annualIncomeList,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [courseList, setCourseList] = useState([]);
  const [professional_details, setProfessionalDetails] = useState({
    education_id: "",
    education: "",
    course: "",
    course_id: "",
    qualification: "",
    employed_in_id: "",
    employed_in: "",
    occupation: "",
    occupation_id: "",
    annual_income: "",
    annual_income_id: "",
  });
  const [isEditingProfessional, setIsEditingProfessional] = useState(false);
  useEffect(() => {
    const selectedEducation = educationList.find(
      (education) =>
        education.main_qualification == userData?.qualification_main
    );

    let course_data = "";

    if (selectedEducation) {
      const selectedCourse = selectedEducation.sub_qualification.find(
        (course) => course.sub_qualification == userData?.qualification_sub
      );

      setCourseList([...selectedEducation.sub_qualification]);
      if (selectedCourse) {
        course_data = selectedCourse;
      }
    }

    const selectedEmp = employedInList.find(
      (employed_in) => employed_in.employed_in == userData?.employed_in
    );

    const selectedOccupation = occupationList.find(
      (occupation) => occupation.occupation == userData?.occupation
    );

    const selectedAnnual = annualIncomeList.find(
      (annual_income) => annual_income.annual_income == userData?.annual_income
    );

    setProfessionalDetails({
      education_id: selectedEducation ? selectedEducation?.id : "",
      education: userData?.qualification_main,
      course_id: course_data?.id,
      course: userData?.qualification_sub,
      employed_in_id: selectedEmp ? selectedEmp?.id : "",
      employed_in: userData?.employed_in,
      occupation: userData?.occupation,
      occupation_id: selectedOccupation ? selectedOccupation?.id : "",
      annual_income: userData?.annual_income,
      annual_income_id: selectedAnnual ? selectedAnnual?.id : "",
    });
  }, [userData]);

  const handleEditClickProfessional = () => {
    setIsEditingProfessional(true);
  };

  const handleSaveClickProfessional = async () => {
    const payload = {
      education_main: professional_details?.education_id,
      education_sub: professional_details?.course_id,
      employeed_in: professional_details?.employed_in_id,
      occupation: professional_details?.occupation_id,
      annual_income: professional_details?.annual_income_id,
    };
    await api.updateProfessionalDetails(payload, token).then((data) => {
      if (data.status) {
        setIsEditingProfessional(false);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    });
  };

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Professional Details</p>
        <div className="editing-religious">
          {isEditingProfessional ? (
            <button onClick={handleSaveClickProfessional}>Save</button>
          ) : (
            <button onClick={handleEditClickProfessional}>
              <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Education
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="education"
                name="education"
                className="SelectBoxs-searchpage-profileedit"
                value={professional_details?.education_id}
                onChange={(e) => {
                  const selectedEducationId = e.target.value;

                  if (selectedEducationId) {
                    const selectedEducation = educationList.find(
                      (education) => education.id == selectedEducationId
                    );

                    setProfessionalDetails({
                      ...professional_details,
                      education_id: selectedEducation?.id,
                      education: selectedEducation.main_qualification,
                      course: "",
                      course_id: "",
                    });
                    setCourseList([...selectedEducation.sub_qualification]);
                  } else {
                    setProfessionalDetails({
                      ...professional_details,
                      education_id: "",
                      education: "",
                      course: "",
                      course_id: "",
                    });
                    setCourseList([]);
                  }
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {educationList.map((education) => (
                  <option key={education.id} value={education.id}>
                    {education.main_qualification}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
            Highest Education
            </div>
            <div style={{ flex: 1 }}>
              :{""} {professional_details?.education}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
            Qualification
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="course"
                name="course"
                className="SelectBoxs-searchpage-profileedit"
                value={professional_details?.course_id}
                onChange={(e) => {
                  const selectedCourseId = e.target.value;
                  if (selectedCourseId) {
                    const selectedCourse = courseList.find(
                      (course) => course.id == selectedCourseId
                    );
                    setProfessionalDetails({
                      ...professional_details,
                      course_id: selectedCourseId,
                      course: selectedCourse.sub_qualification,
                    });
                  } else {
                    setProfessionalDetails({
                      ...professional_details,
                      course_id: "",
                      course: "",
                    });
                  }
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {courseList.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.sub_qualification}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Course
            </div>
            <div style={{ flex: 1 }}>
              :{""} {professional_details?.course}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Employed in
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="employedin"
                name="employedin"
                className="SelectBoxs-searchpage-profileedit"
                value={professional_details?.employed_in}
                onChange={(e) => {
                  const selectedEmpId = e.target.value;

                  const selectedEmp = employedInList.find(
                    (employed_in) => employed_in.employed_in == selectedEmpId
                  );

                  setProfessionalDetails((prevDetails) => ({
                    ...prevDetails,
                    employed_in_id: selectedEmp.id,
                    employed_in: selectedEmpId,
                  }));
                }}
              >
                <option value="">Select</option>

                {/* Remove the selected attribute from here */}
                {employedInList.map((employeed_in) => (
                  <option
                    key={employeed_in.id}
                    value={employeed_in?.employed_in}
                  >
                    {employeed_in?.employed_in}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Employed in
            </div>
            <div style={{ flex: 1 }}>: {professional_details?.employed_in}</div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Occupation
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="occupation"
                name="occupation"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={professional_details.occupation}
                onChange={(e) => {
                  const selectedOccupationId = e.target.value;
                  const selectedOccupation = occupationList.find(
                    (occupation) =>
                      occupation.occupation == selectedOccupationId
                  );

                  setProfessionalDetails({
                    ...professional_details,
                    occupation_id: selectedOccupation?.id,
                    occupation: selectedOccupationId,
                  });
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {occupationList.map((occupation) => (
                  <option key={occupation?.id} value={occupation?.occupation}>
                    {occupation.occupation}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Occupation
            </div>
            <div style={{ flex: 1 }}>
              :{""} {professional_details?.occupation}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Annual income
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="annualincome"
                name="annualincome"
                className="SelectBoxs-searchpage-profileedit"
                value={professional_details?.annual_income}
                onChange={(e) => {
                  const selectedAnnualId = e.target.value;
                  const selectedAnnual = annualIncomeList.find(
                    (annual_income) =>
                      annual_income.annual_income == selectedAnnualId
                  );

                  setProfessionalDetails({
                    ...professional_details,
                    annual_income_id: selectedAnnual?.id,
                    annual_income: selectedAnnualId,
                  });
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {annualIncomeList.map((annual_income) => (
                  <option
                    key={annual_income.id}
                    value={annual_income.annual_income}
                  >
                    {annual_income.annual_income}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Annual income
            </div>
            <div style={{ flex: 1 }}>
              :{""} {professional_details?.annual_income}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default ProfessionalDetails;
