import Footer from './Footer'
import '../style/searchprofile.css'
import { MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit'
import { Button, Container } from 'react-bootstrap'
import { Form, Link, useLocation, useNavigate } from 'react-router-dom'
import ReactDOM from "react-dom";
import Navbars from './Navbars'
import Slidermobile from './Slidermobile'
import { useEffect, useState } from 'react'
import instance from '../axios'
import Footerinner from './Footerinner'


function Searchloginafter() {
  const location = useLocation();
  const { data, error } = location.state || {};

  console.log('data', data);
  console.log('error', error);
  const navigate = useNavigate()

  const [userData, setUserData] = useState(null);

  const [userPhoto, setuserPhoto] = useState(null);
  //photo view
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewphoto', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        setuserPhoto(response.data.data);
        console.log('response data data : ', response.data.data);

      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  // view logged user
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewedUserProfile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data logged', response);
        setUserData(response.data.userData);
        console.log('response data data : ', response.data.data);
        console.log('response data  : ', response.data);
        console.log("logged own user details", userData);
        console.log("own user details", userData.c_name);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);
  const [percentage, setPercentage] = useState(null)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/getPercentage', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data percentage', response);
        setPercentage(response.data);
        console.log('response percentage : ', percentage);

      } catch (error) {
        console.error('Error fetching user details:', error);

      }
    };

    fetchUserDetails();
  }, []);
  return (
    <div className='searchhome'>
      <Navbars></Navbars>



      <Container className='search-border-new'>
        <MDBRow className='mt-5  ml-3 ' >
          <MDBCol size='6' sm='3' className='employee-listitems columns-container  '>



            <div className='emppic'>

              <div className="switches-container">

                <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked="checked" />
                <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" />
                <label for="switchMonthly" >Regular</label>
                <label for="switchYearly">Premium</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div >Regular</div>
                    <div>Premium</div>
                  </div>

                </div>
              </div>

              <div className='empfull-layer'>
                <div className='layer container-fluid'>
                  <img src='../Images/Layer_1.png' className='layerfirst'></img><span className='active-online-profile'><span className="dot "></span> online</span>
                </div>
                {userPhoto ? (
                  <img src={userPhoto.c_profile_image} className='img-fluid searchemployee' alt='User' />
                ) : (
                  <img src='default-photo-url.png' className='img-fluid searchemployee' alt='Default' />
                )}
                {userData && (
                  <>
                    <p className='empname'>{userData.c_name}</p>
                    <p className='empid'>{userData.n_age} Yrs | {userData.c_usercode}</p>
                    <hr />
                    {percentage && (
                      <>
                        <p className='percentage'><span className='dark'>Profile Completeness</span> <span className='yellow'>{percentage.data}%</span>

                          <a href='Profileedit'><span className='green'> Complete Profile</span></a> </p>
                      </>
                    )}
                  </>
                )}
              </div>
              {/*   <Slidermobile></Slidermobile> */}
            </div>
            <div className='listitems-myhome'>

              <li className='empedit'><a href='/add-photo'>Add/Edit Photos</a></li><hr></hr>
              <li className='empedit'><a href='Profileedit'>View/Edit Profile</a></li><hr></hr>
              <li className='empedit'><a href='Interestedprofiles'>Interested Profile</a></li><hr></hr>

              <li className='empedit'><a href='Rejectedprofiles'>Rejected Profile</a></li><hr></hr>
              <li className='empedit'><a href='Rejectedbyother'>Rejected By Other</a></li><hr></hr>
              <li className='empedit'><a href='Acceptedprofile'>Accepted Profile</a></li><hr></hr>
              <li className='empedit'><a href='Accetedbyother'>Accepted by Other</a></li><hr></hr>

              <li className='empedit'><a href='Myshortlist'>My Shortlists</a></li><hr></hr>
              <li className='empedit'><a href='Shortlistedbyother'>Shortlisted by Other</a></li><hr></hr>
              <li className='empedit'><a href='Whovisited'>Who visited my profile</a></li><hr></hr>
              <li className='empedit'><a href='Hide'>Hide profile</a></li></div>


          </MDBCol>
          <MDBCol size='6' sm='9' className='insertform'>
            <p className='member-watches'>Search profile</p>
            {data && (
              <MDBRow className='liked-profile' style={{ marginLeft: '3%' }}>

                <MDBCol size='3' className='linked-with-allitems'>

                  {data.c_profile_image ? (
                    <img src={data.c_profile_image} alt='Profile Image' className='profile-image matches-profileimage' />
                  ) : (
                    <img src='default-photo-url.png' alt='Default Image' className='matches-profileimage' />
                  )}
                </MDBCol>
                <MDBCol size='9' className='linked-with-allitems'>

                  <div className='danil-sebastine'>
                    <p className='danil'>{data.c_name}<span className='online'><img src='../Images/online.png'></img></span></p>
                    <p className='danil-details'>{data.c_usercode} | Profile Created For {data.profile_for}</p>
                    <p className='danil-details'>{data.n_age || 'Age in'}Yrs, {' '}  {data.n_feet || data.n_cm ? `${data.n_feet || 'height'} / ${data.n_cm || 'height'}` : 'Height not defined'}
                    </p>
                    <p className='danil-details'>{data.religion}/{data.caste}</p>
                    <p className='danil-details'>{data.qualification || 'Education'}, {data.occupation || 'Occupation'}, {data.c_district || 'District'} </p>


                    <a href={`Profiledetails/${data.c_usercode}`}><p className='singlefulldetails'>View Full Profile</p></a>
                  </div>
                </MDBCol>

              </MDBRow>
            )}
            {/*mobile-view*/}
            <div className='client_top'>
              <div className='client'>

                {data.c_profile_image ? (
                  <img src={data.c_profile_image} alt='Profile Image' />
                ) : (
                  <img src='default-photo-url.png' alt='Default Image' />
                )}
                <div className='danil-sebastine'>
                  <p className='danil'>{data.c_name}<span className='online-mobile'><img src='../Images/online.png'></img></span></p>
                  <p className='danil-details'> {data.c_usercode} | Profile Created For {data.profile_for} </p>
                  <p className='danil-details'>{data.n_age}, {' '} {data.n_feet || data.n_cm ? `${data.n_feet || 'height'} / ${data.n_cm || 'height'}` : 'Height not defined'}</p>
                  <p className='danil-details'> {data.religion}/{data.caste}</p>
                  <p className='danil-details'>{data.qualification || 'Education'}, {data.occupation || 'Occupation'}, {data.c_district || 'District'} </p>

                  <a href={`Profiledetails/${data.c_usercode}`}><p className='singlefulldetails'>View Full Profile</p></a>


                </div>

              </div>

            </div>{/*end*/}


          </MDBCol>
        </MDBRow>
      </Container>
      <Footerinner></Footerinner>

    </div>
  )
}

export default Searchloginafter