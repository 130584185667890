const { axios, constants } = require("../../Config");
const baseURL = `${constants.baseURL}agent`;

class AgentProfileFetch {
  getRegisterDetails() {
    return axios
      .get(`${baseURL}/get-details`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  createProfile(token, payload) {
    return axios
      .post(`${baseURL}/profile/create`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updatePartnerPreference(token, payload) {
    return axios
      .post(`${baseURL}/profile/partner-preference`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfiles(payload, token) {
    return axios
      .post(`${baseURL}/profiles`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfileById(payload, token) {
    return axios
      .get(`${baseURL}/profiles/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfileMatches(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/profile-matches/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  uploadAgentUserProfilePhoto(profileId, payload, token) {
    return axios
      .post(`${baseURL}/profile-photo/${profileId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentUserProfileImages(profileId, token) {
    return axios
      .get(`${baseURL}/list-photos/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  removeAgentUserProfileImage(profileId, payload, token) {
    return axios
      .post(`${baseURL}/remove-photo/${profileId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  setAgentUserProfileImage(profileId, payload, token) {
    return axios
      .post(`${baseURL}/set-profile-photo/${profileId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  hideAgentProfile(profileId, payload, token) {
    return axios
      .post(`${baseURL}/profile-hide/${profileId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentHideProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/profile-hide-list/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  hideAgentCreatedProfile(profileId, token) {
    return axios
      .post(
        `${baseURL}/user-hide/${profileId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  editAgentCreatedProfile(profileId, token, payload) {
    return axios
      .post(`${baseURL}/profile-edit/${profileId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  interestAgentProfile(profileId, token, payload) {
    return axios
      .post(`${baseURL}/interest-send/${profileId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentLikedProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-interest-by-me/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentLikeRecievedProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-interest-received/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  acceptAgentProfileInterest(profile_id, token, payload) {
    return axios
      .post(`${baseURL}/inerest-accepted/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentAcceptedProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-interest-accepted-by-me/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentRejectedProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-interest-rejected-by-me/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentAcceptedByOthersProfiles(profile_id, payload, token) {
    return axios
      .post(
        `${baseURL}/view-interest-accepted-by-other/${profile_id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentRejectedByOthersProfiles(profile_id, payload, token) {
    return axios
      .post(
        `${baseURL}/view-interest-rejected-by-other/${profile_id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  shortlistAgentProfile(profile_id, token, payload) {
    return axios
      .post(`${baseURL}/shortlist-send/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getShortlistAgentProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-shortlist-by-me/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getShortlistByOtherAgentProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-shortlist-by-other/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getVisitedAgentProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-visitors/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getBlockedAgentProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/view-blocked-users/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  blockAgentProfiles(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/block-user/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  deleteAgentProfile(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/delete-user/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}
export default new AgentProfileFetch();
