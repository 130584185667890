import "../../style/terms_faq.css";

import { MDBCol } from "mdb-react-ui-kit";

function PrivacyContainer() {
  const token = localStorage.getItem("authToken");

  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="searchprofile">
        <h4>Terms and Conditions</h4>
      </div>
      <p className="termsHeadMain">Introduction</p>
      <p className="tremsContent">
        Welcome to Indian Brides and Grooms Matrimony. We value your privacy and
        are committed to protecting your personal data. This privacy policy
        explains how we collect, use, disclose, and safeguard your information
        when you visit our website [Website URL], use our services, or interact
        with us in other ways. By using our website and services, you agree to
        the terms of this privacy policy.
      </p>

      <p className="termsHead">1. Information We Collect</p>
      <p className="tremsContent">
        We may collect and process the following types of information about you:
        <li className="pt-2 liPadding">
          <span className="termsliSpan">Personal Information : </span>
          Name, email address, phone number, date of birth, gender, and other
          similar information.
        </li>
        <li className="liPadding">
          <sapn className="termsliSpan">Profile Information : </sapn>
          Details about your preferences, interests, and other information you
          provide in your profile.
        </li>
        <li className="liPadding">
          <span className="termsliSpan">Financial Information : </span> Payment
          details, billing information, and transaction history.
        </li>
        <li className="liPadding">
          <span className="termsliSpan">Usage Data:</span> Information about how
          you use our website and services, including your IP address, browser
          type, operating system, and pages visited.
        </li>
        <li className="liPadding">
          <span className="termsliSpan">Communications : </span>
          Records of your correspondence with us, including emails, chats, and
          other messages.
        </li>
      </p>

      <p className="termsHead"> 2. How We Use Your Information</p>
      <p className="tremsContent">
        We use your information for the following purposes :
        <li className="pt-2 liPadding"> To create and manage your account</li>
        <li className="liPadding"> To provide and improve our services </li>
        <li className="liPadding">
          To communicate with you, including sending you updates and promotional
          materials.{" "}
        </li>
        <li className="liPadding">
          {" "}
          To process your payments and manage transactions.{" "}
        </li>
        <li className="liPadding">
          {" "}
          To analyze usage patterns and improve our website.{" "}
        </li>
        <li className="liPadding"> To comply with legal obligations.</li>
      </p>

      <p className="termsHead">3. Sharing Your Information</p>
      <p className="tremsContent">
        We may share your information with :
        <li className="pt-2 liPadding">
          <span className="termsliSpan"> Service Providers : </span> Third-party
          vendors and service providers who perform services on our behalf, such
          as payment processing, data analysis, email delivery, and hosting
          services.
        </li>
        <li className="liPadding">
          <span className="termsliSpan"> Business Transfers : </span> In the
          event of a merger, acquisition, or sale of all or a portion of our
          assets, your information may be transferred to the acquiring company.
        </li>
        <li className="liPadding">
          <span className="termsliSpan">Legal Requirements : </span> When
          required by law, we may disclose your information to comply with legal
          processes, respond to claims, or protect the rights, property, or
          safety of our users or others.
        </li>
      </p>

      <p className="termsHead">4. Data Security</p>
      <p className="tremsContent">
        We take reasonable measures to protect your information from
        unauthorized access, use, alteration, and disclosure. However, no
        internet-based service is completely secure, and we cannot guarantee the
        absolute security of your information.
      </p>

      <p className="termsHead"> 5. Your Choices</p>
      <p className="tremsContent">
        You have the following rights regarding your information:
        <li className="pt-2 liPadding">
          <span className="termsliSpan">Access and Correction : </span>You can
          access and update your personal information by logging into your
          account or contacting us.
        </li>
        <li className="liPadding">
          <span className="termsliSpan">Deletion : </span> You can request the
          deletion of your account and personal information by contacting us.{" "}
        </li>
        <li className="liPadding">
          <span className="termsliSpan"> Opt-Out : </span> You can opt out of
          receiving promotional emails from us by following the unsubscribe
          instructions in those emails.
        </li>
      </p>

      <p className="termsHead">6. Cookies and Tracking Technologies</p>
      <p className="tremsContent">
        We use cookies and similar tracking technologies to enhance your
        experience on our website. You can control cookies through your browser
        settings.
      </p>

      <p className="termsHead">7. Children's Privacy</p>
      <p className="tremsContent">
        Our services are not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children under 18. If we
        become aware that we have inadvertently received personal information
        from a user under the age of 18, we will delete such information from
        our records.
      </p>

      <p className="termsHead">8. Changes to This Privacy Policy</p>
      <p className="tremsContent">
        We may update this privacy policy from time to time. We will notify you
        of any significant changes by posting the new privacy policy on our
        website and updating the "Last Updated" date at the top of this policy.
        Your continued use of our website and services after any changes
        indicates your acceptance of the updated privacy policy.
      </p>

      <p className="termsHead">9. Contact Us</p>
      <p className="tremsContent">
        If you have any questions or concerns about this privacy policy or our
        data practices, please contact us at: <br></br>
        <p className="termsAddress">
        Indian Brides & Grooms Matrimony <br></br>
        Door No.CC 35/1398C3, <br></br>2nd Floor,City Tower, <br></br>MKK Nair Road, Palarivattom,
        <br></br>Kochi- 682025 <br></br>
        
        </p>
        <p>noreply@ibgmatrimony.com <br></br>
        +91 9539990515</p>
      </p>
    </MDBCol>
  );
}

export default PrivacyContainer;
