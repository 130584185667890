import React, { useState } from "react";
import Navbars from "../Navbars";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/myhome.css";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import RightSide from "./RightSide";
import Footer from "../Footer";
import InterationSection from "../Interations/InterationSection";
import { Helmet } from "react-helmet";

const MyHome = () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  return (
    <>
      <Helmet>
        <title>Home | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <Navbars
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
        <Container>
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
            {selectedTab === "Home" ? (
              <RightSide />
            ) : (
              <InterationSection
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                titleDiscription={titleDiscription}
                setTitleDiscription={setTitleDiscription}
              />
            )}
          </MDBRow>
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default MyHome;
