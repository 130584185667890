import React from 'react'
import Nva from './Nva'

import '../style/singleprofile.css';
import Footer from './Footer'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Singleprofile() {
  const location = useLocation();
  const { data, error } = location.state || {};

  console.log('data', data);
  console.log('error', error);
  console.log("data name", data.c_name);
  return (
    <div className='all-background'>
      <Nva></Nva>
      {data && (
        <MDBRow className='liked-profile singleprofile'>
          <MDBCol size='3' className='singleprofile-picture'>

            {data.c_profile_image ? (
              <img src={data.c_profile_image} alt='Profile Image' />
            ) : (
              <img src='default-photo-url.png' alt='Default Image' />
            )}
          </MDBCol>
          <MDBCol size='9' className='linked-with-allitems'>
            <div className='danil-sebastine'>
              <p className='danil'>{data.c_name}<span className='online'><img src='../Images/online.png'></img></span></p>
              <p className='danil-details'>{data.c_usercode} | Profile Created For {data.profile_for || 'not defined'}</p>
              <p className='danil-details'> {data.n_age || 'not defined'} Yrs, {data.c_height}</p>
              {/* <p className='danil-details'> {data. religion || 'not defined' }/{data.caste || 'not defined' }</p> */}
              <p className='danil-details'> {data.religion || data.caste ? `${data.religion || 'religion'} / ${data.caste || 'caste'}` : 'religion and caste not defined'}</p>
              <p className='danil-details'>{data.qualification || 'no qualification provide '}, {data.occupation || 'no occupation provide '},
                {data.c_district}, </p>

              <a href='registration'><p className='singlefulldetails'>View Full Profile</p></a>
            </div>
          </MDBCol>

        </MDBRow>
      )}

      {/*mobile-view*/}
      <div className='client_top mobile-singleprofile'>
        <div className='client'>

          {data.c_profile_image ? (
            <img src={data.c_profile_image} alt='Profile Image' />
          ) : (
            <img src='default-photo-url.png' alt='Default Image' />
          )}
          <div className='danil-sebastine'>
            <p className='danil'>{data.c_name}<span className='online-mobile'><img src='../Images/online.png'></img></span> </p>
            <p className='danil-details-mobile'>{data.c_usercode} | Profile Created For  {data.profile_for}</p>
            <p className='danil-details-mobile'> {data.n_age},5'7", Malayalam,</p>
            <p className='danil-details-mobile'>{data.religion}/{data.caste}</p>
            <p className='danil-details-mobile'>{data.qualification || 'no qualification provide '}, {data.education || 'no education provided '}</p>
            <a href='registration'><p className='singlefulldetails'>View Full Profile</p></a>

          </div>

        </div>

      </div>
      <Footer></Footer>
    </div>
  )
}

export default Singleprofile