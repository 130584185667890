import React from "react";
import { MDBCol } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";
import { Link, useLocation } from "react-router-dom";

const ProfileSideBar = ({
  selectedTab,
  setSelectedTab,
  setTitleDiscription,
}) => {
  const getUserDetails = useSelector(getProfileDetails);
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <>
      <MDBCol size="6" sm="3" className="employee-listitems columns-container">
        <div className="emppic " id="mobile_profile">
          <div className="account-switch-container row">
            {/* <div
              className={`py-2 ${
                getUserDetails?.account_type === "Regular"
                  ? "col-7 selected-account-type"
                  : "col-5"
              }`}
            > */}
            <div className={`py-2 col-7 selected-account-type`}>
              <p className="text-center m-0">Regular</p>
            </div>
            <div
              className={`py-2 ${
                getUserDetails?.account_type === "Premium"
                  ? "col-7 selected-account-type"
                  : "col-5"
              }`}
            >
              <p className="text-center m-0">Premium</p>
            </div>
          </div>
          <div className="empfull-layer">
            <div className="layer container-fluid">
              <img
                src="../Images/Layer_1.png"
                className="layerfirst"
                alt="icon-1"
              ></img>

              <span className="active-online-profile ">
                <span className="dot "></span> Online
              </span>
            </div>
            <div>
              {getUserDetails?.profile_image ? (
                <img
                  src={getUserDetails?.profile_image}
                  className="img-fluid searchemployee mx-auto d-flex"
                  alt="User"
                />
              ) : (
                <img
                  src="../default-photo-url.png"
                  className="img-fluid searchemployee mx-auto d-flex"
                  alt="Default"
                />
              )}
            </div>
            <p className="empname">{getUserDetails?.name}Agent Ram</p>
            <p className="empid">IDP1222117</p>
          </div>
        </div>
        <div className="listitems-myhome">
          {/* <hr></hr> */}
          <div className="empeditss">
            <li className="empedit">
              <a href="my-profile">My Profile</a>
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "AddProfile" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("AddProfile");
                window.history.pushState(null, "", "/agent/add-profile");
              }}
            >
              Add Profile
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "AllProfiles" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("AllProfiles");
                window.history.pushState(null, "", "/agent/all-profiles");
              }}
            >
              All Profile
            </li>
            <hr></hr>
          </div>
        </div>
      </MDBCol>
    </>
  );
};

export default ProfileSideBar;
