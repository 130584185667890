import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const MoreProfiles = ({ profiles }) => {
  console.log("Proie", profiles);
  return (
    <div className=" editprofiletyping similer-profile-profileedit">
      <p className="similer-properties">More Profiles</p>
      <hr />
      <>
        {profiles.length > 0 ? (
          profiles.map((profile, index) => (
            <MDBRow className="similer-profile-profileedit" key={index}>
              <MDBCol size="6" sm="5">
                <img
                  src={
                    profile?.profile_image
                      ? profile?.profile_image
                      : `/default-photo-url.png`
                  }
                  className="similer-images img-fluid"
                />
              </MDBCol>
              <MDBCol size="6" sm="7" className="editprofile-only">
                <p className="editprofile-name">{profile?.name}</p>
                <p className="editprofile-id">
                  {profile?.profileId}|Profile Created by
                  {profile?.created_for || " Not Defined"} ,<br></br>
                  {profile?.age}Yrs,{" "}
                  {profile?.height_feet || profile?.height_cm
                    ? `${profile?.height_feet || "height"} / ${
                        profile?.height_cm || "height"
                      } cm`
                    : "height not defined"}
                </p>
                <Link
                  className="editprofile-seemore"
                  to={`/agent/all-profiles/${profile?.profileId}`}
                >
                  See More
                </Link>
              </MDBCol>
            </MDBRow>
          ))
        ) : (
          <p className="loading py-5">No profiles</p>
        )}
      </>
    </div>
  );
};

export default MoreProfiles;
