// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWPvzkiIUv0exss29sviZDEKbhGRpTnho",
  authDomain: "kpms-app-ccbb8.firebaseapp.com",
  projectId: "kpms-app-ccbb8",
  storageBucket: "kpms-app-ccbb8.appspot.com",
  messagingSenderId: "99334000717",
  appId: "1:99334000717:web:f3e7eeb81c320728accc04",
  measurementId: "G-3CXFVR6YRW",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const firebaseMessage = getMessaging(firebaseApp);
const generateToken = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notifications");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const firebaseMessage = getMessaging(firebaseApp);
      const token = await getToken(firebaseMessage, {
        vapidKey:
          "BOwH2aUmrOLyyT23plPkuBJaAAHLOSzYbmdi4MIMUMb5FS6uX4-Z2KeuLZo1Awphe_L589Lv_NH1RQLQddLTGFo",
      });
      return token;
    } else {
      console.error("Permission for notifications was not granted");
      return null;
    }
  } catch (error) {
    console.error(
      "Error occurred while requesting notification permission:",
      error
    );
    return null;
  }
};

export { firebaseApp, generateToken };
