import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import api from "../../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  getFirestore,
  or,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../../../../firebase/firebase";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../../../redux/features/profile";

const AgentBasicDetails = ({
  userData,
  registerData,
  setRegisterData,
  handleRegisterData,
  listHeights,
  listStars,
  register,
  setValue,
  errors,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const getUserDetails = useSelector(getProfileDetails);
  const firebaseDB = getFirestore(firebaseApp);
  const [oldName, setOldName] = useState(userData?.name);
  const [basicDetails, setBasicDetails] = useState({
    name: userData?.name,
    gender: userData?.gender,
    dateOfBirth: userData?.date_of_birth,
    age: userData?.age,
    marital_status: userData?.marital_status,
    height_id: "",
    height_cm: userData?.height_cm,
    height_feet: userData?.height_feet,
    star_id: "",
    star: userData?.star,
    physical_status: userData?.physical_status,
    complexion: userData?.complexion,
    body_type: userData?.body_type,
    weight: userData?.weight,
  });
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date(today.setFullYear(today.getFullYear() - 18));
    const year = pastDate.getFullYear();
    const month = String(pastDate.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(pastDate.getDate()).padStart(2, "0"); // Ensure 2-digit day
    const formattedDate = `${year}-${month}-${day}`;
    setValue("date_birth", formattedDate);
    setValue("age", "18");
    setValue("gender", "Male")
    setRegisterData((prevValue) => ({
      ...prevValue,
      date_birth: formattedDate,
      age: "18",
    }));
  }, []);
  const [isEditingBasicdetails, setIsEditingBasicdetails] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 90,
    today.getMonth(),
    today.getDate()
  );

  const handleEditClickBasicdetails = () => {
    setIsEditingBasicdetails(true);
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
    const formattedDate = `${year}-${month}-${day}`;

    const age = calculateAge(formattedDate);

    setSelectedDate(date);

    setRegisterData((prevValue) => ({
      ...prevValue,
      date_birth: formattedDate,
      age: age.toString(),
    }));
    setValue("date_birth", formattedDate);
    setValue("age", age.toString());
  };

  const handleSaveClickBasicdetails = async () => {
    try {
      const payload = {
        name: basicDetails.name,
        dob: basicDetails.dateOfBirth,
        age: basicDetails.age,
        height: basicDetails.height_id,
        gender: basicDetails.gender,
        matrial_status:
          basicDetails.marital_status === "Not Defined"
            ? ""
            : basicDetails?.marital_status,
        physical_status:
          basicDetails.physical_status === "Not Defined"
            ? ""
            : basicDetails?.physical_status,
        star_id: `${basicDetails.star_id}`,
        star_name:
          basicDetails.star === "Not Defined" ? "" : basicDetails?.star,
        complexion: basicDetails.complexion,
        body_type: basicDetails.body_type,
        weight: basicDetails.weight,
      };
      await api.updateBasicDetails(payload, token).then((data) => {
        if (data.status) {
          setIsEditingBasicdetails(false);
          setOldName(basicDetails?.name);
          if (oldName !== basicDetails.name) {
            updateFirebaseName(basicDetails.name);
          }
        } else if (data.status === 401) {
          navigate("/login");
          localStorage.clear();
        }
      });
    } catch (error) {
      console.error("Error updating profile description:", error);
    }
  };

  const updateFirebaseName = async (name) => {
    const lastMessagesRef = collection(firebaseDB, "lastMessages");

    const queryRef = query(
      lastMessagesRef,
      or(
        where("senderId", "==", getUserDetails?.profile_id),
        where("receiverId", "==", getUserDetails?.profile_id)
      )
    );

    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach(async (doc) => {
      let messageData = doc.data();
      const docRef = doc.ref;
      if (messageData.senderId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            senderName: name,
          });
        } catch (error) {
          console.error("Error updating sender name:", error);
        }
      } else if (messageData.receiverId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            receiverName: name,
          });
        } catch (error) {
          console.error("Error updating receiver name:", error);
        }
      }
    });
  };

  const handleLookingForChange = (e) => {
    const { value } = e.target;
    setRegisterData((prevValue) => ({
      ...prevValue,
      looking_for: value,
      gender: value === "Bride" ? "Male" : value === "Groom" ? "Female" : "",
    }));
  };
  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Basic Details</p>
        {/* <div className="editing-religious">
          {isEditingBasicdetails ? (
            <button onClick={handleSaveClickBasicdetails}>Save</button>
          ) : (
            <button onClick={handleEditClickBasicdetails}>
               <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div> */}
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Looking For<span style={{ color: "#640348" }}>*</span>
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="looking-for"
              name="looking_for"
              className="SelectBoxs-searchpage-profileedit"
              {...register("looking_for", { required: true })}
              value={registerData?.looking_for}
              onChange={handleLookingForChange}
            >
              <option value="Bride">Bride</option>
              <option value="Groom">Groom</option>
            </select>
          </div>
        </div>
        <div className="annual-income d-lg-none" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Profile for
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="profile-for"
              name="profile_for"
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.profile_for}
              {...register("profile_for")}
              onChange={handleRegisterData}
            >
              <option value="" disabled selected hidden>
                Profile For
              </option>
              <option value="Myself">Myself</option>
              <option value="Daughter">Daughter</option>
              <option value="Son">Son</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Relative">Relative</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Name<span style={{ color: "#640348" }}>*</span>
            </div>
            :
            <div
              className={`basic-profiles  ${errors.name && "border-danger"}`}
              style={{ flex: 1 }}
            >
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                name="name"
                value={registerData?.name}
                {...register("name", { required: true })}
                onChange={handleRegisterData}
              />
              {errors.name && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Name
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {registerData?.name}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Gender
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="gender"
                name="gender"
                className="SelectBoxs-searchpage-profileedit"
                value={registerData.gender}
                {...register("gender")}
                disabled
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Gender
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.gender || "Not defined"}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Date of birth<span style={{ color: "#640348" }}>*</span>
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <span className="datespaceonly">
                <DatePicker
                  placeholderText="yyyy-mm-dd)"
                  name="date_birth"
                  {...register("date_birth", { required: true })}
                  selected={selectedDate}
                  onChange={(date) => {
                    handleDateChange(date);                  }}
                  dateFormat="yyyy-MM-dd"
                  maxDate={maxDate}
                  minDate={minDate}
                  showYearDropdown
                  yearDropdownItemNumber={90}
                  value={registerData?.date_birth}
                  className="SelectBoxs-searchpage-profileedit"
                />
              </span>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Date of birth
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {registerData?.date_birth || "Not defined"}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Age
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                name="age"
                value={registerData?.age ? `${registerData.age} Years` : ""}
                style={{ border: "none" }}
                {...register("age")}
                className="SelectBoxs-searchpage-profileedit"
                disabled
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Age
            </div>{" "}
            :
            <div className="" style={{ flex: 1 }}>
              {""} {registerData?.age || "Not defined"} Years
            </div>
          </div>
        )}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Height
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <span className="heightpaceonly">
                <select
                  id="height"
                  name="height"
                  {...register("height")}
                  className="SelectBoxs-searchpage-profileedit-height"
                  value={registerData?.height} // Initialize with basicDetails.height_id
                  onChange={handleRegisterData}
                >
                  <option value="">Select</option>

                  {listHeights.map((height) => (
                    <option key={height?.id} value={height?.id}>
                      {`${height?.height_cm} cm / ${height?.height_feet}`}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Height
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.height_cm} cm / {registerData?.height_feet}
            </div>
          </div>
        )}
        {/*   WEIGHT */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Weight in kg
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                name="weight"
                {...register("weight")}
                value={registerData?.weight}
                onChange={handleRegisterData}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Weight in kg
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {" "}
              {""} {registerData?.weight}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div
          className="annual-income d-none d-lg-flex"
          style={{ display: "flex" }}
        >
          <div style={{ flex: 1 }} className="">
            Profile for
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="profile-for"
              name="profile_for"
              {...register("profile_for")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.profile_for}
              onChange={handleRegisterData}
            >
              <option value="" disabled selected hidden>
                Profile For
              </option>
              <option value="Myself">Myself</option>
              <option value="Daughter">Daughter</option>
              <option value="Son">Son</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Relative">Relative</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Marital Status
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="maritalStatus"
                name="matrial_status"
                {...register("matrial_status")}
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.matrial_status}
                onChange={handleRegisterData}
              >
                <option value="">Select</option>
                <option value="Never Married">Never Married</option>
                <option value="Widowed">Widowed</option>
                <option value="Divorced">Divorced</option>
                <option value="Seperated">Seperated</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Marital Status
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.matrial_status || "Not defined"}
            </div>
          </div>
        )}

        {/* BODY TYPE */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Body Type
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="bodyType"
                name="body_type"
                className="SelectBoxs-searchpage-profileedit"
                {...register("body_type")}
                value={registerData?.body_type}
                onChange={handleRegisterData}
              >
                <option defaultValue="">Select</option>
                <option value="Average">Average</option>
                <option value="Slim">Slim</option>
                <option value="Athletic">Athletic</option>
                <option value="Heavy">Heavy</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Body Type
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.body_type || "Not defined"}
            </div>
          </div>
        )}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Physical Status
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="physicalStatus"
                name="physical_status"
                {...register("physical_status")}
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.physical_status}
                onChange={handleRegisterData}
              >
                <option Value="">Select</option>
                <option value="Physically Challenged">
                  Physically Challenged
                </option>
                <option value="Normal">Normal</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Physical Status
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.physical_status || "Not defined"}
            </div>
          </div>
        )}

        {/* COMPLEXION */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Complexion
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="complexion"
                name="complexion"
                {...register("complexion")}
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.complexion}
                onChange={handleRegisterData}
              >
                <option value="Fair">Fair</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Dark">Dark</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Complexion
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {registerData?.complexion || "Not defined"}
            </div>
          </div>
        )}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Star
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="star"
                name="star"
                {...register("star")}
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.star}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>
                {listStars.map((star) => (
                  <option key={star.id} value={star.id}>
                    {star.star}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Star
            </div>
            :
            <div style={{ flex: 1 }}>
              {""}
              {registerData?.star}
            </div>
          </div>
        )}
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Any Disability
          </div>{" "}
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="any_disability"
              name="any_disability"
              {...register("any_disability")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.any_disability}
              onChange={handleRegisterData}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default AgentBasicDetails;
