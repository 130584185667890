import React from 'react'



import Footer from './Footer'

import Nva from './Nva'
import Home from './Home'



function Membership() {
  return (
    <div className='registerbody'>
       <Nva></Nva>
        <Home></Home>
      
      
        <Footer></Footer>
    </div>
  )
}

export default Membership