import React, { useEffect, useState } from "react";
import "../style/editmultiple.css";
import Navbars from "./Navbars";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import CustomModal from "./CustomModel";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBRow,
} from "mdb-react-ui-kit";
import NoImages from "./NoImages";
import api from "../Api/ProfileApi";
import { Container } from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import InterationSection from "./Interations/InterationSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileDetails,
  setProfileDetails,
} from "../redux/features/profile";
import CropPhoto from "./Modals/CropPhoto";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  or,
  updateDoc,
} from "firebase/firestore";
import { firebaseApp } from "../firebase/firebase";
import { Helmet } from "react-helmet";

function Addphoto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserDetails = useSelector(getProfileDetails);
  const firebaseDB = getFirestore(firebaseApp);
  const [newImageData, setNewImageData] = useState({
    imageUrl: "",
    file: "",
    for: "",
  });
  const [cropedImage, setCropedImage] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [headshotImage, setHeadshotImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [fullImage, setFullImage] = useState(null);
  const [fourthImage, setFourthImage] = useState(null);
  const [fifthImage, setFifthImage] = useState(null);
  const [sixthImage, setSixthImage] = useState(null);
  const [seventhImage, setSeventhImage] = useState(null);
  const [eighthImage, setEighthImage] = useState(null);
  const [ninthImage, setNinthImage] = useState(null);
  const [tenthImage, setTenthImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );

  const handleCloseModal = () => {
    fetchUserImages();
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    fetchUserImages();
    // updateFirebaseImage();
  }, []);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        navigate("/login");
        return;
      }

      const tokenData = parseToken(token);

      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenData.exp < currentTime) {
          clearToken();
          navigate("/login");
        }
      }
    };

    checkTokenExpiration();
  }, [navigate]);

  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }

  function clearToken() {
    localStorage.removeItem("authToken");
  }

  const MAX_FILE_SIZE = 15 * 1024 * 1024;

  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  const handleImageChange = (e, setImage, inputId) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      if (!isAllowedFileType(file)) {
        alert("Only .jpeg, .jpg, and .png image files are allowed.");
        setNewImageData({
          imageUrl: "",
          file: "",
        });
        return;
      }
      if (!isFileSizeValid(file)) {
        alert("File size should be no larger than 15MB.");
        setNewImageData({
          imageUrl: "",
          file: "",
        });
        return;
      }

      reader.onload = function (event) {
        setNewImageData({
          imageUrl: reader.result,
          file: file,
        });
        setImage(event.target.result);
      };

      reader.readAsDataURL(file);
      //handleSubmitImages(e, inputId);
    }
  };

  const removeProfileImage = async (profileImageId) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("Authentication token missing");
        return;
      }

      api.removeProfilePhoto(profileImageId, token).then(({ data }) => {
        if (data.status) {
          setModalTitle("Success");
          const message = data.message;
          setModalMessage(message);
          setImageData((prevImageData) =>
            prevImageData.filter(
              (image) => image.n_profile_id !== profileImageId
            )
          );
          const userDetails = {
            ...getUserDetails,
            profile_image: "",
          };
          dispatch(setProfileDetails(userDetails));
          setHeadshotImage(null);
          setProfileImage(null);
          setFullImage(null);
          setFourthImage(null);
          setFifthImage(null);
        } else {
          setModalTitle("Error");
          const message = data.message;
          setModalMessage(message);
        }
      });
      handleOpenModal();
    } catch (error) {
      console.error("Error removing photo:", error);
    }
  };

  const handleSubmitImages = async (e, inputId) => {
    e.preventDefault();

    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("Authentication token missing");
      return;
    }

    const formData = new FormData();

    const imageInput = document.getElementById(inputId);
    if (!imageInput) {
      console.error("Input element not found");
      return;
    }

    const ImageFile = imageInput.files?.[0];

    if (ImageFile) {
      formData.append("photo", ImageFile);
    }

    try {
      api.uploadProfilePhoto(formData, token).then(({ data }) => {
        if (data.status) {
          setModalTitle("Success");
          const message = data.message;
          setModalMessage(message);
        } else {
          setModalTitle("Error");
          const message = data.message;
          setModalMessage(message);
        }
      });
      handleOpenModal();
    } catch (error) {
      setUploadError(error.response.data.error);
      console.error("Error uploading photos", error);
    }
  };

  const handleSubmitCroppedImages = async (ImageFile) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("Authentication token missing");
      return;
    }

    const base64Image = ImageFile;
    // Create a new FormData object
    const formData = new FormData();

    // Decode the Base64 image data
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object representing the image
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    formData.append("photo", blob, "image.jpg");

    try {
      api.uploadProfilePhoto(formData, token).then(({ data }) => {
        if (data.status) {
          setModalTitle("Success");
          const message = data.message;
          setModalMessage(message);
        } else {
          setModalTitle("Error");
          const message = data.message;
          setModalMessage(message);
        }
        setNewImageData({
          imageUrl: "",
          file: "",
        });
        setCropedImage(null);
      });
      handleOpenModal();
    } catch (error) {
      console.log("dd", error);
      setUploadError(error.response.data.error);
      console.error("Error uploading photos", error);
    }
  };

  const [uploadError, setUploadError] = useState(null);

  const isAllowedFileType = (file) => {
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes("." + fileExtension);
  };

  const fetchUserImages = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("Authentication token missing");
        navigate("/login");
        return;
      }

      api.getViewUploadedPhoto(token).then(({ data }) => {
        if (data?.status) {
          const fetchedImagesData = data.data;
          setImageData(fetchedImagesData);
          if (fetchedImagesData.length >= 10) {
            const profileImageUrl = fetchedImagesData[0].c_profile_image;
            const fullImageUrl = fetchedImagesData[1].c_profile_image;
            const headshotImageUrl = fetchedImagesData[2].c_profile_image;
            const fourthImageUrl = fetchedImagesData[3].c_profile_image;
            const fifthImageUrl = fetchedImagesData[4].c_profile_image;
            const sixthImageUrl = fetchedImagesData[5].c_profile_image;
            const seventhImageUrl = fetchedImagesData[6].c_profile_image;
            const eighthImageUrl = fetchedImagesData[7].c_profile_image;
            const ninthImageUrl = fetchedImagesData[8].c_profile_image;
            const tenthImageUrl = fetchedImagesData[9].c_profile_image;

            setProfileImage(profileImageUrl);
            setFullImage(fullImageUrl);
            setHeadshotImage(headshotImageUrl);
            setFourthImage(fourthImageUrl);
            setFifthImage(fifthImageUrl);
            setSixthImage(sixthImageUrl);
            setSeventhImage(seventhImageUrl);
            setEighthImage(eighthImageUrl);
            setNinthImage(ninthImageUrl);
            setTenthImage(tenthImageUrl);
            setProfileImage(true);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching user images:", error);
    }
  };

  const updateProfileImage = async (profileImageId, imageUrl) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("Authentication token missing");
        return;
      }
      api.setAsProfilePhoto(profileImageId, token).then(async ({ data }) => {
        if (data.status) {
          const userDetails = {
            ...getUserDetails,
            profile_image: imageUrl,
          };
          dispatch(setProfileDetails(userDetails));
          setModalTitle("Success");
          await updateFirebaseImage(imageUrl);
          const message = data.message;
          setModalMessage(message);
        } else {
          setModalTitle("Error");
          const message = data.message;
          setModalMessage(message);
        }
      });
      handleOpenModal();
    } catch (error) {
      console.error("Error setting photo as profile image:", error);
    }
  };

  const updateFirebaseImage = async (newImageUrl) => {
    const lastMessagesRef = collection(firebaseDB, "lastMessages");

    const queryRef = query(
      lastMessagesRef,
      or(
        where("senderId", "==", getUserDetails?.profile_id),
        where("receiverId", "==", getUserDetails?.profile_id)
      )
    );

    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach(async (doc) => {
      let messageData = doc.data();
      const docRef = doc.ref;
      if (messageData.senderId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            senderImage: newImageUrl,
          });
        } catch (error) {
          console.error("Error updating sender image:", error);
        }
      } else if (messageData.receiverId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            receiverImage: newImageUrl,
          });
        } catch (error) {
          console.error("Error updating receiver image:", error);
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>AddPhoto | Indian Brides & Grooms Matrimony</title>
      </Helmet>
      <div className="all-background">
        <Navbars
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
        {selectedTab === "Home" ? (
          <div className="intro">
            {imageData.length >= 1 ? (
              <form
                className="form"
                id="image-upload"
                onSubmit={handleSubmitImages}
              >
                <p className="better-photo">
                  Add your photo and get much better responses!
                </p>
                <div className="row">
                  {/* first image*/}
                  <div className="col-4 add-photos-full">
                    <div className="form--field image-upload--profile-image">
                      {imageData ? (
                        <div className="image-container">
                          <img
                            src={
                              imageData
                                ? imageData[0].c_profile_image
                                : "upload_image.svg"
                            }
                            alt="Profile"
                            className={`upload upload-image ${
                              imageData[0]?.p_status === "N" ? "image-blur" : ""
                            }`}
                          />
                          {imageData && imageData[0]?.p_status !== "N" && (
                            <MDBDropdown>
                              <MDBDropdownToggle
                                style={{ paddingBottom: "2rem" }}
                              >
                                ....
                              </MDBDropdownToggle>
                              <MDBDropdownMenu
                                style={{
                                  transform: "inherit",
                                  minWidth: "100%",
                                  transform: "translate3d(-73px, 28px, 4px)",
                                }}
                              >
                                <MDBDropdownItem
                                  link
                                  onClick={() =>
                                    updateProfileImage(
                                      imageData[0]?.n_profile_id,
                                      imageData[0]?.c_profile_image
                                    )
                                  }
                                >
                                  Set as Main
                                </MDBDropdownItem>

                                <MDBDropdownItem
                                  link
                                  onClick={() =>
                                    removeProfileImage(
                                      imageData && imageData[0]?.n_profile_id
                                    )
                                  }
                                >
                                  Delete
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          )}
                          {imageData && imageData[0]?.p_status === "N" && (
                            <>
                              {" "}
                              <div className="validation">
                                <p>Under validation</p>
                              </div>
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  className=""
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>

                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[0]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            </>
                          )}
                        </div>
                      ) : (
                        <img
                          src={profileImage ? profileImage : "upload_image.svg"}
                          className="upload upload-image"
                          alt="Full"
                        />
                      )}
                      <input
                        className="image-input"
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        accept="image/x-png,image/jpg,image/jpeg"
                        onChange={(e) =>
                          handleImageChange(e, setProfileImage, "profileImage")
                        }
                        disabled={!!imageData} // Disable the input if an image is present
                      />
                    </div>
                    <br />
                  </div>

                  {/* second image */}
                  <div className="col-4 add-photos-full">
                    <div className="form--field image-upload--full-image">
                      {imageData && imageData.length > 1 ? (
                        <div className="image-container">
                          <img
                            src={
                              imageData
                                ? imageData[1].c_profile_image
                                : "upload_image.svg"
                            }
                            alt="Profile"
                            className={`upload upload-image ${
                              imageData[1]?.p_status === "N" ? "image-blur" : ""
                            }`}
                          />
                          {imageData && imageData[1]?.p_status !== "N" && (
                            <MDBDropdown>
                              <MDBDropdownToggle
                                style={{ paddingBottom: "2rem" }}
                              >
                                ....
                              </MDBDropdownToggle>
                              <MDBDropdownMenu
                                style={{
                                  transform: "inherit",
                                  minWidth: "100%",
                                  transform: "translate3d(-73px, 28px, 4px)",
                                }}
                              >
                                <MDBDropdownItem
                                  link
                                  onClick={() =>
                                    updateProfileImage(
                                      imageData[1]?.n_profile_id,
                                      imageData[1]?.c_profile_image
                                    )
                                  }
                                >
                                  Set as Main
                                </MDBDropdownItem>

                                <MDBDropdownItem
                                  link
                                  onClick={() =>
                                    removeProfileImage(
                                      imageData && imageData[1]?.n_profile_id
                                    )
                                  }
                                >
                                  Delete
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          )}
                          {imageData && imageData[1]?.p_status === "N" && (
                            <>
                              {" "}
                              <div className="validation">
                                <p>Under validation</p>
                              </div>
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[1]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            </>
                          )}
                        </div>
                      ) : (
                        <img
                          src={fullImage ? fullImage : "upload_image.svg"}
                          className="upload upload-image"
                          alt="Full"
                        />
                      )}

                      <input
                        className="image-input"
                        type="file"
                        name="fullImage"
                        id="fullImage"
                        accept="image/x-png,image/jpg,image/jpeg"
                        onChange={(e) =>
                          handleImageChange(e, setFullImage, "fullImage")
                        }
                        disabled={!!(imageData && imageData.length > 1)}
                      />
                    </div>
                    <br />
                  </div>

                  {/* third image*/}
                  {imageData && imageData.length > 1 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--headshot-image">
                        {imageData && imageData.length > 2 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[2].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[2]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[2]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[2]?.n_profile_id,
                                        imageData[2]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[2]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[2]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[2]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={
                              headshotImage ? headshotImage : "upload_image.svg"
                            }
                            className="upload upload-image"
                            alt="Full"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="headshotImage"
                          id="headshotImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              setHeadshotImage,
                              "headshotImage"
                            )
                          }
                          disabled={!!(imageData && imageData.length > 2)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* fourth image */}
                  {imageData && imageData.length > 2 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--fourth-image">
                        {imageData && imageData.length > 3 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[3].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[3]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[3]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[3]?.n_profile_id,
                                        imageData[3]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[3]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[3]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[3]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={fourthImage ? fourthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Fourth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="fourthImage"
                          id="fourthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setFourthImage, "fourthImage")
                          }
                          disabled={!!(imageData && imageData.length > 3)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* fifth image */}
                  {imageData && imageData.length > 3 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--fifth-image">
                        {imageData && imageData.length > 4 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[4].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[4]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[4]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[4]?.n_profile_id,
                                        imageData[4]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[4]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[4]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[4]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={fifthImage ? fifthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Fifth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="fifthImage"
                          id="fifthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setFifthImage, "fifthImage")
                          }
                          disabled={!!(imageData && imageData.length > 4)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* sixth image */}
                  {imageData && imageData.length > 4 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--sixth-image">
                        {imageData && imageData.length > 5 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[5].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[5]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[5]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[5]?.n_profile_id,
                                        imageData[5]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[5]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[5]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[5]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={sixthImage ? sixthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Sixth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="sixthImage"
                          id="sixthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setSixthImage, "sixthImage")
                          }
                          disabled={!!(imageData && imageData.length > 5)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* seventh image */}
                  {imageData && imageData.length > 5 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--seventh-image">
                        {imageData && imageData.length > 6 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[6].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[6]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[6]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[6]?.n_profile_id,
                                        imageData[6]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[6]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[6]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[6]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={
                              seventhImage ? seventhImage : "upload_image.svg"
                            }
                            className="upload upload-image"
                            alt="Seventh"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="seventhImage"
                          id="seventhImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              setSeventhImage,
                              "seventhImage"
                            )
                          }
                          disabled={!!(imageData && imageData.length > 6)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* eighth image */}
                  {imageData && imageData.length > 6 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--eighth-image">
                        {imageData && imageData.length > 7 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[7].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[7]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[7]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[7]?.n_profile_id,
                                        imageData[7]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[7]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[7]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[7]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={eighthImage ? eighthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Eighth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="eighthImage"
                          id="eighthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setEighthImage, "eighthImage")
                          }
                          disabled={!!(imageData && imageData.length > 7)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* ninth  image */}
                  {imageData && imageData.length > 7 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--ninth-image">
                        {imageData && imageData.length > 8 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[8].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[8]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[8]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[8]?.n_profile_id,
                                        imageData[8]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[8]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[8]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[8]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={ninthImage ? ninthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Ninth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="ninthImage"
                          id="ninthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setNinthImage, "ninthImage")
                          }
                          disabled={!!(imageData && imageData.length > 8)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                  {/* tenth  image */}
                  {imageData && imageData.length > 8 && (
                    <div className="col-4 add-photos-full">
                      <div className="form--field image-upload--tenth-image">
                        {imageData && imageData.length > 9 ? (
                          <div className="image-container">
                            <img
                              src={
                                imageData
                                  ? imageData[9].c_profile_image
                                  : "upload_image.svg"
                              }
                              alt="Profile"
                              className={`upload upload-image ${
                                imageData[9]?.p_status === "N"
                                  ? "image-blur"
                                  : ""
                              }`}
                            />
                            {imageData && imageData[9]?.p_status !== "N" && (
                              <MDBDropdown>
                                <MDBDropdownToggle
                                  style={{ paddingBottom: "2rem" }}
                                >
                                  ....
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                  style={{
                                    transform: "inherit",
                                    minWidth: "100%",
                                    transform: "translate3d(-73px, 28px, 4px)",
                                  }}
                                >
                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      updateProfileImage(
                                        imageData[9]?.n_profile_id,
                                        imageData[9]?.c_profile_image
                                      )
                                    }
                                  >
                                    Set as Main
                                  </MDBDropdownItem>

                                  <MDBDropdownItem
                                    link
                                    onClick={() =>
                                      removeProfileImage(
                                        imageData && imageData[9]?.n_profile_id
                                      )
                                    }
                                  >
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                            {imageData && imageData[9]?.p_status === "N" && (
                              <>
                                {" "}
                                <div className="validation">
                                  <p>Under validation</p>
                                </div>
                                <MDBDropdown>
                                  <MDBDropdownToggle
                                    style={{ paddingBottom: "2rem" }}
                                  >
                                    ....
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu
                                    style={{
                                      transform: "inherit",
                                      minWidth: "100%",
                                      transform:
                                        "translate3d(-73px, 28px, 4px)",
                                    }}
                                  >
                                    <MDBDropdownItem
                                      link
                                      onClick={() =>
                                        removeProfileImage(
                                          imageData &&
                                            imageData[9]?.n_profile_id
                                        )
                                      }
                                    >
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              </>
                            )}
                          </div>
                        ) : (
                          <img
                            src={tenthImage ? tenthImage : "upload_image.svg"}
                            className="upload upload-image"
                            alt="Tenth"
                          />
                        )}
                        <input
                          className="image-input"
                          type="file"
                          name="tenthImage"
                          id="tenthImage"
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={(e) =>
                            handleImageChange(e, setTenthImage, "tenthImage")
                          }
                          disabled={!!(imageData && imageData.length > 9)}
                        />
                      </div>
                      <br />
                    </div>
                  )}

                </div>

                {uploadError && (
                  <div className="error-message">{uploadError}</div>
                )}
              </form>
            ) : (
              <NoImages
                fetchUserImages={fetchUserImages}
                setImageData={setImageData}
              />
            )}
          </div>
        ) : (
          <Container>
            <MDBRow className="myhome-matches-all">
              <ProfileSideBar
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTitleDiscription={setTitleDiscription}
              />
              <InterationSection
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                titleDiscription={titleDiscription}
                setTitleDiscription={setTitleDiscription}
              />
            </MDBRow>
          </Container>
        )}

        <Footer></Footer>
        <CustomModal
          show={showModal}
          handleClose={handleCloseModal}
          title={modalTitle}
          message={modalMessage}
        />
        {newImageData.file && (
          <CropPhoto
            selectedImage={newImageData}
            onClose={() =>
              setNewImageData({
                imageUrl: "",
                file: "",
              })
            }
            setCropedImage={setCropedImage}
            from="list"
            handleSubmit={handleSubmitCroppedImages}
          />
        )}
      </div>
    </>
  );
}

export default Addphoto;
