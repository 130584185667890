import React from 'react'
import Nva from './Nva'

import '../style/singleprofile.css';
import Footer from './Footer'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

function Morematches() {
  const location = useLocation();
  const { data, error } = location.state || {};


  console.log('data wq', data);
  console.log('error', error);
  console.log("data name", data.c_name);
  //pagination
  const [pageNumber, setPageNumber] = useState(0);
  const profilesPerPage = 8;
  const pageCount = Math.ceil(data.length / profilesPerPage);
  console.log("page count : ", pageCount);

  const displayedProfiles = data.slice(
    pageNumber * profilesPerPage,
    (pageNumber + 1) * profilesPerPage
  );


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className='all-background'>
      <Nva></Nva>
      {data &&
        Array.isArray(data) &&
        displayedProfiles.map((userData, index) => (
          <MDBRow key={index} className='liked-profile singleprofile'>
            <MDBCol size='3'>

              {data.c_profile_image ? (
                <img src={data.c_profile_image} alt='Profile Image' className='matches-profileimage' />
              ) : (
                <img src='default-photo-url.png' alt='Default Image' className='matches-profileimage' />
              )}
            </MDBCol>
            <MDBCol size='9' className='linked-with-allitems'>
              <div className='danil-sebastine'>
                <p className='danil'>{userData.c_name}<span className='online'><img src='../Images/online.png'></img></span></p>
                <p className='danil-details'>{userData.c_usercode} | Profile Created For{userData.profile_for || 'not defined'}</p>
                <p className='danil-details'> {userData.n_age || 'not defined'} Yrs, {userData.c_height}</p>
                <p className='danil-details'> {userData.religion || userData.caste ? `${userData.religion || 'religion'} / ${userData.caste || 'caste'}` : 'religion and caste not defined'}</p>
                <p className='danil-details'>{userData.qualification || 'no qualification provide '}, {userData.occupation || 'no occupation provide '},
                  {userData.c_district}, </p>

                <a href='registration'><p className='singlefulldetails'>View Full Profile</p></a>
              </div>
            </MDBCol>

          </MDBRow>
        ))}
      {/* Pagination */}
      <div className='desktop-pagination'>
        {pageCount > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<i className="fa pagination-next fa-angle-left"></i>}
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'} // Define a CSS class for each page number element
              pageLinkClassName={'page-link'} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
      {/*mobile-view*/}
      {data && Array.isArray(data) && data.map((userData, index) => (
        <div className='client_top mobile-singleprofile'>
          <div className='client'>
            {data.c_profile_image ? (
              <img src={data.c_profile_image} alt='Profile Image' />
            ) : (
              <img src='default-photo-url.png' alt='Default Image' />
            )}
            <div className='danil-sebastine'>
              <p className='danil'>{userData.c_name}<span className='online-mobile'><img src='../Images/online.png'></img></span> </p>
              <p className='danil-details-mobile'>{userData.c_usercode} | Profile Created For {userData.profile_for || 'not defined'}</p>
              <p className='danil-details-mobile'>{userData.n_age || 'not defined'} Yrs, {userData.c_height}</p>
              <p className='danil-details-mobile'>{userData.religion || userData.caste ? `${userData.religion || 'religion'} / ${userData.caste || 'caste'}` : 'religion and caste not defined'}</p>
              <p className='danil-details-mobile'>{userData.qualification || 'no qualification provide '}, {userData.occupation || 'no occupation provide '},
                {userData.c_district},</p>
              <a href='registration'><p className='singlefulldetails'>View Full Profile</p></a>

            </div>

          </div>

        </div>
      ))}



      {/* Pagination */}

      <div className='desktop-mobile'>
        {pageCount > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<i className="fa pagination-next fa-angle-left"></i>}
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'} // Define a CSS class for each page number element
              pageLinkClassName={'page-link'} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>

      <Footer></Footer>
    </div>
  )
}

export default Morematches