import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  getFirestore,
  or,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../../../firebase/firebase";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../../redux/features/profile";

const BasicDetails = ({ userData, listHeights, listStars }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const getUserDetails = useSelector(getProfileDetails);
  const firebaseDB = getFirestore(firebaseApp);
  const [oldName, setOldName] = useState(userData?.name);
  const [basicDetails, setBasicDetails] = useState({
    name: userData?.name,
    gender: userData?.gender,
    dateOfBirth: userData?.date_of_birth,
    age: userData?.age,
    marital_status: userData?.marital_status,
    height_id: "",
    height_cm: userData?.height_cm,
    height_feet: userData?.height_feet,
    star_id: "",
    star: userData?.star,
    physical_status: userData?.physical_status,
    phone: userData?.phone,
    email: userData?.email,
    complexion: userData?.complexion,
    body_type: userData?.body_type,
    weight: userData?.weight,

  });
  useEffect(() => {
    const selectedHeight = listHeights.find(
      (height) => height?.height_cm == userData?.height_cm
    );
    const selectedStar = listStars.find((star) => star.star == userData?.star);
    setBasicDetails({
      name: userData?.name,
      gender: userData?.gender,
      dateOfBirth: userData?.date_of_birth,
      age: userData?.age,
      marital_status: userData?.marital_status,
      height_id: selectedHeight ? selectedHeight.id : "",
      height_cm: userData?.height_cm,
      height_feet: userData?.height_feet,
      star_id: selectedStar ? selectedStar?.id : "",
      star: userData?.star,
      physical_status: userData?.physical_status,
      phone: userData?.mobile,
      email: userData?.email,
      complexion: userData?.complexion,
      body_type: userData?.body_type,
      weight: userData?.weight,

    });
    setOldName(userData?.name);
  }, [userData]);
  const [isEditingBasicdetails, setIsEditingBasicdetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 90,
    today.getMonth(),
    today.getDate()
  );

  const handleEditClickBasicdetails = () => {
    setIsEditingBasicdetails(true);
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
    const formattedDate = `${year}-${month}-${day}`;

    const age = calculateAge(formattedDate);

    setSelectedDate(date);
    setBasicDetails({
      ...basicDetails,
      dateOfBirth: formattedDate,
      age: age.toString(),
    });
  };

  const handleSaveClickBasicdetails = async () => {
    try {
      const payload = {
        name: basicDetails.name,
        dob: basicDetails.dateOfBirth,
        age: basicDetails.age,
        height: basicDetails.height_id,
        gender: basicDetails.gender,
        matrial_status:
          basicDetails.marital_status === "Not Defined"
            ? ""
            : basicDetails?.marital_status,
        physical_status:
          basicDetails.physical_status === "Not Defined"
            ? ""
            : basicDetails?.physical_status,
        star_id: `${basicDetails.star_id}`,
        star_name:
          basicDetails.star === "Not Defined" ? "" : basicDetails?.star,
        phone: basicDetails.phone,
        email: basicDetails.email,
        complexion: basicDetails.complexion,
        body_type: basicDetails.body_type,
        weight: basicDetails.weight,

      };
      await api.updateBasicDetails(payload, token).then((data) => {
        if (data.status) {
          setIsEditingBasicdetails(false);
          setOldName(basicDetails?.name);
          if (oldName !== basicDetails.name) {
            updateFirebaseName(basicDetails.name);
          }
        } else if (data.status === 401) {
          navigate("/login");
          localStorage.clear();
        }
      });
    } catch (error) {
      console.error("Error updating profile description:", error);
    }
  };

  const updateFirebaseName = async (name) => {
    const lastMessagesRef = collection(firebaseDB, "lastMessages");

    const queryRef = query(
      lastMessagesRef,
      or(
        where("senderId", "==", getUserDetails?.profile_id),
        where("receiverId", "==", getUserDetails?.profile_id)
      )
    );

    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach(async (doc) => {
      let messageData = doc.data();
      const docRef = doc.ref;
      if (messageData.senderId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            senderName: name,
          });
        } catch (error) {
          console.error("Error updating sender name:", error);
        }
      } else if (messageData.receiverId === getUserDetails.profile_id) {
        try {
          await updateDoc(docRef, {
            receiverName: name,
          });
        } catch (error) {
          console.error("Error updating receiver name:", error);
        }
      }
    });
  };
  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Basic Details</p>
        <div className="editing-religious">
          {isEditingBasicdetails ? (
            <button onClick={handleSaveClickBasicdetails}>Save</button>
          ) : (
            <button onClick={handleEditClickBasicdetails}>
               <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Name
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails?.name}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Name
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.name}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Date of birth
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <span className="datespaceonly">
                <DatePicker
                  placeholderText=" dd-mm-yyyy"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  maxDate={maxDate}
                  minDate={minDate}
                  showYearDropdown
                  yearDropdownItemNumber={90}
                  value={basicDetails.dateOfBirth}
                  className="SelectBoxs-searchpage-profileedit"
                />
              </span>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Date of birth
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.dateOfBirth || "Not defined"}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Age
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                value={basicDetails?.age ? `${basicDetails.age} Years` : ""}
                style={{ border: "none" }}
                className="SelectBoxs-searchpage-profileedit"
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Age
            </div>{" "}
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.age || "Not defined"} Years
            </div>
          </div>
        )}

        {/* CONTACT NUMBER */}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Phone
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.phone}
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Phone
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.phone}
            </div>
          </div>
        )}
{/*         {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Phone
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails?.phone}
                
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow digits and limit length to 10
                  if (/^\d{0,10}$/.test(value)) {
                    setBasicDetails({
                      ...basicDetails,
                      phone: value,
                    });
                  }
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Phone
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.phone}
            </div>
          </div>
        )} */}

        {/* EMAIL */}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Email
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.email}
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Email
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.email}
            </div>
          </div>
        )}
{/*         {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Email
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails?.email}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Email
            </div>
            :
            <div className="" style={{ flex: 1 }}>
              {""} {basicDetails?.email}
            </div>
          </div>
        )} */}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Height
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <span className="heightpaceonly">
                <select
                  id="height"
                  name="height"
                  className="SelectBoxs-searchpage-profileedit-height"
                  value={basicDetails.height_cm} // Initialize with basicDetails.height_id
                  onChange={(e) => {
                    const selectedHeightId = e.target.value;
                    const selectedHeight = listHeights.find(
                      (height) => height?.height_cm == selectedHeightId
                    );

                    setBasicDetails({
                      ...basicDetails,
                      height_id: selectedHeight.id,
                      height_cm: selectedHeightId,
                      height_feet: selectedHeight.height_feet,
                    });
                  }}
                >
                  <option value="">Select</option>
                  {listHeights.map((height) => (
                    <option key={height?.id} value={height?.height_cm}>
                      {`${height?.height_cm} cm / ${height?.height_feet}`}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Height
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.height_cm} cm / {basicDetails?.height_feet}
            </div>
          </div>
        )}

        {/*   WEIGHT */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Weight in kg
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <input
                type="text"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails?.weight}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    weight: e.target.value,
                  });
                }}

                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Weight in kg
            </div>
            :<div className="" style={{ flex: 1 }}>  {""} {basicDetails?.weight}</div>
          </div>
        )}

      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Gender
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="gender"
                name="gender"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails.gender}
                disabled
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Gender
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.gender || "Not defined"}
            </div>
          </div>
        )}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Marital Status
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="maritalStatus"
                name="maritalStatus"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails?.marital_status}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    marital_status: e.target.value,
                  });
                }}
              >
                <option value="">Select</option>
                <option value="Never Married">Never Married</option>
                <option value="widowed">widowed</option>
                <option value="Divorced">Divorced</option>
                <option value="Seperated">Seperated</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Marital Status
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.marital_status || "Not defined"}
            </div>
          </div>
        )}

        {/* BODY TYPE */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Body Type
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="bodyType"
                name="bodyType"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails.body_type}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    body_type: e.target.value,
                  });
                }}
              >
                <option defaultValue="">Select</option>
                <option value="Average">Average</option>
                <option value="Slim">Slim</option>
                <option value="Athletic">Athletic</option>
                <option value="Heavy">Heavy</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Body Type
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.body_type || "Not defined"}
            </div>
          </div>
        )}

        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Physical Status
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="physicalStatus"
                name="physicalStatus"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails.physical_status}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    physical_status: e.target.value,
                  });
                }}
              >
                <option Value="">Select</option>
                <option value="Physically Challenged">
                  Physically Challenged
                </option>
                <option value="Normal">Normal</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Physical Status
            </div>
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.physical_status || "Not defined"}
            </div>
          </div>
        )}

        {/* COMPLEXION */}
        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Complexion
            </div>{" "}
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="complexion"
                name="complexion"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails.complexion}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    complexion: e.target.value,
                  });
                }}
              >
                <option value="Fair">Fair</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Dark">Dark</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Complexion
            </div>{" "}
            :
            <div style={{ flex: 1 }}>
              {""} {basicDetails?.complexion || "Not defined"}
            </div>
          </div>
        )}


        {isEditingBasicdetails ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Star
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="star"
                name="star"
                className="SelectBoxs-searchpage-profileedit"
                value={basicDetails.star}
                onChange={(e) => {
                  const selectedStarValue = e.target.value;
                  const selectedStar = listStars.find(
                    (star) => star.star == selectedStarValue
                  );

                  setBasicDetails({
                    ...basicDetails,
                    star_id: selectedStar.id,
                    star: selectedStarValue,
                  });
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {listStars.map((star) => (
                  <option key={star.star} value={star.star}>
                    {star.star}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Star
            </div>
            :
            <div style={{ flex: 1 }}>
              {""}
              {basicDetails?.star}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default BasicDetails;
