import React, { useEffect, useState } from "react";
import api from "../../../../Api/InterationApi";
import ReactPaginate from "react-paginate";
import UserCard from "./UserCard";
import { useDispatch, useSelector } from "react-redux";
import { getInteractionCount, setInteractionCounts } from "../../../../redux/features/profile";

function InterestedRecived() {
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const getCounts = useSelector(getInteractionCount);
  const [interestRecived, setInterestRecived] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchInterestRecived();
  }, [pageDetails]);

  const fetchInterestRecived = async () => {
    const interactionCounts = {
      ...getCounts,
      interestedRecieved: 0,
    };
    dispatch(setInteractionCounts(interactionCounts));
    
    setInterestRecived([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getInterestRecived(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setInterestRecived(data?.data?.interest);
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  return (
    <>
      <div className="pictures-client">
        {interestRecived?.length > 0 ? (
          interestRecived.map((interation, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={{
                  name: interation?.name,
                  userId: interation?.profile_code,
                  image: interation?.profile_image,
                  createdBy: interation?.profile_created_for,
                  age: interation?.age,
                  height_cm: interation?.height_cm,
                  height_feet: interation?.height_feet,
                  religion: interation?.religion,
                  education: interation?.education,
                  district: interation?.district,
                  date: interation?.date,
                  occupation: interation?.occupation,
                  gender: interation?.gender,
                }}
                interestRecived={interestRecived}
                setinterestRecived={setInterestRecived}
              />
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              {" "}
              No interest is received{" "}
            </p>
          </div>
        )}
      </div>

      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
}

export default InterestedRecived;
