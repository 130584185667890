import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { TbClipboardList } from "react-icons/tb";
import { PiUsersThreeThin } from "react-icons/pi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { FaRegEyeSlash } from "react-icons/fa6";
import ProfileDetails from "./ProfileDetails";
import ProfileMatchesList from "./Matches";

const AgentProfileRightDetails = ({ profileData, profileId }) => {
  const [isUserOnline, setIsUserOnline] = useState(false);

  return (
    <>
      <MDBCol size="6" sm="9" className="profiledetails-full">
        <p className="danils">
          {profileData?.name}
          <span className="online">
            <img src="/Images/online.png" alt="img-tick"></img>
          </span>
        </p>
        <MDBRow className="profile-row">
          <MDBCol>
            <p className="mother-tongue">
              {profileData?.age} Yrs | {profileId}
            </p>
            <p className="mother-tongue">
              {" "}
              {profileData?.heightInCm
                ? `${profileData?.heightInCm} cm / ${profileData?.heightInFeet}`
                : "height not defined"}
            </p>
            <p className="mother-tongue">
              {profileData?.religion || "Religion not defined"} /{" "}
              {profileData?.caste || "Caste not defined"}
            </p>
            <p className="mother-tongue">
              Occupation in {profileData?.occupation || "Not defined"}
            </p>
          </MDBCol>
          <MDBCol>
            <p className="mother-tongue">
              Star is {profileData?.star || "Not defined"}
            </p>

            <p className="mother-tongue">
              Lives in {profileData?.district || "Not defined"}
            </p>
            <p className="mother-tongue">
              Studied {profileData?.qualification || "Not defined"}
            </p>
          </MDBCol>
        </MDBRow>
        <Tabs
          defaultActiveKey="details"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="details"
            title={
              <>
                <span className="d-none d-md-inline">Profile</span> Details
                <TbClipboardList className="tab-icon" />
              </>
            }
          >
            <ProfileDetails profileData={profileData?.profileDetails} />
          </Tab>
          <Tab
            eventKey="matches"
            title={
              <>
                <span className="d-none d-md-inline">Profile</span> Matches
                <PiUsersThreeThin className="tab-icon" />
              </>
            }
          >
            <ProfileMatchesList />
          </Tab>
          <Tab
            eventKey="photos"
            title={
              <>
                Photos <HiOutlineSquares2X2 className="tab-icon" />
              </>
            }
          >
            Tab content for Loooonger Tab
          </Tab>
          <Tab
            eventKey="hide"
            title={
              <>
                Hide? <FaRegEyeSlash className="tab-icon" />
              </>
            }
          >
            Tab content for Contact
          </Tab>
        </Tabs>
      </MDBCol>
    </>
  );
};

export default AgentProfileRightDetails;
