const { axios, constants } = require("../../Config");
const baseURL = `${constants.baseURL}agent`;

class AgentAuthFetch {
  login(payload) {
    return axios
      .post(`${baseURL}/login`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  register(payload) {
    return axios
      .post(`${baseURL}/register`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}
export default new AgentAuthFetch();
