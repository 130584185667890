import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const Hobbies = ({ userData, sports, arts, hobbies }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [selectedHobbies, setSelectedHobbies] = useState([]);

  useEffect(() => {
    if (userData?.hobbies && userData?.hobbies.length > 0) {
      const selected = userData?.hobbies.map((item) => item.id);
      setSelectedHobbies([...selected]);
    }
  }, [userData]);

  const handleSaveClickSports = async (hobbyArray) => {
    let payload = {
      selectedHobbies: [...hobbyArray],
    };
    await api.updateHobbies(payload, token).then((data) => {
      if (data.status) {
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    });
  };

  const handleSelectHobby = (value) => {
    const findHobby = selectedHobbies.find((item) => item === value);
    let newHobbies = [];
    if (findHobby) {
      const filteredHobbies = selectedHobbies.filter(
        (hobby) => hobby !== value
      );
      newHobbies = [...filteredHobbies];
    } else {
      newHobbies = [...selectedHobbies, value];
    }
    setSelectedHobbies([...newHobbies]);
    handleSaveClickSports([[...newHobbies]]);
  };
  return (
    <MDBRow>
      <p className="profile-description">Interest & Hobbies</p>
      <ul className="ks-cboxtags">
        {hobbies.map((hobby, index) => {
          const findHobby = selectedHobbies.find((item) => item === hobby.id);
          let isSelected = false;
          if (findHobby) {
            isSelected = true;
          }
          return (
            <li key={index}>
              <input type="checkbox" checked={isSelected} onChange={() => {}} />
              <label
                htmlFor="checkboxOne"
                onClick={() => handleSelectHobby(hobby?.id)}
              >
                {hobby?.sports}
              </label>
            </li>
          );
        })}
      </ul>
    </MDBRow>
  );
};

export default Hobbies;
