import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { TbClipboardList } from "react-icons/tb";
import { PiUsersThreeThin } from "react-icons/pi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { FaRegEyeSlash, FaRegThumbsUp } from "react-icons/fa6";
import ProfileDetails from "./ProfileDetails";
import ProfileMatchesList from "./Matches";
import AgentProfilePhotos from "./Photos/AgentProfilePhotos";
import HideAgentProfileList from "./Hides";
import LikedAgentProfileList from "./Liked";
import SliderTabs from "./SliderTabs";
import InterestRecievedAgentProfileList from "./InterestRecievedAgentProfileList";
import AcceptedAgentProfileList from "./AcceptedProfiles";
import RejectedAgentProfileList from "./RejectedProfiles";
import AcceptedByOtherAgentProfileList from "./AcceptedByOthers";
import RejectedByOtherAgentProfileList from "./RejectedByOther";
import ShortlistedAgentProfileList from "./ShortlistedProfiles";
import ShortlistedByOthersAgentProfileList from "./ShortListByOthers";
import VisitedAgentProfileList from "./VisitedProfiles";
import BlockedAgentProfileList from "./BlockedProfiles";

const AgentProfileRightDetails = ({
  profileData,
  profileId,
  setProfileImages,
  profileImages,
  setSavedEditProfileDetails,
}) => {
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [activeTab, setActiveTab] = useState("details");
  const [selectedTab, setSelectedTab] = useState("Profile Details");

  // Handle tab change
  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      <MDBCol size="6" sm="9" className="profiledetails-full">
        <p className="danils">
          {profileData?.name}
          <span className="online">
            <img src="/Images/online.png" alt="img-tick"></img>
          </span>
        </p>
        <MDBRow className="profile-row">
          <MDBCol>
            <p className="mother-tongue">
              {profileData?.age} Yrs | {profileId}
            </p>
            <p className="mother-tongue">
              {" "}
              {profileData?.heightInCm
                ? `${profileData?.heightInCm} cm / ${profileData?.heightInFeet}`
                : "height not defined"}
            </p>
            <p className="mother-tongue">
              {profileData?.religion || "Religion not defined"} /{" "}
              {profileData?.caste || "Caste not defined"}
            </p>
            <p className="mother-tongue">
              Occupation in {profileData?.occupation || "Not defined"}
            </p>
          </MDBCol>
          <MDBCol>
            <p className="mother-tongue">
              Star is {profileData?.star || "Not defined"}
            </p>

            <p className="mother-tongue">
              Lives in {profileData?.district || "Not defined"}
            </p>
            <p className="mother-tongue">
              Studied {profileData?.qualification || "Not defined"}
            </p>
          </MDBCol>
        </MDBRow>
        <SliderTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {selectedTab === "Profile Details" &&
          profileData &&
          Object.keys(profileData).length > 0 && (
            <ProfileDetails
              profileData={profileData?.profileDetails}
              partnerPreferenceData={profileData?.partnerDetails}
              setSavedEditProfileDetails={setSavedEditProfileDetails}
            />
          )}
        {selectedTab === "Profile Matches" && (
          <ProfileMatchesList activeTab={selectedTab} />
        )}
        {selectedTab === "Photos" && (
          <AgentProfilePhotos
            profileImages={profileImages}
            setProfileImages={setProfileImages}
          />
        )}
        {selectedTab === "visited" && (
          <VisitedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "Liked" && (
          <LikedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "Received" && (
          <InterestRecievedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "Accepted" && (
          <AcceptedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "AcceptedByOther" && (
          <AcceptedByOtherAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "Rejected" && (
          <RejectedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "RejectedByOther" && (
          <RejectedByOtherAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "shortlist" && (
          <ShortlistedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "shortlistOther" && (
          <ShortlistedByOthersAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "blocked" && (
          <BlockedAgentProfileList activeTab={selectedTab} />
        )}
        {selectedTab === "Hide" && (
          <HideAgentProfileList activeTab={selectedTab} />
        )}
        {/* <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange} // Trigger tab change
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="details"
            title={
              <>
                <span className="d-none d-md-inline">Profile</span> Details
                <TbClipboardList className="tab-icon" />
              </>
            }
          >
            {profileData && Object.keys(profileData).length > 0 && (
              <ProfileDetails
                profileData={profileData?.profileDetails}
                partnerPreferenceData={profileData?.partnerDetails}
                setSavedEditProfileDetails={setSavedEditProfileDetails}
              />
            )}
          </Tab>
          <Tab
            eventKey="matches"
            title={
              <>
                <span className="d-none d-md-inline">Profile</span> Matches
                <PiUsersThreeThin className="tab-icon" />
              </>
            }
          >
            <ProfileMatchesList activeTab={activeTab} />
          </Tab>
          <Tab
            eventKey="photos"
            title={
              <>
                Photos <HiOutlineSquares2X2 className="tab-icon" />
              </>
            }
          >
            <AgentProfilePhotos
              setProfileImages={setProfileImages}
              profileImages={profileImages}
            />
          </Tab>
          <Tab
            eventKey="like"
            title={
              <>
                Liked <FaRegThumbsUp className="tab-icon" />
              </>
            }
          >
            <LikedAgentProfileList activeTab={activeTab} />
          </Tab>
          <Tab
            eventKey="hide"
            title={
              <>
                Hide? <FaRegEyeSlash className="tab-icon" />
              </>
            }
          >
            <HideAgentProfileList activeTab={activeTab} />
          </Tab>
        </Tabs> */}
      </MDBCol>
    </>
  );
};

export default AgentProfileRightDetails;
