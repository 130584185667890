import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { BsEye, BsEyeSlash } from "react-icons/bs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import CustomModal from '../CustomModel';
import { useNavigate } from 'react-router-dom';
import api from "../../Api/SettingApi"
function ChangePassword() {
    const navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [changeError, setChangeError] = useState("");
    const [showModalcustom, setShowModalcustom] = useState(false);
    const [modalTitlecustom, setModalTitlecustom] = useState("");
    const [modalMessagecustom, setModalMessagecustom] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState([]);
    const [newPassword, setNewPassword] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState([]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = localStorage.getItem("authToken");
            if (!token) {
                navigate("/login");
                return;
            }
            if (!token) {
                console.error("Authentication token missing");
            }
            const tokenData = parseToken(token);
            if (tokenData && tokenData.exp) {
                const currentTime = Math.floor(Date.now() / 1000);
                if (tokenData.exp < currentTime) {
                    clearToken();
                    navigate("/login");
                }
            }
        };

        checkTokenExpiration();
    }, [navigate]);

    function parseToken(token) {
        try {
            const tokenData = JSON.parse(atob(token.split(".")[1]));
            return tokenData;
        } catch (error) {
            return null;
        }
    }

    function clearToken() {
        localStorage.removeItem("authToken");
    }

    const handleCloseModalcustom = () => {
        setShowModalcustom(false);
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
    };
    const handleOpenModalcustom = () => {
        setShowModalcustom(true);
    };
    const handleToggleCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };
    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const schema = yup.object().shape({
        currentPassword: yup
            .string()
            .required("Please enter your current password"),

        newPassword: yup
            .string()
            .required("New password is required")
            .notOneOf(
                [yup.ref("currentPassword")],
                "New password must be different from current password"
            )
            .min(8, "Password must have at least 8 characters")
            .max(20, "Password cannot exceed 20 characters")
            .matches(
                /^(?=.*[a-z])(?=.*\d)(?!.*[<>])\S*$/,
                "Password must contain letters and digits."
            ),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("newPassword"), null], "Passwords must match")
            .required("Please confirm your password"),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitForm = async (formData) => {
        try {
            await api.changePassword(formData, token).then(({ data }) => {
                if (data.status) {
                    setChangeError("");
                    setModalTitlecustom(data.message);
                    setModalMessagecustom(data.message);
                    handleOpenModalcustom()
                }
            });

        } catch (error) {
            setChangeError("An error occurred while deleting the account.");
            console.error(error);
        }
    };
    const handleConfirmClick = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);

        // Redirect to /Changepassword and refresh the page
        window.location.href = "/Changepassword";
    };
    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div className="profileprivacy-explanation">
            <p className="change-resion">Change Password</p>
            <Form
                className="formregister-changepassword"
                onSubmit={handleSubmit(submitForm)}

            >
                <p className="newpassword">
                    <div className="password-container">
                        <Form.Control
                            type={showCurrentPassword ? "text" : "password"}
                            placeholder="Enter current password"
                            className="changepassword"
                            name="currentPassword"
                            {...register("currentPassword")}
                        />
                        <span
                            className="password-toggle-icon-mobile"
                            onClick={handleToggleCurrentPassword}
                            style={{
                                position: "absolute",
                                top: "50%",
                            }}
                        >
                            {showCurrentPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                    </div>
                    {errors.currentPassword && (
                        <label className="errormessage">
                            {errors.currentPassword.message}
                        </label>
                    )}

                    <div className="password-container">
                        <Form.Control
                            {...register("newPassword")}
                            type={showNewPassword ? "text" : "password"}
                            placeholder="Enter new password"
                            className="changepassword"
                        />
                        <span
                            className="password-toggle-icon-mobile"
                            onClick={handleToggleNewPassword}
                            style={{
                                position: "absolute",
                                top: "50%",
                            }}
                        >
                            {showNewPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                    </div>
                    {errors.newPassword && (
                        <label className="errormessage">
                            {errors.newPassword.message}
                        </label>
                    )}

                    <div className="password-container">
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm password"
                            className="changepassword"
                            {...register("confirmPassword")}
                        />
                        <span
                            className="password-toggle-icon-mobile"
                            onClick={handleTogglePassword}
                            style={{
                                position: "absolute",
                                top: "50%",
                            }}
                        >
                            {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                    </div>
                    {errors.confirmPassword && (
                        <label className="errormessage">
                            {errors.confirmPassword.message}
                        </label>
                    )}
                </p>
                {changeError && (
                    <div>
                        {changeError && (
                            <label style={{ color: 'red' }}>{changeError}</label>
                        )}
                    </div>
                )}

                <Button className="searchButtons-profileprivacy" type="submit">
                    Confirm
                </Button>

            </Form>
            <CustomModal
                show={showModalcustom}
                handleClose={handleCloseModalcustom}
                title="Change Password"
                handleCloseModal={handleCloseModal}
              /*    message={modalMessagecustom}  */
                message="Your password was changed successfully"
            >
            </CustomModal>

        </div>
    )
}

export default ChangePassword