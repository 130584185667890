import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import React from "react";
import { Container } from "react-bootstrap";

const AdCard = () => {
  return (
    <div className="need-help-all" key="advertisement">
      <MDBListGroup className="lg-hid need-help">
        <MDBListGroupItem style={{ padding: "0%" }}>
          <img
            src="../Images/sucesskaliyanam.png"
            className="img-fluid "
            alt="..."
            style={{ width: "100%", opacity: "0.5" }}
          />
          <div className="cption">
            <Container>
              <div className="myhome-service">
                <p className="find-partner">
                  Need help to find a life partner?
                </p>
                <p className="personalized">
                  Get personalized service from a dedicated <br /> Relationship
                  Manager
                </p>
              </div>
            </Container>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
    </div>
  );
};

export default AdCard;
