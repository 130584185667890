import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import "../../style/navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxChatBubble, RxUpdate } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { GoGear } from "react-icons/go";
import { CgLogOut } from "react-icons/cg";

import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../../firebase/firebase";
import api from "../../Api/ProfileApi";
import authApi from "../../Api/AuthApi";
import {
  getFcmToken,
  getInteractionCount,
  getNotificationCount,
  getProfileDetails,
  setInteractionCounts,
  setNotificationCount,
  setProfileDetails,
} from "../../redux/features/profile";
// import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import { BsHouseDoor } from "react-icons/bs";
import { LuHeartHandshake } from "react-icons/lu";
function AgentNavbar({ selectedTab, setSelectedTab, setTitleDiscription }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const getUserDetails = useSelector(getProfileDetails);
  const getNoteCount = useSelector(getNotificationCount);
  const getCounts = useSelector(getInteractionCount);
  const FcmToken = useSelector(getFcmToken);
  const firebaseDB = getFirestore(firebaseApp);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [messageCount, setMessageCount] = useState(0);
  const [userPhoto, setuserPhoto] = useState(null);
  const [matchCount, setMatcheCount] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      if (getUserDetails?.profile_id) {
        updateOnline(getUserDetails?.profile_id);
      }
    }
  }, []);

  const updateOnline = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    }
  };

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  //photo view
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          document.cookie =
            "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          // Clear the local storage item (if you use it for storing the token)
          localStorage.clear();
          navigate("/agent/login");
          return;
        }

        //     await api.getUserProfile(token).then(({ data }) => {
        //       if (data.status) {
        //         setuserPhoto(data?.user_profile?.profile_image);
        //         dispatch(setProfileDetails(data?.user_profile));
        //         dispatch(setNotificationCount(data?.notification_count));
        //         const interactionCounts = {
        //           interestedProfiles: data?.interested_profile_count,
        //           interestedRecieved: data?.interest_received_count,
        //           acceptedProfiles: data?.interest_accepted_count,
        //           acceptedByOtherProfiles: data?.interest_accepted_other_count,
        //           rejectedProfiles: data?.interest_rejected_profile_count,
        //           rejectedByOtherProfiles: data?.interest_rejected_other_count,
        //           shortlistedMe: data?.shortlist_by_me_count,
        //           shortlistedByOther: data?.shortlist_by_other_count,
        //           visitedProfiles: data?.visited_count,
        //         };
        //         dispatch(setInteractionCounts(interactionCounts));
        //       } else {
        //         if (data.statusCode === 401) {
        //           localStorage.clear();
        //           navigate("/agent/login");
        //         }
        //       }
        //     });
      } catch (error) {
        console.error("Error fetching user newmatches details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  //   useEffect(() => {

  //     return () => {
  //       const token = localStorage.getItem("authToken");
  //       if (token) {
  //         onSnapshot(
  //           query(
  //             collection(firebaseDB, "lastMessages"),
  //             where("receiverId", "==", getUserDetails?.profile_id),
  //             where("isViewed", "==", false)
  //           ),
  //           (querySnapShot) => {
  //             const data = querySnapShot.docs.map((doc) => doc.data());
  //             const notRejectedData = data.filter(
  //               (da) => da.status !== "REJECTED"
  //             );
  //             setMessageCount(notRejectedData.length);
  //           }
  //         );
  //       }
  //     };
  //   }, []);

  const navigate = useNavigate();
  //logout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      console.log("Authentication token", token);

      // await authApi.logout({ fcmtoken: FcmToken }, token);
      // await updateOnlineStatus(getUserDetails?.profile_id);
      // Clear the local browser cookie
      document.cookie =
        "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      // Clear the local storage item (if you use it for storing the token)
      localStorage.clear();
      navigate("/agent/login");

      console.log("logout sucessful");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const updateOnlineStatus = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "OFFLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "OFFLINE",
        time: currentTimeInUTC,
      });
    }
  };

  const navigateNotifications = () => {
    navigate("/notifications");
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Navbar
        collapseOnSelect
        className="navbarnav"
        expand="lg"
        sticky="top"
        bg=""
        variant="dark"
      >
        <Container className="logoContainer">
          <p
            className="mobi"
            style={{ marginBottom: "0px" }}
            onClick={handleShow}
          >
            <span className="navbar-toggler-icon"></span>
          </p>

          <Offcanvas className="navbar-close" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <hr></hr>
              <div className="empeditss">
                <li className="empedit">
                  <a href="/my-profile">MY PROFILE</a>
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li className="empedit">
                  <a href="/upload-profile">UPLOAD PROFILE</a>
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li className="empedit">
                  <a href="/all-profile">ALL PROFILE</a>
                </li>
                <hr></hr>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <a href="/agent">
            <img src="/Images/logo-white.png" alt="My Image" className="logo" />
          </a>
          <div className="mobi">
            <div
              className="notification cursor-pointer"
              onClick={navigateNotifications}
            >
              <img src="/Images/notification.png" alt="Notifications" />
              {getNoteCount > 0 && (
                <span className="notification-count">{getNoteCount}</span>
              )}
            </div>

            <div
              className="notification-login"
              onClick={() => setPopupVisible(!popupVisible)}
            >
              {getUserDetails?.profile_image ? (
                <img
                  src={getUserDetails?.profile_image}
                  alt="Profile"
                  onClick={togglePopup}
                  className="rounded-profile-image"
                />
              ) : (
                <img
                  src="/Images/profile.png"
                  alt="Profile"
                  onClick={togglePopup}
                  className="rounded-profile-image"
                />
              )}
              {popupVisible && (
                <div className="popup-profile" onClick={togglePopup}>
                  <div className="mb-1">
                    <a href="/agent">
                      <div className="d-flex align-items-center gap-1">
                        <BsHouseDoor
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">My Home</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/edit-profile">
                      <div className="d-flex align-items-center gap-1">
                        <MDBIcon
                          far
                          icon="user"
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">Edit Profile</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/matches">
                      <div className="d-flex align-items-center gap-1">
                        <LuHeartHandshake
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">Matches</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/messages">
                      <div className="d-flex align-items-center gap-1">
                        <RxChatBubble
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">
                          Messages
                          {messageCount > 0 && (
                            <span className="count">({messageCount})</span>
                          )}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/search" className="flex align-items-center">
                      <div className="d-flex align-items-center gap-1">
                        <IoSearchOutline
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">Search</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/upgrade">
                      <div className="d-flex align-items-center gap-1">
                        <RxUpdate
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">Upgrade now</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <Link href="/agent/settings">
                      <div className="d-flex align-items-center gap-1">
                        <GoGear
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />
                        <span className="setting-edit">Settings</span>
                      </div>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <a href="#" onClick={handleLogout}>
                      <div className="d-flex align-items-center gap-1">
                        <CgLogOut
                          style={{ fontSize: "17px", color: "#65044A" }}
                        />{" "}
                        <span className="setting-edit">Logout</span>
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav" className="nav-login">
            <div className="navbar_itesms">
              <Nav className="menubar">
                <Nav.Link
                  as={Link}
                  to="/agent"
                  exact="true"
                  style={{
                    borderBottom:
                      pathname === "/agent" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MY HOME
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/matches"
                  style={{
                    borderBottom:
                      pathname === "/matches" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MATCHES
                  {matchCount > 0 && (
                    <span className="count">({matchCount})</span>
                  )}
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/messages"
                  style={{
                    borderBottom:
                      pathname === "/messages" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MESSAGES
                  {messageCount > 0 && (
                    <span className="count">({messageCount})</span>
                  )}
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/search"
                  style={{
                    borderBottom:
                      pathname === "/search" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  SEARCH
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/Upgrade"
                  style={{
                    borderBottom:
                      pathname === "/Upgrade" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  UPGRADE NOW
                </Nav.Link>

                <div className="desk">
                  <div
                    className="notification cursor-pointer"
                    onClick={navigateNotifications}
                  >
                    <img src="/Images/notification.png" alt="Notifications" />
                    {getNoteCount > 0 && (
                      <span className="notification-count">{getNoteCount}</span>
                    )}
                  </div>

                  <div
                    className="notification-login"
                    onClick={() => setPopupVisible(!popupVisible)}
                  >
                    {getUserDetails?.profile_image ? (
                      <img
                        src={getUserDetails?.profile_image}
                        alt="Profile"
                        onClick={togglePopup}
                        className="rounded-profile-image"
                      />
                    ) : (
                      <img
                        src="/Images/profile.png"
                        alt="Profile"
                        onClick={togglePopup}
                        className="rounded-profile-image"
                      />
                    )}

                    {popupVisible && (
                      <div className="popup-profile" onClick={togglePopup}>
                        <a href="/edit-profile">
                          <MDBIcon far icon="user" />{" "}
                          <span className="setting-edit">Edit Profile</span>
                        </a>
                        <br />
                        <br />
                        <Link to="/agent/settings">
                          <MDBIcon fas icon="cog" size="1x" /> Settings
                        </Link>
                        <br />
                        <br />
                        <a href="#" onClick={handleLogout}>
                          <MDBIcon fas icon="sign-out-alt" size="1x" /> Logout
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default AgentNavbar;
