import React from "react";

const Mobileslider = ({
  setShowPopup,
  selectedTab,
  setSelectedTab,
  setTitleDiscription,
}) => {
  return (
    <div className="employee-listitems-mobile ">
      <div className="empedit-">
        <li className="empedit">
          <a href="add-photo">Add/Edit Photos</a>
        </li>
        <hr></hr>
        <li className="empedit">
          <a href="edit-profile">View/Edit Profile</a>
        </li>
        <hr></hr>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Interest Sent" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Interest Sent");
              setTitleDiscription("Members you have interest ");
              setShowPopup(false);
            }}
          >
            Interested Profile
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Interested Received" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Interested Received");
              setTitleDiscription("Members who sent has interest in you!");
              setShowPopup(false);
            }}
          >
            INTERESTED RECEIVED
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Accepted Profile" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Accepted Profile");
              setTitleDiscription("Members you have accepted");
              setShowPopup(false);
            }}
          >
            Accepted Profile
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Accepted By Other" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Accepted By Other");
              setTitleDiscription("Members you have accepted");
              setShowPopup(false);
            }}
          >
            Accepted By Other
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Rejected Profile" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Rejected Profile");
              setTitleDiscription("Members who rejected you!");
              setShowPopup(false);
            }}
          >
            Rejected Profile
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Rejected By Other" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Rejected By Other");
              setTitleDiscription("Members who rejected you!");
              setShowPopup(false);
            }}
          >
            Rejected By Other
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Shortlisted By Other" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Shortlisted By Other");
              setTitleDiscription("Members Who have Shortlisted you!");
              setShowPopup(false);
            }}
          >
            Shortlisted By Other
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "My Shortlist" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("My Shortlist");
              setTitleDiscription("Members Who have Shortlisted by you!");
              setShowPopup(false);
            }}
          >
            My Shortlist
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Who Visited My Profile" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Who Visited My Profile");
              setTitleDiscription("Members Visited!");
              setShowPopup(false);
            }}
          >
            Who Visited
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Hide Profile" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Hide Profile");
              setTitleDiscription("Members Hide!");
              setShowPopup(false);
            }}
          >
            Hide Profile
          </li>
          <hr></hr>
        </div>
        <div className="empeditss">
          <li
            className={`empedit cursor-pointer ${
              selectedTab === "Blocked Profile" && "selected-myhome-list"
            }`}
            onClick={() => {
              setSelectedTab("Blocked Profile");
              setTitleDiscription("Members Who Blocked!");
              setShowPopup(false);
            }}
          >
            Blocked Profile
          </li>
          <hr></hr>
        </div>
      </div>
    </div>
  );
};

export default Mobileslider;
