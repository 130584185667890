import { axios, constants } from "../Config";

class MatchApiFetch {
  getAllMatches(payload, token) {
    return axios
      .post(`${constants.baseURL}matches/all`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getNewMatches(payload, token) {
    return axios
      .post(`${constants.baseURL}matches/new`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getLocationMatches(payload, token) {
    return axios
      .post(`${constants.baseURL}matches/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.")
      });
  }
  getEducationMatches(payload, token) {
    return axios
      .post(`${constants.baseURL}matches/education`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.")
      });
  }

}

export default new MatchApiFetch()