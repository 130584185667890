import React from "react";
import { MDBCol } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import {
  getInteractionCount,
  getProfileDetails,
} from "../redux/features/profile";

const ProfileSideBar = ({
  selectedTab,
  setSelectedTab,
  setTitleDiscription,
}) => {
  const getUserDetails = useSelector(getProfileDetails);
  const getCounts = useSelector(getInteractionCount);

  return (
    <>
      <MDBCol size="6" sm="3" className="employee-listitems columns-container">
        <div className="emppic "  id = "mobile_profile">
          <div className="account-switch-container row">
            <div
              className={`py-2 ${
                getUserDetails?.account_type === "Regular"
                  ? "col-7 selected-account-type"
                  : "col-5"
              }`}
            >
              <p className="text-center m-0">Regular</p>
            </div>
            <div
              className={`py-2 ${
                getUserDetails?.account_type === "Premium"
                  ? "col-7 selected-account-type"
                  : "col-5"
              }`}
            >
              <p className="text-center m-0">Premium</p>
            </div>
          </div>
          <div className="empfull-layer">
            <div className="layer container-fluid">
              <img
                src="../Images/Layer_1.png"
                className="layerfirst"
                alt="icon-1"
              ></img>
          
              <span className="active-online-profile ">
                <span className="dot "></span> Online
              </span>
             
            </div>
            <div >
            {getUserDetails?.profile_image ? (
              <img
                src={getUserDetails?.profile_image}
                className="img-fluid searchemployee mx-auto d-flex"
                alt="User"
              />
            ) : (
              <img
                src="default-photo-url.png"
                className="img-fluid searchemployee mx-auto d-flex"
                alt="Default"
              />
            )}
            </div> 
            <p className="empname">{getUserDetails?.name}</p>
            <p className="empid">
              {getUserDetails?.age} Yrs | {getUserDetails?.profile_id}
            </p>
            <hr />
            <p className="percentage">
              <span className="dark">Profile Completeness</span>{" "}
              <span className="yellow">
                {getUserDetails?.percentage_complete}%
              </span>
              <a href="edit-profile">
                <span className="green"> Complete Profile</span>
              </a>
            </p>
          </div>
        </div>
        <div className="listitems-myhome">
          <hr></hr>
          <div className="empeditss">
            <li className="empedit">
              <a href="/add-photo">Add/Edit Photos</a>
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li className="empedit">
              <a href="/edit-profile">View/Edit Profile</a>
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Interest Sent" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Interest Sent");
                setTitleDiscription("Members you have interest ");
              }}
            >
              Interested Profile {""}
              {getCounts?.interestedProfiles > 0 && (
                <span>({getCounts?.interestedProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Interested Received" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Interested Received");
                setTitleDiscription(" Members who sent has interest in you!");
              }}
            >
              Interested Received {""}
              {getCounts?.interestedRecieved > 0 && (
                <span>({getCounts?.interestedRecieved})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Accepted Profile" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Accepted Profile");
                setTitleDiscription("Members you have accepted");
              }}
            >
              Accepted Profile {""}
              {getCounts?.acceptedProfiles > 0 && (
                <span>({getCounts?.acceptedProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Accepted By Other" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Accepted By Other");
                setTitleDiscription("Members you have accepted");
              }}
            >
              Accepted By Other {""}
              {getCounts?.acceptedByOtherProfiles > 0 && (
                <span>({getCounts?.acceptedByOtherProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Rejected Profile" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Rejected Profile");
                setTitleDiscription("Members who rejected you!");
              }}
            >
              Rejected Profile {""}
              {getCounts?.rejectedProfiles > 0 && (
                <span>({getCounts?.rejectedProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Rejected By Other" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Rejected By Other");
                setTitleDiscription("Members who rejected you!");
              }}
            >
              Rejected By Other {""}
              {getCounts?.rejectedByOtherProfiles > 0 && (
                <span>({getCounts?.rejectedByOtherProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Shortlisted By Other" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Shortlisted By Other");
                setTitleDiscription("Members Who have Shortlisted you!");
              }}
            >
              Shortlisted By Other {""}
              {getCounts?.shortlistedMe > 0 && (
                <span>({getCounts?.shortlistedMe})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "My Shortlist" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("My Shortlist");
                setTitleDiscription("Members Who have Shortlisted by you!");
              }}
            >
              My Shortlist {""}
              {getCounts?.shortlistedByOther > 0 && (
                <span>({getCounts?.shortlistedByOther})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Who Visited My Profile" &&
                "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Who Visited My Profile");
                setTitleDiscription("Members Visited!");
              }}
            >
              Who Visited {""}
              {getCounts?.visitedProfiles > 0 && (
                <span>({getCounts?.visitedProfiles})</span>
              )}
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Hide Profile" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Hide Profile");
                setTitleDiscription("Members Hide!");
              }}
            >
              Hide Profile
            </li>
            <hr></hr>
          </div>
          <div className="empeditss">
            <li
              className={`empedit cursor-pointer ${
                selectedTab === "Blocked Profile" && "selected-myhome-list"
              }`}
              onClick={() => {
                setSelectedTab("Blocked Profile");
                setTitleDiscription("Members Who Blocked!");
              }}
            >
              Blocked Profile
            </li>
            <hr></hr>
          </div>
        </div>
      </MDBCol>
    </>
  );
};

export default ProfileSideBar;
