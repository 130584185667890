import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Chat from "../Chat";
import ProfileDescriptions from "./ProfileDescriptions";
import PartnerPreference from "./PartnerPreference";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { firebaseApp } from "../../firebase/firebase";
import api from "../../Api/ProfileApi";
import GeneralApi from "../../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";
import { TbClipboardList } from "react-icons/tb";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { RxChatBubble } from "react-icons/rx";
import { FaRegEyeSlash, FaRegThumbsUp, FaThumbsUp } from "react-icons/fa6";

const Details = ({
  userId,
  userData,
  userImages,
  partnerPreference,
  setIsHideProfile,
  setIsBlocked,
  user,
}) => {
  const token = localStorage.getItem("authToken");
  const firebaseDB = getFirestore(firebaseApp);
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [shortListed, setShortList] = useState(false);
  const [isProfileLiked, setIsProfileLiked] = useState(false);

  useEffect(() => {
    setShortList(userData?.isShortlisted);
    setIsProfileLiked(userData?.isLiked);
  }, [userData]);

  useEffect(() => {
    const onGetOnlineStatus = onSnapshot(
      doc(firebaseDB, "users", userId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().status === "ONLINE") {
            setIsUserOnline(true);
          } else {
            setIsUserOnline(false);
          }
        } else {
          setIsUserOnline(false);
        }
      }
    );

    return () => {
      onGetOnlineStatus();
    };
  }, [userData]);

  const handleChatClick = () => {
    setOpenChat(true);
  };
  const handleCloseChat = () => {
    setOpenChat(false);
  };
  const handleShortList = () => {
    api.shortListProfile(userId, token).then(({ data }) => {
      if (data?.status) {
        setShortList(!shortListed);
      }
    });
  };
  const handleHideProfile = () => {
    api.hideProfile(userId, token).then(({ data }) => {
      if (data?.status) {
        setIsHideProfile(true);
      }
    });
  };
  const handleLikeProfile = () => {
    api.likeProfile(userId, token).then(({ data }) => {
      if (data.status) {
        console.log("rextcfh");
        setIsProfileLiked(!isProfileLiked);
      }
      else{
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  const [isAccept, setIsAccept] = useState("");

useEffect(() => {
  if (user && user.isAccepted !== undefined) {
    setIsAccept(user.isAccepted);
  }
}, [user]);

const handleClick = () => {
  if (!isAccept) {
    handleLikeProfile();
  }
};

  const handleBlockUser = () => {
    GeneralApi.blockUser(userId, token).then(({ data }) => {
      if (data?.status) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsBlocked(true);
        handleCloseChat();
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  return (
    <>
      <MDBCol size="6" sm="9" className="profiledetails-full">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <p className="profile-editheading">Profile View</p> */}
        <p className="danils">
          {userData?.name}
          <span className="online">
            <img src="../Images/online.png"></img>
          </span>
          {isUserOnline ? (
            <span className="active-online">
              <span className="dot"></span> Online
            </span>
          ) : (
            <span className="active-offline">
              <span className="active-offline-dot"></span> Offline
            </span>
          )}
        </p>
        <MDBRow className="profile-row">
          <MDBCol>
            <p className="mother-tongue">
              {userData?.age} Yrs | {userData?.profile_id}
            </p>
            <p className="mother-tongue">
              {" "}
              {userData?.height_cm
                ? `${userData?.height_cm} cm / ${userData?.height_feet}`
                : "height not defined"}
            </p>

            <p className="mother-tongue">
              {userData?.religion || "Religion not defined"} /{" "}
              {userData?.caste || "Caste not defined"}
            </p>
            <p className="mother-tongue">
              Occupation in {userData?.occupation || "Not defined"}
            </p>
          </MDBCol>
          <MDBCol>
            <p className="mother-tongue">
              Star is {userData?.star || "Not defined"}
            </p>

            <p className="mother-tongue">
              Lives in {userData?.district || "Not defined"}
            </p>
            <p className="mother-tongue">
              Studied {userData?.qualification_main || "Not defined"}
            </p>
          </MDBCol>
        </MDBRow>
        <div className="short-links">
          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setOpenChat(!openChat);
            }}
          >
            <p>Chat</p>
            <RxChatBubble className="mirror-icon match-bottom-icon" />
          </div>

          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleShortList();
            }}
          >
            {shortListed ? (
              <>
                <p>Shortlisted</p>
                <HiClipboardDocumentCheck className="match-bottom-icon" />
              </>
            ) : (
              <>
                <p>Shortlist?</p>
                <TbClipboardList className="match-bottom-icon" />
              </>
            )}
          </div>

          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleHideProfile();
            }}
          >
            <p>Hide?</p>
            <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
          </div>

          <div
            className="match-bottom-link cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleClick();
              
            }}
          >
            {isProfileLiked ? (
              <>
                <p>Liked</p>
                <FaThumbsUp className="match-bottom-icon" />
              </>
            ) : (
              <>
                <p>Like His Profile?</p>
                <FaRegThumbsUp className="match-bottom-icon" />
              </>
            )}
          </div>
        </div>
        <ProfileDescriptions userData={userData} />
        <PartnerPreference
          userData={userData}
          partnerPreference={partnerPreference}
        />
      </MDBCol>
      {openChat && (
        <Chat
          chatData={{
            userId: userData?.profile_id,
            name: userData?.name,
            image: userImages[0] ? userImages[0] : "",
          }}
          onClose={handleCloseChat}
          from="PROFILE"
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
};

export default Details;
