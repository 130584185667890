import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import api from "../../Api/AuthApi";

const ForgotPassword = ({
  modalType,
  setModalType,
  setOtpExpiration,
  setForgotPassword,
}) => {
  const [formData, setFormData] = useState({
    userName: "",
    error: "",
  });

  const isValidEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^[0-9]+$/;
    return mobileRegex.test(mobileNumber);
  };

  const handleEmailOrMobile = (e) => {
    const value = e.target.value;
    if (value) {
      if (!isValidEmail(value) && !isValidMobileNumber(value)) {
        setFormData((prevValue) => ({
          ...prevValue,
          error: "Please enter a valid Mobile number/Email id.",
        }));
      } else {
        setFormData((prevValue) => ({
          ...prevValue,
          error: "",
        }));
      }
    } else {
      setFormData((prevValue) => ({
        ...prevValue,
        error: "Please enter Mobile number/Email id",
      }));
    }

    setFormData((prevValue) => ({
      ...prevValue,
      userName: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (!formData.userName) {
        setFormData((prevValue) => ({
          ...prevValue,
          error: "Please enter Mobile number/Email id",
        }));
      }

      if (formData?.userName) {
        const payload = {
          user: formData?.userName.trim(),
        };
        api.forgotPassword(payload).then(({ data }) => {
          if (data.status) {
            setOtpExpiration(data?.otpExpirationTime * 60);
            setForgotPassword((prevValue) => ({
              ...prevValue,
              user: formData?.userName,
            }));
            setModalType("otp");
          } else {
            setFormData((prevValue) => ({
              ...prevValue,
              error: data?.message,
            }));
          }
        });
      }
    } catch (err) {
      console.log("err", err);
      setFormData((prevValue) => ({
        ...prevValue,
        error: "Sorry, something went wrong",
      }));
    }
  };
  return (
    <MDBModal
      show={modalType === "forgot" ? true : false}
      tabIndex="-1"
      className="model-full"
      onHide={() => {
        setModalType("");
      }}
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader className="forgotpassword-header">
            <div className="forgot-header">
              <MDBModalTitle>Forgot Password?</MDBModalTitle>
            </div>
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              onClick={() => {
                setModalType("");
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <p className="forgot-paragraph">
            Enter your Registered Mobile number or Email ID and
            <br></br>
            We'll send you a link and an OTP to reset your password
          </p>

          <div className="box-size-forgot">
            <MDBInput
              wrapperClass="mb-0"
              className=""
              label="Enter your Mobile Number/Email ID"
              id="formControlLg"
              type="text"
              size="lg"
              value={formData?.userName}
              onChange={handleEmailOrMobile}
            />
            {formData?.error && (
              <p className="text-danger">{formData?.error}</p>
            )}
          </div>
          <MDBModalFooter>
            <MDBBtn className="forgot-submit" onClick={onSubmit}>
              Submit
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ForgotPassword;
