import React from 'react';
import '../style/matches.css'
function NotFound() {

  return (
    <div>
      <p className='usernotFpunnd'>404 - Page Not Found</p>
      <p className='usernotFpunnd'>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
