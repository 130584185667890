import { MDBIcon } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";

const ProfileDescription = ({ userData, setUserData }) => {
  const token = localStorage.getItem("authToken");
  const [profileDescription, setProfileDescription] = useState(
    userData?.profile_description === "Not Defined" ? "" : userData?.profile_description
  );
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500; 
   
useEffect(() => {
  setProfileDescription (userData?.profile_description === "Not Defined" ? "" : userData?.profile_description);
},[userData])

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharLimit) {
      setProfileDescription(text);
      setUserData((prev) => ({
        ...prev,
        profile_description: text,
      }));
      setCharCount(text.length);
    }
  };

  const handleEditClickDescription = () => {
    setIsEditingDescription(true);
  };

  const handleSaveClickDescription = async () => {
    try {
      await api
        .updateProfileDescription(
          { profile_description: profileDescription },
          token
        )
        .then((data) => {
          if (data.status) {
            setIsEditingDescription(false);
          }
        });
    } catch (error) {
      console.error("Error updating profile description:", error);
    }
  };

  return (
    <>
    
  <div className="user-description">
  <p className="profile-description">Profile Description</p>
      <div className="editing-religious-save">
        {isEditingDescription ? (
          <button onClick={() => handleSaveClickDescription()}>Save</button>
        ) : (
          <button onClick={() => handleEditClickDescription()}>
             <MDBIcon far icon="edit" /> Edit
          </button>
        )}
      </div>
    
      </div>

      {isEditingDescription ? (
        <>
          <textarea
            className="profile-description-textarea"
            value={profileDescription}
            onChange={handleDescriptionChange}
            rows={10}
            style={{
              maxWidth: "100%",
              overflow: "auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxHeight: "200px",
            }}
            
          />

          {charCount > maxCharLimit && (
            <p className="error-message">
              Character limit exceeded (max 500 characters).
            </p>
          )}
          <p className="maximumcharacters">
           {charCount} / {maxCharLimit}
          </p>
        </>
      ) : (
        <p className="profile-des ">
          {userData?.profile_description || "Not defined"}
        </p>
      )}
      <p className="character-limit">
      
      </p>
     

    </>
   
  );
};

export default ProfileDescription;
