import React, { useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import Footer from "../Footer";
import { Container } from "react-bootstrap";
import Nva from "../Nva";
import { Helmet } from "react-helmet";
import PrivacyPolicyPage from "./PrivacyPolicyPage";

const PrivacyPolicy = () => {  
    const token = localStorage.getItem("authToken");
    const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Indian Brides & Grooms Matrimony</title>
      </Helmet>

      <section className="matches-background">
      {token ? (
          <Navbars
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
        ) : (
          <Nva />
        )}


        <Container>
        <PrivacyPolicyPage
            selectedTab={selectedTab}
            titleDiscription={titleDiscription}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default PrivacyPolicy;
