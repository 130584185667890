import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import AgentBasicDetails from "./BasicDetails";
import AgentReligiousDetails from "./AgentReligiousDetails";
import AgentProfessionalDetails from "./AgentProfessionalDetails";
import AgentLocationDetails from "./AgentLocationDetails";
import AgentFamilyDetails from "./AgentFamilyDetails";
import AgentHobbies from "./AgentHobbies";
import AgentProfileApi from "../../../../Api/Agent/AgentProfileApi";
import { useNavigate } from "react-router-dom";
import AgentAddPartnerPreferences from "./AgentAddPartnerPreferences";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import AgentHabits from "./AgentHabbits";

const AgentAddNewProfileMain = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorModal, setErrorModal] = useState();
  const [fetchedProfileId, setFetchedProfileId] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState();
  const [registerDetails, setRegisterDetails] = useState({
    listHeights: [],
    listStars: [],
    religionCaste: [],
    educationList: [],
    employedInList: [],
    occupationList: [],
    annualIncomeList: [],
    stateAndDistricts: [],
    hobbies: [],
  });
  const [registerData, setRegisterData] = useState({
    looking_for: "Bride",
    profile_for: "",
    profile_description: "",
    name: "",
    gender: "Male",
    date_birth: "",
    age: "",
    height: "",
    matrial_status: "",
    any_disability: "No",
    weight: "65",
    body_type: "normal",
    complexion: "light",
    star: "10",
    physical_status: "normal",
    education_main: "",
    education_sub: "",
    employed_in: "",
    occupation: "",
    annual_income: "",
    state: "",
    district: "",
    city: "",
    religion: "",
    caste: "",
    family_value: "",
    family_status: "",
    family_type: "",
    father_name: "",
    faher_occupation: "",
    mother_name: "",
    mother_occupation: "",
    no_brothers: "",
    no_sisters: "",
    family_location: "",
    eating_habit: "",
    smoking_habit: "",
    drinking_habit: "",
    hobbies: [],
  });
  useEffect(() => {
    fetchRegisterListDetails();
  }, []);

  const fetchRegisterListDetails = async () => {
    await AgentProfileApi.getRegisterDetails().then((data) => {
      if (data.status) {
        let responseData = data?.data;
        setRegisterDetails((prevValue) => ({
          ...prevValue,
          listHeights: responseData?.heights,
          listStars: responseData?.star,
          religionCaste: responseData?.religionCaste,
          educationList: responseData?.education,
          employedInList: responseData?.employed_in,
          occupationList: responseData?.occupation,
          annualIncomeList: responseData?.annual_income,
          stateAndDistricts: responseData?.stateDistrict,
          hobbies: responseData?.hobbies,
        }));
      } else {
        if (data.statusCode === 401) {
          localStorage.clear();
          navigate("/agent/login");
        }
      }
    });
  };

  const schema = yup.object().shape({
    looking_for: yup.string().required("This field is required"),
    profile_for: yup.string().optional(),
    profile_description: yup.string().optional(),
    name: yup.string().required("Please enter name"),
    gender: yup.string().optional(),
    date_birth: yup.string().required("Please enter date of birth"),
    age: yup.string(),
    height: yup.string().optional(),
    matrial_status: yup.string().optional(),
    any_disability: yup.string().optional(),
    weight: yup.string().optional(),
    body_type: yup.string().optional(),
    complexion: yup.string().optional(),
    star: yup.string().optional(),
    physical_status: yup.string().optional(),
    education_main: yup.string().optional(),
    education_sub: yup.string().optional(),
    employed_in: yup.string().optional(),
    occupation: yup.string().optional(),
    annual_income: yup.string().optional(),
    state: yup.string().optional(),
    district: yup.string().optional(),
    city: yup.string().optional(),
    religion: yup.string().required("Please select a religion"),
    caste: yup.string().required("Please select a caste"),
    family_value: yup.string().optional(),
    family_status: yup.string().optional(),
    family_type: yup.string().optional(),
    father_name: yup.string().optional(),
    faher_occupation: yup.string().optional(),
    mother_name: yup.string().optional(),
    mother_occupation: yup.string().optional(),
    no_brothers: yup.string().optional(),
    no_sisters: yup.string().optional(),
    family_location: yup.string().optional(),
    eating_habit: yup.string().optional(),
    smoking_habit: yup.string().optional(),
    drinking_habit: yup.string().optional(),
    hobbies: yup.array().optional(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegisterData = (e) => {
    let { name, value } = e.target;
    setRegisterData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (name === "name") {
      if (value) {
        clearErrors("name");
      } else {
        setError("name", {
          type: "manual",
          message: "Please enter name",
        });
      }
    }

    if (name === "caste") {
      if (value) {
        clearErrors("caste");
      } else {
        setError("caste", {
          type: "manual",
          message: "Please select a caste",
        });
      }
    }
  };

  const submitForm = async (formValue) => {
    console.log(formValue);
    let token = localStorage.getItem("authToken");
    console.log("toke", token);
    formValue.hobbies = registerData?.hobbies;
    await AgentProfileApi.createProfile(token, formValue).then(({ data }) => {
      console.log("Data", data?.data?.profile_id);
      if (data.status) {
        setFetchedProfileId(data?.data?.profile_id);
        setFormSubmitted(true);
        setShowSuccessModal(true);
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    });
    try {
    } catch (error) {
      console.error("Error while submitting forms:", error);
      setFormSubmitted(false);
    }
  };

  const handleerrorCloseModal = () => {
    setErrorModal(false);
  };

  return (
    <MDBCol size="6" sm="9" className="profile-edit-danial pt-4">
      <h1 className="agent-profile-editheading">Add Profile</h1>

      <div className="profile-editprofile">
        <div className="user-description">
          <p className="profile-description">Profile Description</p>
        </div>
        <form onSubmit={handleSubmit(submitForm)}>
          <textarea
            className="profile-description-textarea"
            rows={4}
            name="profile_description"
            value={registerData?.profile_description}
            {...register("profile_description")}
            onChange={handleRegisterData}
            style={{
              maxWidth: "100%",
              overflow: "auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          />
          <hr />
          <AgentBasicDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            listHeights={registerDetails?.listHeights}
            listStars={registerDetails?.listStars}
            register={register}
            setValue={setValue}
            errors={errors}
          />
          <hr />
          <AgentReligiousDetails
            religionCaste={registerDetails?.religionCaste}
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            register={register}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
          />
          <hr />
          <AgentProfessionalDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            handleRegisterData={handleRegisterData}
            educationList={registerDetails?.educationList}
            employedInList={registerDetails?.employedInList}
            occupationList={registerDetails?.occupationList}
            annualIncomeList={registerDetails?.annualIncomeList}
            register={register}
          />
          <hr />
          <AgentLocationDetails
            registerData={registerData}
            setRegisterData={setRegisterData}
            stateAndDistricts={registerDetails?.stateAndDistricts}
            register={register}
          />
          <hr />
          <AgentFamilyDetails
            registerData={registerData}
            handleRegisterData={handleRegisterData}
            stateAndDistricts={registerDetails?.stateAndDistricts}
            register={register}
          />
          <hr />
          <AgentHabits
            registerData={registerData}
            handleRegisterData={handleRegisterData}
            register={register}
          />
          <hr />
          <AgentHobbies
            registerData={registerData}
            setRegisterData={setRegisterData}
            hobbies={registerDetails?.hobbies}
          />
          <div className="text-center">
            <button
              className="btn mx-auto d-block agent-profile-submit-btn"
              type="submit"
              disabled={formSubmitted}
            >
              {formSubmitted ? "Saved" : "Save"}
            </button>
          </div>
          {/* The error popup */}

          <MDBModal show={errorModal} onHide={() => setErrorModal(false)}>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader className="submit-register text-center">
                  <MDBModalTitle className="register-sucess-title mx-auto">
                    Registration Unsuccessfully
                  </MDBModalTitle>
                  <MDBBtn
                    color="#fffff"
                    onClick={handleerrorCloseModal}
                    className="custom-close-button"
                  >
                    &times;
                  </MDBBtn>
                </MDBModalHeader>

                <MDBModalFooter>
                  <p className="reg-footer-unsuccessfull">
                    {" "}
                    Sorry, something went wrong while create new profile
                  </p>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal
            show={showSuccessModal}
            onHide={() => setShowSuccessModal(false)}
            centered
          >
            <MDBModalDialog>
              <MDBModalHeader
                className="submit-register text-center"
                style={{
                  borderBottom: "0px",
                  border: "10px",
                  marginTop: "10rem",
                }}
              >
                {" "}
                <MDBModalTitle className="register-sucess-title mx-auto">
                  <img
                    src="../Images/reg-success.png"
                    style={{
                      width: "25%",
                      marginBottom: "2rem",
                      marginTop: "1rem",
                    }}
                  ></img>
                  <br></br>
                  <p className="reg-success-message">
                    Registration Successfully
                  </p>
                  <p className="reg-footer">
                    Your Profile ID: {fetchedProfileId}{" "}
                  </p>
                  <MDBBtn
                    color="none"
                    onClick={() => setShowSuccessModal(false)}
                    className="custom-close-buttons"
                  >
                    OK
                  </MDBBtn>
                </MDBModalTitle>{" "}
              </MDBModalHeader>
            </MDBModalDialog>
          </MDBModal>
        </form>
      </div>
      <AgentAddPartnerPreferences
        listHeights={registerDetails?.listHeights}
        occupationList={registerDetails?.occupationList}
        stateAndDistricts={registerDetails?.stateAndDistricts}
        listStars={registerDetails?.listStars}
        annualIncomeList={registerDetails?.annualIncomeList}
        religionCaste={registerDetails?.religionCaste}
        formSubmitted={formSubmitted}
        fetchedProfileId={fetchedProfileId}
      />
    </MDBCol>
  );
};

export default AgentAddNewProfileMain;
