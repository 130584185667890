import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import "../style/messages.css";
import Navbars from "./Navbars";
import instance from "../axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Chat from "./Chat";
import Messagemenu from "./Messagemenu";
import Footerinner from "./Footerinner";

function Messageaccepted() {
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/login');
        return;
      }

      const tokenData = parseToken(token);

      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (tokenData.exp < currentTime) {
          // Token has expired, clear it and navigate to login
          clearToken();
          navigate("/login");
        }
      }
    };

    checkTokenExpiration();
  }, [navigate]);

  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }


  function clearToken() {
    localStorage.removeItem('authToken');

  }
  //backend//
  const [users, setUsers] = useState([]);



  const [userData, setUserData] = useState(null);

  const [userPhoto, setuserPhoto] = useState(null);
  //photo view
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error("Authentication token missing");
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewphoto', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        setuserPhoto(response.data.data);
        console.log('response data data : ', response.data.data);

      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // view logged user
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewedUserProfile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data logged', response);
        setUserData(response.data.userData);
        console.log('response data data : ', response.data.data);
        console.log('response data  : ', response.data);
        console.log("logged own user details", userData);
        console.log("own user details", userData.c_name);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  // percentage calculation
  const [percentage, setPercentage] = useState(null)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/getPercentage', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data percentage', response);
        setPercentage(response.data);
        console.log('response percentage : ', percentage);

      } catch (error) {
        console.error('Error fetching user details:', error);

      }
    };

    fetchUserDetails();
  }, []);

  // view logged user
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error("Authentication token missing");
          return;
        }

        const response = await instance.get(
          "/api/pulayakalyanam/viewedUserProfile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("User Data logged", response);
        setUserData(response.data.userData);
        console.log("response data data : ", response.data.data);
        console.log("response data  : ", response.data);
        console.log("logged own user details", userData);
        console.log("own user details", userData.c_name);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  
  // view all messages
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchUserMsg = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewAcceptedMessages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        setMessages(response.data.data);

        console.log('messgaes set : ', response.data);

      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserMsg();
  }, []);

  //ignore
  const handleIgnore = async (userCode) => {
    // Implement the logic to handle the ignore action
    console.log(`Ignoring message with ID ${userCode}`);

  //ignore
  const handleIgnore = async (userCode) => {
    // Implement the logic to handle the ignore action
    console.log(`Ignoring message with ID ${userCode}`);

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      const response = await instance.put(`/api/pulayakalyanam/ignore/${userCode}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });




    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  };

  /*   /chat popup// */


  const [activeChatProfile, setActiveChatProfile] = useState(null);

  const handleImageClick = (profileId, msgid) => {
    console.log("msgid in handleIamgae", msgid);
    setActiveChatProfile({ profileId, msgid });
  };

  const handleCloseChat = () => {
    setActiveChatProfile(null);
  };

  return (
    <div className="back">
      <Navbars></Navbars>
      <Container>
        <MDBRow className=" ml-3 myhome-matches-all">
          <MDBCol
            size="6"
            sm="3"
            className="employee-listitems columns-container  "
          >
            <div className="emppic">
              <div className="switches-container">
                <input
                  type="radio"
                  id="switchMonthly"
                  name="switchPlan"
                  value="Monthly"
                  checked="checked"
                />
                <input
                  type="radio"
                  id="switchYearly"
                  name="switchPlan"
                  value="Yearly"
                />
                <label for="switchMonthly">Regular</label>
                <label for="switchYearly">Premium</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div>Regular</div>
                    <div>Premium</div>
                  </div>
                </div>
              </div>
              <div className="empfull-layer">
                <div className="layer container-fluid">
                  <img src="../Images/Layer_1.png" className="layerfirst"></img>
                  <span className="active-online-profile">
                    <span className="dot "></span> online
                  </span>
                </div>
                {userPhoto ? (
                  <img
                    src={userPhoto.c_profile_image}
                    className="img-fluid searchemployee"
                    alt="User"
                  />
                ) : (
                  <img
                    src="default-photo-url.jpg"
                    className="img-fluid searchemployee"
                    alt="Default"
                  />
                )}
                {userData && (
                  <>
                    <p className="empname">{userData.c_name}</p>
                    <p className="empid">
                      {userData.n_age} Yrs | {userData.c_usercode}
                    </p>
                    <hr />
                    {percentage && (
                      <>
                        <p className="percentage">
                          <span className="dark">Profile Completeness</span>{" "}
                          <span className="yellow">{percentage.data}%</span>
                          <a href="Profileedit">
                            <span className="green"> Complete Profile</span>
                          </a>{" "}
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="listitems-myhome">
              <hr></hr>
              <li className="empedit">
                <a href="add-photo">Add/Edit Photos</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Profileedit">View/Edit Profile</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Interestedprofiles">Interested Profile</a>
              </li>
              <hr></hr>

              <li className="empedit">
                <a href="Rejectedprofiles">Rejected Profile</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Acceptedprofile">Accepted Profile</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Shortlistpro">Who short listed Me</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Myshortlist">My Shortlists</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Whovisited">Who visited my profile</a>
              </li>
              <hr></hr>
              <li className="empedit">
                <a href="Hide">Hide profile</a>
              </li>
            </div>
          </MDBCol>

          <MDBCol size="6" sm="9" className="messages-mobile">
            <Messagemenu />

            <>
              {messages.map((msg, index) => (
                <MDBRow className="client-format" key={index}>
                  {/* Assuming you have an image URL available for the user */}
                  <MDBCol size="6" sm="3" className="client-images">
                    {/*  <img src='../Images/client1.jpg' className='img-fluid' alt='...' /> */}
                    <img src={msg.c_profile_image || 'default-photo-url.png'}></img>
                  </MDBCol>
                  <MDBCol size="6" sm="9" className="client-alldetails">
                    <p>{`${msg.sender_id} sent you a message!`}</p>
                    <p className="client-name">{msg.sender_id}</p>
                    <p className="send-details">
                      {/* Add other details if needed */}
                    </p>
                    {msg.grouped_messages
                      .split("|")
                      .map((individualMessage, i) => (
                        <p key={i} className="client-message">
                          {individualMessage}
                        </p>
                      ))}
                    <hr />
                    <p className="client-intraction">
                      Do you want to reply to the message?{" "}
                      {/*  <button className='client-button' onClick={() => handleIgnore(msg.sender_id)}>
            Ignore
          </button>{' '} */}
                      <button
                        className="client-replay"
                        onClick={() => handleImageClick(msg.sender_id, msg.id)}
                      >
                        Reply
                      </button>
                      {activeChatProfile &&
                        activeChatProfile.profileId === msg.sender_id && (
                          <Chat
                            otherUserId={activeChatProfile.profileId}
                            msgId={activeChatProfile.msgid}
                            onClose={handleCloseChat}
                          />
                        )}
                    </p>
                  </MDBCol>
                </MDBRow>
              ))}
            </>

            <br></br>

            {/*mobile view*/}

            <div className="client-top-mobiles">
              <div className="message-request-mobile">
                <Button
                  type="button"
                  className="matches-buttons-privacy"
                  as={Link}
                  to="/Messages"
                >
                  {" "}
                  Messgaes Request
                  <span
                    className="count_matches"
                    style={{ backgroundColor: "#F0F0F0" }}
                  ></span>{" "}
                </Button>
                <Button
                  type="button"
                  className="matches-buttons-privacy"
                  as={Link}
                  to="/Messageaccepted"
                  style={{ backgroundColor: "#F0F0F0" }}
                >
                  Chat History <span className="count_matches"></span>
                </Button>
              </div>
              <div className="client_top">
                <>
                  {messages.map((msg, index) => (
                    <div className="" key={index}>
                      <div
                        className="client"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {/*  <img src='../Images/client1.jpg' className='img-fluid' alt='...' style={{ maxWidth: '100%', height: 'auto' }} /> */}
                        <img src={msg.c_profile_image || 'default-photo-url.png'}></img>
                        <div className='client-alldetails'>
                          <p>{`${msg.sender_id} sent you a message!`}</p>
                          <p className="client-name ">{msg.sender_id}</p>
                          <p className="send-details">
                            {/* Add other details if needed */}
                          </p>
                          {msg.grouped_messages
                            .split("|")
                            .map((individualMessage, i) => (
                              <p key={i} className="client-message">
                                {individualMessage}
                              </p>
                            ))}
                          <hr />
                          <p className="client-intraction">
                            Do you want to reply to the message?{" "}
                            {/*  <button className='client-button' onClick={() => handleIgnore(msg.sender_id)}>
            Ignore
          </button>{' '} */}
                            <button
                              className="client-replay"
                              onClick={() =>
                                handleImageClick(msg.sender_id, msg.id)
                              }
                            >
                              Reply
                            </button>
                            {activeChatProfile &&
                              activeChatProfile.profileId === msg.sender_id && (
                                <Chat
                                  otherUserId={activeChatProfile.profileId}
                                  msgId={activeChatProfile.msgid}
                                  onClose={handleCloseChat}
                                />
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
            </div>

            {/*  <div className='client_top'>
      <div className='client'>
      <img src='../Images/client1.jpg' className='img-fluid' alt='...' />
      <div className='client-alldetails'>
        <p>Anjana sent you a message!</p>
        <p className='client-name-mobile'>Anjana</p>
        <p className='send-details-mobile'>
          E5961147 | Profile Created by Self<br/>
         
        </p>
        <p className='client-message-mobile'>
          Hi, I like your profile. If you are interested, please call this number +1001000100
        </p>
      
        
      </div>
    
    </div><hr></hr>
    <p className='client-intraction-mobile'>
          Do you want to replay her message?
          <button className='client-button-message' href=''>Ignore</button>
          <button className='client-replay-message' href=''>Replay</button>
        </p>
        </div>  */}
          </MDBCol>
        </MDBRow>
      </Container>

      <Footerinner></Footerinner>
    </div>
  );
}

export default Messageaccepted;
