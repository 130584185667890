import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FaRegEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import UserCardBottom from "./UserCardBottom";

const AgentProfileCard = ({ user, hideProfile, chatData, setChatData }) => {
  return (
    <>
      {/* showing in lg */}
      <MDBRow className="matches-container d-none d-lg-flex">
        <MDBCol size="3" className="position-relative">
          <div className={`${user?.profile_image && "matches-border-img"}`}>
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="../default-photo-url.png"
                  alt="Default Image"
                  className="matches-profileimage"
                />
              )}
            </Link>
          </div>
        </MDBCol>
        <MDBCol size="1"></MDBCol>
        <MDBCol size="8">
          <div className="danil-sebastine">
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              <p className="danil">{user?.name}</p>
              <p className="danil-details">{user?.profileId}</p>
              <p className="danil-details">
                {" "}
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              <p className="danil-details">
                {" "}
                {user?.religion}/{user?.caste}
              </p>
              <p className="danil-details">
                {user?.qualification || "education not defined"}
                {user?.occupation
                  ? `, ${user?.occupation}`
                  : ", occupation not defined"}
                {user?.district && `, ${user?.district}`}
              </p>
            </Link>
            <hr />
            <div className="match-bottom-container d-flex justify-content-end">
              <div className="match-bottom-link cursor-pointer hide-on-mobile">
                <p>Hide?</p>
                <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      {/* for responsive */}

      <div className="client_top">
        <div className="client">
          <div className="img_new">
            <div style={{ position: "relative" }}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className=" matches-mobileprofileimage"
                />
              ) : (
                <img src="../default-photo-url.png" alt="Default Image" />
              )}
            </div>
          </div>
          <div className="danil-sebastine">
            <Link to={`/agent/all-profiles/${user?.profileId}`}>
              <p className="danil">{user?.name}</p>
              <p className="danil-details-mobile">{user?.profileId}</p>
              <p className="danil-details-mobile">
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              <p className="danil-details-mobile">
                {user?.qualification || "education not defined"}
                {user?.occupation
                  ? `, ${user?.occupation}`
                  : ", occupation not defined"}
                {user?.district && `, ${user?.district}`}
              </p>
            </Link>
          </div>
        </div>
        <hr></hr>
        <div className="match-bottom-container d-flex justify-content-end">
          <div className="match-bottom-link cursor-pointer">
            <p>Hide?</p>
            <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentProfileCard;
