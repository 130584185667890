import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import MatchSlider from "./MatchSlider";
import Skeleton from "react-loading-skeleton";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import MatchSliderComponent from "./MatchSliderComponent";
import NewmatchSlider from "./NewmatchSlider";

const DialyMatches = ({ dialyMatches }) => {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());
  const [screenWidth, setScreenWidth] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Function to update screen width
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to update screen width on resize
    window.addEventListener("resize", updateScreenWidth);

    // Initial call to set the initial screen width
    updateScreenWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  function getRemainingTime() {
    const now = new Date();
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0,
      0
    );
    const timeDifference = endOfDay - now;
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  }

  //slide
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="your-matches">
      <p className="who-matches">
        <span className="new-matches-only">Daily Matches</span>- Members who
        matches your preference
      </p>
      <div className="time-counter-container">
        <span className="time-counter">
          <span className="profile-view-end">Profile view ends</span>
          <span className="hours">{remainingTime?.hours}h</span>
          <span className="minits">{remainingTime?.minutes}m</span>
          <span className="minits">{remainingTime?.seconds}s</span>
        </span>
      </div>
      <MDBRow className="daily-yourpreference">
        {dialyMatches.length > 4 ? (
          <MatchSliderComponent newMatches={dialyMatches} />
        ) : screenWidth > 768 ? (
          dialyMatches.length > 3 && screenWidth < 1024 ? (
            <MatchSliderComponent newMatches={dialyMatches} />
          ) : (
            <div className="d-flex gap-3">
              {dialyMatches.map((match) => (
                <NewmatchSlider
                  key={match?.profile_id}
                  id={match?.profile_id}
                  name={match?.name}
                  age={match?.age}
                  image={match?.profile_image}
                />
              ))}
            </div>
          )
        ) : (
          <MatchSliderComponent newMatches={dialyMatches} />
        )}
      </MDBRow>

      {/* <Slider {...sliderSettings}>
        {dialyMatches.length <= 0
          ? ["*", "*", "*", "*"].map((_, index) => (
              <MDBCol className="text-center p-2" key={index}>
                <div className="">
                  <Skeleton height={160} />
                </div>

                <p className="sebastian-full">
                  <Skeleton />
                </p>
                <p className="sebastian-years">
                  <Skeleton />
                </p>
              </MDBCol>
            ))
          : dialyMatches.map((match) => (
              <MatchSlider
                key={match?.profile_id}
                id={match?.profile_id}
                name={match?.name}
                age={match?.age}
                image={match?.profile_image}
              />
            ))}
      </Slider> */}
    </div>
  );
};

export default DialyMatches;
