import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import {
  FaRegEye,
  FaRegEyeSlash,
  FaRegThumbsUp,
  FaThumbsUp,
} from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import AgentMatchProgress from "./AgentMatchProgress";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { toast } from "react-toastify";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { TbClipboardList } from "react-icons/tb";
// import UserCardBottom from "./UserCardBottom";

const AgentMatchProfileCard = ({
  user,
  hideProfile,
  chatData,
  setChatData,
  hideAgentProfile,
  from,
  removeUnlikedProfile,
  handleInterestResponse,
  handleBlockResponse,
}) => {
  const token = localStorage.getItem("authToken");
  const { profileId } = useParams();
  const [isLiked, setIsLiked] = useState(false);
  const [isShortlisted, setIsShortListed] = useState(false);
  useEffect(() => {
    setIsShortListed(user?.isShortlisted);
    setIsLiked(user?.isLiked);
  }, [user]);
  const likeProfile = async () => {
    let payload = {
      otherProfileId: user?.profile_id,
    };
    await api
      .interestAgentProfile(profileId, token, payload)
      .then(({ data }) => {
        if (data?.status) {
          setIsLiked(!isLiked);
          if (from === "liked" && isLiked) {
            removeUnlikedProfile(user?.profile_id);
          }
        } else {
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };
  const handleShortList = () => {
    let payload = {
      otherProfileId: user?.profile_id,
    };
    api.shortlistAgentProfile(profileId, token, payload).then(({ data }) => {
      if (data?.status) {
        setIsShortListed(!isShortlisted);
      }
    });
  };
  return (
    <>
      {/* showing in lg */}
      <MDBRow className="matches-container d-none d-lg-flex">
        <MDBCol size="3" className="position-relative">
          <div className={`${user?.profile_image && "matches-border-img"}`}>
            <Link to={`/agent/all-profiles/${user?.profile_id}`}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="/default-photo-url.png"
                  alt="Default Image"
                  className="matches-profileimage"
                />
              )}
            </Link>
          </div>
        </MDBCol>
        {/* <MDBCol size="1"></MDBCol> */}
        <MDBCol size="9">
          {from === "liked" && (
            <div className="intrest_like">
              <img src="/Images/intrestlike.svg" alt="" />
              <h4>
                <span style={{ color: "#0AB93B" }}>Interest Sent</span> |
                {user?.date}
              </h4>
            </div>
          )}
          {from === "likeRecieved" && (
            <div className="intrest_like">
              <BsBoxArrowInDownLeft style={{ color: "#0AB93B" }} />
              <h4>
                <span style={{ color: "#0AB93B" }}>Interest Received</span> |
                {user?.date}
              </h4>
            </div>
          )}
          <div className="danil-sebastine">
            <MDBRow>
              <MDBCol size={9}>
                <Link to={`/agent/all-profiles/${user?.profile_id}`}>
                  <p className="danil">{user?.name}</p>
                  <p className="danil-details">{user?.profile_id}</p>
                  <p className="danil-details">
                    {" "}
                    {user?.age} Yrs,{" "}
                    {user?.height_feet || user?.height_cm
                      ? `${user?.height_feet || "height"} / ${
                          user?.height_cm || "height"
                        } cm`
                      : "height not defined"}
                  </p>
                  <p className="danil-details">
                    {" "}
                    {user?.religion}/{user?.caste}
                  </p>
                  <p className="danil-details">
                    {user?.qualification || "education not defined"}
                    {user?.occupation
                      ? `, ${user?.occupation}`
                      : ", occupation not defined"}
                    {user?.district && `, ${user?.district}`}
                  </p>
                </Link>
              </MDBCol>
              {from === "matches" && (
                <MDBCol
                  size={3}
                  className="d-flex justify-content-center align-items-center"
                >
                  <AgentMatchProgress
                    value={user?.percentage}
                    size={90}
                    fontSizes={["10px", "12px"]}
                  />
                </MDBCol>
              )}
            </MDBRow>
            <hr />
            {(from === "matches" || from === "hide") && (
              <div className="match-bottom-container d-flex gap-3 justify-content-end">
                {from === "matches" && (
                  <div
                    className="match-bottom-link cursor-pointer"
                    onClick={handleShortList}
                  >
                    {isShortlisted ? (
                      <>
                        <p>Shortlisted</p>
                        <HiClipboardDocumentCheck className="match-bottom-icon" />
                      </>
                    ) : (
                      <>
                        <p>Shortlist?</p>
                        <TbClipboardList className="match-bottom-icon" />
                      </>
                    )}
                  </div>
                )}
                <div
                  className="match-bottom-link cursor-pointer hide-on-mobile"
                  onClick={() => hideAgentProfile(user?.profile_id)}
                >
                  {from === "hide" ? (
                    <>
                      <p>Unhide?</p>
                      <FaRegEye className="mirror-icon match-bottom-icon" />
                    </>
                  ) : (
                    <>
                      <p>Hide?</p>
                      <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
                    </>
                  )}
                </div>
                {from === "matches" && (
                  <div
                    className="match-bottom-link cursor-pointer hide-on-mobile"
                    onClick={likeProfile}
                  >
                    {isLiked ? (
                      <>
                        <p>Liked</p>
                        <FaThumbsUp className="match-bottom-icon" />
                      </>
                    ) : (
                      <>
                        <p>Like Profile?</p>
                        <FaRegThumbsUp className="match-bottom-icon" />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {(from === "liked" || from === "accepted") && (
              <div className="bottom_details">
                <div>
                  <p>Would you like to communicate further?</p>
                </div>
                <div>
                  <button>Message</button>
                  <a href={`tel:${user?.mobile}`}>
                    <button style={{ background: "#F39200" }}>Call</button>
                  </a>
                </div>
              </div>
            )}
            {from === "block" && (
              <div className="bottom_details">
                <div>
                  <p>
                    This member will be able to see and contact you by clicking
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => handleBlockResponse(user.profile_id)}
                    style={{ background: "#F39200" }}
                  >
                    Unblock
                  </button>
                </div>
              </div>
            )}
            {from === "likeRecieved" && (
              <div className="bottom_details">
                <div>
                  <p>Would you like to communicate further?</p>
                </div>
                <div>
                  <button
                    onClick={() =>
                      handleInterestResponse(user.profile_id, "accept")
                    }
                  >
                    Accepted
                  </button>
                  <button
                    onClick={() =>
                      handleInterestResponse(user.profile_id, "reject")
                    }
                    style={{ background: "#F39200" }}
                  >
                    Declined
                  </button>
                </div>
              </div>
            )}
          </div>
        </MDBCol>
      </MDBRow>
      {/* for responsive */}

      <div className="match-client" style={{ margin: "0px !important" }}>
        <div className="client">
          <div className="img_new">
            <div style={{ position: "relative" }}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className="matches-mobileprofileimage"
                />
              ) : (
                <img
                  src="/default-photo-url.png"
                  className="matches-mobileprofileimage"
                  alt="Default Image"
                />
              )}
            </div>
          </div>
          <div className="danil-sebastine w-100">
            {from === "liked" && (
              <div className="intrest_like">
                <img src="/Images/intrestlike.svg" alt="" />
                <h4>
                  <span style={{ color: "#0AB93B" }}>Interest Sent</span> |
                  {user?.date}
                </h4>
              </div>
            )}
            {from === "likeRecieved" && (
              <div className="intrest_like">
                <BsBoxArrowInDownLeft style={{ color: "#0AB93B" }} />
                <h4>
                  <span style={{ color: "#0AB93B" }}>Interest Received</span> |
                  {user?.date}
                </h4>
              </div>
            )}
            <MDBRow>
              <MDBCol size={8}>
                <Link to={`/agent/all-profiles/${user?.profile_id}`}>
                  <p className="danil">{user?.name}</p>
                  <p className="danil-details-mobile">{user?.profile_id}</p>
                  <p className="danil-details-mobile">
                    {user?.age} Yrs,{" "}
                    {user?.height_feet || user?.height_cm
                      ? `${user?.height_feet || "height"} / ${
                          user?.height_cm || "height"
                        } cm`
                      : "height not defined"}
                  </p>
                  <p className="danil-details-mobile">
                    {user?.qualification || "education not defined"}
                    {user?.occupation
                      ? `, ${user?.occupation}`
                      : ", occupation not defined"}
                    {user?.district && `, ${user?.district}`}
                  </p>
                </Link>
              </MDBCol>{" "}
              {from === "matches" && (
                <MDBCol
                  size={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <AgentMatchProgress
                    value={user?.percentage}
                    size={55}
                    fontSizes={["8px", "9px"]}
                  />
                </MDBCol>
              )}
            </MDBRow>
          </div>
        </div>
        <hr></hr>
        {(from === "matches" || from === "hide") && (
          <div className="match-bottom-container d-flex gap-2 justify-content-end">
            {from === "matches" && (
              <div
                className="match-bottom-link cursor-pointer"
                onClick={handleShortList}
              >
                {isShortlisted ? (
                  <>
                    <p>Shortlisted</p>
                    <HiClipboardDocumentCheck className="match-bottom-icon" />
                  </>
                ) : (
                  <>
                    <p>Shortlist?</p>
                    <TbClipboardList className="match-bottom-icon" />
                  </>
                )}
              </div>
            )}
            <div
              className="match-bottom-link cursor-pointer"
              onClick={() => hideAgentProfile(user?.profile_id)}
            >
              {from === "hide" ? (
                <>
                  <p>Unhide?</p>
                  <FaRegEye className="mirror-icon match-bottom-icon" />
                </>
              ) : (
                <>
                  <p>Hide?</p>
                  <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
                </>
              )}
            </div>
            {from === "matches" && (
              <div
                className="match-bottom-link cursor-pointer"
                onClick={likeProfile}
              >
                {isLiked ? (
                  <>
                    <p>Liked</p>
                    <FaThumbsUp className="match-bottom-icon" />
                  </>
                ) : (
                  <>
                    <p>Like?</p>
                    <FaRegThumbsUp className="match-bottom-icon" />
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {(from === "liked" || from === "accepted") && (
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: "10px",
              paddingTop: "10px",
            }}
            className="bottom_details"
          >
            <div>
              <p>Would you like to communicate further?</p>
            </div>
            <div>
              <button>Message</button>
              <a href={`tel:${user?.mobile}`}>
                {" "}
                <button style={{ background: "#F39200" }}>Call</button>
              </a>
            </div>
          </div>
        )}
        {from === "block" && (
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: "10px",
              paddingTop: "10px",
            }}
            className="bottom_details"
          >
            <div>
              <p>This member will be able to see and contact you by clicking</p>
            </div>
            <div>
              <button
                onClick={() => handleBlockResponse(user.profile_id)}
                style={{ background: "#F39200" }}
              >
                Unblock
              </button>
            </div>
          </div>
        )}
        {from === "likeRecieved" && (
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: "10px",
              paddingTop: "10px",
            }}
            className="bottom_details"
          >
            <div>
              <p>
                {" "}
                {user?.gender === "Male" ? "He" : "She"} is interested in your
                profile. Would you like to communicate further?
              </p>
            </div>
            <div>
              <button
                onClick={() =>
                  handleInterestResponse(user.profile_id, "accept")
                }
              >
                Accepted
              </button>
              <button
                onClick={() =>
                  handleInterestResponse(user.profile_id, "reject")
                }
                style={{ background: "#F39200" }}
              >
                Declined
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AgentMatchProfileCard;
