// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singlefulldetails {
    color: orange !important;
    padding-bottom: 5% !important;
    font-family: 'Lato', sans-serif;
  }
.singlefulldetails a:active{
    color:orange ;
    font-family: 'Lato', sans-serif;
}
.singleprofile{
    width: 70% !important;
    margin-left: 20% !important;
    background-color: white !important;
}
.singleprofile-picture img{
  width: 80% !important;
  margin-top: 3% !important;
}

.mobile-singleprofile{
    background-color: white !important;
    margin-top: 5% !important;
}

@media screen and (max-width:992px) {
      .desktop-mobile{
        display: block !important;
       
      }
    .singleprofile-show{
      display: none !important;
    }
    .singlefulldetails {
        padding-bottom: 0% !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/singleprofile.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,6BAA6B;IAC7B,+BAA+B;EACjC;AACF;IACI,aAAa;IACb,+BAA+B;AACnC;AACA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,kCAAkC;AACtC;AACA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;IACI,kCAAkC;IAClC,yBAAyB;AAC7B;;AAEA;MACM;QACE,yBAAyB;;MAE3B;IACF;MACE,wBAAwB;IAC1B;IACA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".singlefulldetails {\n    color: orange !important;\n    padding-bottom: 5% !important;\n    font-family: 'Lato', sans-serif;\n  }\n.singlefulldetails a:active{\n    color:orange ;\n    font-family: 'Lato', sans-serif;\n}\n.singleprofile{\n    width: 70% !important;\n    margin-left: 20% !important;\n    background-color: white !important;\n}\n.singleprofile-picture img{\n  width: 80% !important;\n  margin-top: 3% !important;\n}\n\n.mobile-singleprofile{\n    background-color: white !important;\n    margin-top: 5% !important;\n}\n\n@media screen and (max-width:992px) {\n      .desktop-mobile{\n        display: block !important;\n       \n      }\n    .singleprofile-show{\n      display: none !important;\n    }\n    .singlefulldetails {\n        padding-bottom: 0% !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
