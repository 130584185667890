import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const LocationDetails = ({ userData, stateAndDistricts }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [locationDetails, setLoacationDetails] = useState({
    state_id: "",
    state: "",
    district_id: "",
    district: "",
    city_id: "",
    city: "",
  });
  const [districtsList, setDistrictsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [isEditingLocation, setIsEditingLocation] = useState(false);

  useEffect(() => {
    setStatesFunction();
  }, [userData]);

  const setStatesFunction = async () => {
    let findState = await stateAndDistricts.find(
      (state) => state.states == userData?.state
    );
    let findDistrict = {};
    let findCity = {};
    // if (userData?.state === "Not Defined" && stateAndDistricts.length === 1) {
    // findState = stateAndDistricts[0];
    // setDistrictsList([...stateAndDistricts[0].districts]);
    // } else {
    if (findState) {
      setDistrictsList([...findState.districts]);
      findDistrict = await findState.districts.find(
        (district) => district.district == userData?.district
      );
      if (findDistrict) {
        setCitiesList([...findDistrict?.cities]);
        findCity = findDistrict.cities.find(
          (city) => city?.city == userData?.city
        );
      }
    }
    // }
    setLoacationDetails({
      state_id: findState?.id,
      state: userData?.state,
      district_id: findDistrict?.dist_id,
      district: userData?.district,
      city_id: findCity?.city_id,
      city: userData?.city,
    });
  };

  const handleEditClickLocation = () => {
    setIsEditingLocation(true);
  };
  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    if (selectedState) {
      const findState = stateAndDistricts.find(
        (state) => state.id == selectedState
      );
      setLoacationDetails({
        ...locationDetails,
        state_id: selectedState,
        state: findState?.states,
      });
      if (findState) {
        setDistrictsList([...findState?.districts]);
      } else {
        setDistrictsList([]);
      }
    } else {
      setLoacationDetails({
        state_id: "",
        state: "",
        district_id: "",
        district: "",
        city_id: "",
        city: "",
      });
    }
    setCitiesList([]);
  };

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    if (selectedDistrict) {
      const findDistrict = districtsList.find(
        (district) => district.dist_id == selectedDistrict
      );
      setLoacationDetails({
        ...locationDetails,
        district_id: selectedDistrict,
        district: findDistrict.district,
        city_id: "",
        city: "",
      });

      if (findDistrict) {
        setCitiesList([...findDistrict?.cities]);
      } else {
        setCitiesList([]);
      }
    } else {
      setLoacationDetails({
        ...locationDetails,
        district_id: "",
        district: "",
        city_id: "",
        city: "",
      });
      setCitiesList([]);
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      const findCity = citiesList.find((city) => city.city_id == selectedCity);
      setLoacationDetails({
        ...locationDetails,
        city_id: selectedCity,
        city: findCity?.city,
      });
    } else {
      setLoacationDetails({
        ...locationDetails,
        city_id: "",
        city: "",
      });
    }
  };

  const handleSaveClickLocation = async () => {
    //remove this if when adding validation
      const payload = {
        state: locationDetails?.state_id,
        district: locationDetails?.district_id,
        city: locationDetails?.city_id,
      };
      await api.updateLocationDetails(payload, token).then((data) => {
        if (data.status) {
          setIsEditingLocation(false);
        } else if (data.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };
  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Location Details</p>
        <div className="editing-religious">
          {isEditingLocation ? (
            <button onClick={handleSaveClickLocation}>Save</button>
          ) : (
            <button onClick={handleEditClickLocation}>
              <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Select state
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="state"
                name="state"
                className="SelectBoxs-searchpage-profileedit"
                value={locationDetails?.state_id}
                onChange={handleStateChange}
              >
                <option value="">Select</option>
                {stateAndDistricts.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.states}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Select state
            </div>
            <div style={{ flex: 1 }}>
              :{""} {locationDetails?.state}
            </div>
          </div>
        )}

        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              District
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="district"
                name="district"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={locationDetails?.district_id}
                onChange={handleDistrictChange}
              >
                <option value="">Select</option>
                {districtsList.map((district) => (
                  <option key={district.dist_id} value={district.dist_id}>
                    {district.district} {/* Display the district name */}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              District
            </div>
            <div style={{ flex: 1 }}>
              :{""} {locationDetails?.district}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingLocation ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              City
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="city"
                name="city"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={locationDetails?.city_id}
                onChange={handleCityChange}
              >
                <option value="">Select</option>
                {citiesList.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              City
            </div>
            <div style={{ flex: 1 }}>
              :{""} {locationDetails?.city}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default LocationDetails;
