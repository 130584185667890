import React, { useEffect, useState } from "react";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import api from "../../../../Api/InterationApi";
import { ToastContainer, toast } from "react-toastify";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { firebaseApp } from "../../../../firebase/firebase";

const UserCard = ({ user, interestRecived, setinterestRecived }) => {
  const token = localStorage.getItem("authToken");
  const firebaseDB = getFirestore(firebaseApp);
  const [isUserOnline, setIsUserOnline] = useState(false);

  useEffect(() => {
    const onGetOnlineStatus = onSnapshot(
      doc(firebaseDB, "users", user?.userId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().status === "ONLINE") {
            setIsUserOnline(true);
          } else {
            setIsUserOnline(false);
          }
        } else {
          setIsUserOnline(false);
        }
      }
    );

    return () => {
      onGetOnlineStatus();
    };
  }, [user]);

  const handleInterestResponse = async (userId, status) => {
    console.log(userId, status);

    await api
      .InterestReply(userId, { status }, token)
      .then((response) => {
        if (response.data.status === true) {
          const updatedInterestRecived = interestRecived.filter(
            (user) => user.profile_code !== userId
          );
          setinterestRecived(updatedInterestRecived);
        } else {
          toast.error("Sorry, something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })

      .catch((error) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="intrest_container">
        <div className="left_img">
          <div className="img_new">
            <div  style={{ position: "relative" }}>
              <img
                src={user?.image ? `${user?.image}` : "/default-photo-url.png"}
                alt=""
                className="matches-profileimage"
              />
               <a href={`tel:${user?.mobile}`}>
              <img
                style={{
                  width: "auto",
                  position: "absolute",
                  right: "-1px",
                  bottom: "-10px",
                }}
                src="Images/call.png"
                alt=""
              />
              </a>
              <img
                style={{
                  width: "auto",
                  position: "absolute",
                  right: "12px",
                  top: "20px",
                }}
                src="Images/premium.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="right_details">
          <div className="intrest_like">
            <BsBoxArrowInDownLeft style={{ color: "#0AB93B" }} />
            <h4>
              <span style={{ color: "#0AB93B" }}>Interest Received</span> |
              {user?.date}
            </h4>
          </div>

          <div className="name1">
          <a href={`profile-details/${user?.userId}`}>
            <h2>{user?.name}</h2></a>
            <img src="Images/online.png" alt="" />
            {isUserOnline ? (
              <p className="online" style={{ margin: "0" }}>
                online
              </p>
            ) : (
              <p className="offline" style={{ margin: "0" }}>
                offline
              </p>
            )}
          </div>

          <div className="details_caption">
            <p>
              {user?.userId} | Profile Created by {user?.createdBy}
            </p>
            <p>
            {" "}
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${user?.height_cm || "height"
                  } cm`
                  : "height not defined"}
            </p>
            <p>{user?.religion}</p>
            <p>
              {" "}
              {user?.qualification} {user?.occupation}, {user?.district}, Kerala
            </p>
            <hr />

            <div className="bottom_details">
              <div>
                <p>
                  {" "}
                  {user?.gender === "Male" ? "He" : "She"} is interested in your
                  profile. Would you like to communicate further?
                </p>
              </div>
              <div>
                <button
                  onClick={() => handleInterestResponse(user.userId, "accept")}
                >
                Accepted
                </button>
                <button
                  onClick={() => handleInterestResponse(user.userId, "reject")}
                  style={{ background: "#F39200" }}
                >
                 Declined
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="intrest_container mobile_intrest">
        <div className="mobleft">
          <div className="left_img">
            <div className="img_new">
              <div style={{ position: "relative" }}>
                <img
                  src={
                    user?.image ? `${user?.image}` : "/default-photo-url.png"
                  }
                  alt=""
                  className="matches-mobileprofileimage"
                />
                 <a href={`tel:${user?.mobile}`}>
                <img
                  style={{
                    width: "auto",
                    position: "absolute",
                    right: "-1px",
                    bottom: "0px",
                    width:"15px",
                  }}
                  src="Images/call.png"
                  alt=""
                />
              </a>
              <img
                style={{
                  width: "auto",
                  position: "absolute",
                  right: "0px",
                  top: "5px",
                  width:"12px",
                }}
                src="Images/premium.png"
                alt=""
              />
              </div>
            </div>
          </div>
          <div className="right_details">
            <div className="intrest_like">
              <BsBoxArrowInDownLeft style={{ color: "#0AB93B" }} />
              <h4>
                <span style={{ color: "#0AB93B" }}>Interest Received</span> |
                {user?.date}
              </h4>
            </div>

            <div className="name1">
            <a href={`profile-details/${user?.userId}`}>
              <h2>{user?.name}</h2></a>
              <img src="Images/online.png" alt="" />
              {isUserOnline ? (
                <p className="online" style={{ margin: "0" }}>
                  online
                </p>
              ) : (
                <p className="offline" style={{ margin: "0" }}>
                  offline
                </p>
              )}
            </div>

            <div className="details_caption">
              <p>
                {user?.userId} {/* | Profile Created by {user?.createdBy} */}
              </p>
              <p>
              {" "}
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${user?.height_cm || "height"
                  } cm`
                  : "height not defined"}
              </p>
             {/*  <p>{user?.religion}</p> */}
              <p>
                 {user?.qualification} {user?.occupation}, {user?.district}{/* , Kerala */}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid #ccc",
            marginTop: "10px",
            paddingTop: "10px",
          }}
          className="bottom_details"
        >
          <div>
            <p>
            {" "}
              {user?.gender === "Male" ? "He" : "She"} is interested in your
              profile. Would you like to communicate further?
            </p>
          </div>
          <div>
            <button
              onClick={() => handleInterestResponse(user.userId, "accept")}
            >
             Accepted 
            </button>
            <button
              onClick={() => handleInterestResponse(user.userId, "reject")}
              style={{ background: "#F39200" }}
            >
            Declined
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCard;
