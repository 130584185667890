import React, { useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import SimilarProfiles from "./SimilarProfiles";
import { Carousel } from "react-responsive-carousel";
import ImageModal from "./ImageModal"; // assuming you have a Modal component

const ProfileSide = ({ userImages, similarProfiles, userData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index) => {
    if (userImages.length > 0) {
      setSelectedImageIndex(index);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <MDBCol size="6" sm="3" className="profiledetails-full">
  {/*  /*  id = "mobile_profile"  */}
      <MDBContainer>
        <MDBRow>
          <Carousel showArrows={true} className="similar-photos">
            {userImages?.length > 0 ? (
              userImages.map((prfImg, index) => (
                <div
                  className="editprofile-data img-fluid"
                 
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => openModal(index)}
                >
                  <img
                    src={`${prfImg}`}
                    alt="profile-img"
                    className="img-fluid searchemployee upload upload-image"
                  />
                </div>
              ))
            ) : (
              <div className="editprofile-data img-fluid ">
                <img
                  src="/default-photo-url.png"
                  className="img-fluid searchemployee"
                  alt="Default"
                  onClick={() => openModal(0)}
                />
              </div>
            )}
          </Carousel>
          <SimilarProfiles profiles={similarProfiles} />
        </MDBRow>
      </MDBContainer>
      {modalOpen && (
        <ImageModal  onClose={closeModal}>
          <p style={{ color: "white" }}>{userData.name} ({userData.profile_id})</p>

          <Carousel selectedItem={selectedImageIndex}>
            {userImages.map((image, index) => (
              <div key={index} className="d-flex align-items-center">
                <img src={image} alt={`Image ${index}`} />
              </div>
            ))}
          </Carousel>
        </ImageModal>
      )}
      {modalOpen && <div className="modal-backdrop fade show"></div>}
    </MDBCol>
  );
};

export default ProfileSide;
// import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
// import React from "react";
// import SimilarProfiles from "./SimilarProfiles";
// import { Carousel } from "react-responsive-carousel";

// const ProfileSide = ({userImages, similarProfiles}) => {
//   return (
//     <MDBCol size="6" sm="3" className="profiledetails-full">
//       <MDBContainer>
//         <MDBRow>
//           <Carousel showArrows={true} className="similar-photos">
//            {userImages?.length > 0 ? (
//               userImages.map((prfImg, index) => (
//                 <div className="editprofile-data img-fluid " key={index}>
//                   <img
//                     src={`${prfImg}`}
//                     alt="profile-img"
//                     className="img-fluid searchemployee upload upload-image"
//                   />
//                 </div>
//               ))
//             ) : (
//               <div className="editprofile-data img-fluid ">
//                 <img
//                   src="/default-photo-url.png"
//                   className="img-fluid searchemployee"
//                   alt="Default"
//                 />
//               </div>
//             )}
//           </Carousel>
//           <SimilarProfiles profiles={similarProfiles} />
//         </MDBRow>
//       </MDBContainer>
//     </MDBCol>
//   );
// };

// export default ProfileSide;
