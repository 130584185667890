import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomModal from '../CustomModel';
import DeleteConfirmModel from '../DeleteConfirmModel';
import api from "../../Api/SettingApi"
function DeleteAccount() {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [showModal, setShowModal] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [error, setError] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [reasons, setReason] = useState([]);
  const [showModalcustom, setShowModalcustom] = useState(false);
  const [modalTitlecustom, setModalTitlecustom] = useState("");
  const [modalMessagecustom, setModalMessagecustom] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        navigate("/login");
        return;
      }
      const tokenData = parseToken(token);

      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenData.exp < currentTime) {
          clearToken();
          navigate("/login");
        }
      }
    };

    checkTokenExpiration();
  }, [navigate]);

  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }
  function clearToken() {
    localStorage.removeItem("authToken");
  }
  const handleCloseModal = () => {
    setShowModal(false);

  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModalcustom = () => {
    setShowModalcustom(false);
    clearToken();
    navigate("/Login");
  };
  const handleOpenModalcustom = () => {
    setShowModalcustom(true);
  };

  const handleReasonSelect = (event) => {
    setSelectedReason(event.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const fetchDeleteReasons = async () => {
      try {
        await api.getDeleteReason(token).then(({ data }) => {
          /* console.log('data', data); */
          if (data.status) {
            setReason(data.data.delete_reason);
          }
        });

      } catch (error) {
        console.error("Error fetching delete reasons:", error);
      }
    };
    if (token) {
      fetchDeleteReasons();
    } else {
      console.error("Authentication token missing");
    }
  }, []);

  const handleDeleteConfirm = async () => {
    try {
      const payload = {
        deleteReason: selectedReason,
        otherReason: otherReason
      };
      await api.DeleteAccount(payload, token).then(({ data }) => {

        if (data.status) {
          setModalTitlecustom(data.message);
          setModalMessagecustom(data.message);
          handleOpenModalcustom();
          handleCloseModal()
          setTimeout(() => {
            navigate("/Login");
          }, 2000);
        }
      });
    } catch (error) {
      setError("An error occurred while deleting the account.");
      console.error(error);
    }
  };
  const handleDelete = async () => {
    if (selectedReason === "" && otherReason === "") {
      setError("Please share your reason");
    } else {
      setError('')
      handleOpenModal();
    }
  };
  return (
    <div className="delete-profile-full">
      <p className="delete-resion">Delete Profile</p>
      <p className="delete-definition">
        We hope you have found your life partner that you're deleting
        this profile from IBG matrimony.
      </p>
      <select
        id="reason"
        name="reason"
        className="profile-privacy-delete"
        style={{ width: 650 }}
        value={selectedReason}
        onChange={handleReasonSelect}
      >
        <option value="" disabled>
          Select a reason
        </option>
        {reasons?.map((reason) => (
          <option key={reason.id} value={reason.id}>
            {reason.delete_reason}
          </option>
        ))}
      </select>
      <div className="form-group explanation-full">
        <label htmlFor="exampleFormControlTextarea1">
          Other reason
        </label>

        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="2"
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
      </div>
      <Button
        onClick={handleDelete}
        className="searchButtons-profileprivacy-delete"
        type="submit"
      >
        Submit
      </Button>
      {message && <div>{message}</div>}
      {error && (
        <div className="error-deleteaccount">Error: {error}</div>
      )}
      <DeleteConfirmModel
        show={showModal}
        handleClose={handleCloseModal}
        handleDeleteConfirm={handleDeleteConfirm}
        title="Delete Account"
        handleCloseModal={handleCloseModal}
        message="Are you sure you want to delete the account?"
      ></DeleteConfirmModel>

      <CustomModal
        show={showModalcustom}
        handleClose={handleCloseModalcustom}
        title="Delete Account"
        handleCloseModal={handleCloseModal}
        message={modalMessagecustom}
      >
        {/* Yes and No buttons */}
      </CustomModal>

    </div>
  )
}

export default DeleteAccount