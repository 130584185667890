import "../../style/searchprofile.css";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import InterationSection from "../Interations/InterationSection";
import Search_container from "./Search_container";

function Serach({
  selectedTab,
  titleDiscription,
  setSelectedTab,
  setTitleDiscription,
}) {
  const token = localStorage.getItem("authToken");
  return (
    <MDBRow className="myhome-matches-all" style={{ justifyContent: "center" }}>
      {token && (
        <ProfileSideBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
      )}
      {selectedTab === "Home" ? (
        <Search_container />
      ) : (
        <InterationSection
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          titleDiscription={titleDiscription}
          setTitleDiscription={setTitleDiscription}
        />
      )}
    </MDBRow>
  );
}

export default Serach;
