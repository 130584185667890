import { axios, constants } from "../Config";

class AuthApiFetch {
  getRegister() {
    return axios
      .get(`${constants.baseURL}get-register`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  register(payload) {
    return axios
      .post(`${constants.baseURL}register`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  login(payload) {
    return axios
      .post(`${constants.baseURL}login`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  forgotPassword(payload) {
    return axios
      .post(`${constants.baseURL}forget-password`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  validateOTP(payload) {
    return axios
      .post(`${constants.baseURL}validateOTP`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  resetPassword(payload, token) {
    return axios
      .post(`${constants.baseURL}reset-password`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  logout(payload, token) {
    return axios
      .post(`${constants.baseURL}logout`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}
export default new AuthApiFetch();
