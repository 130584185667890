const { axios, constants } = require("../../Config");
const baseURL = `${constants.baseURL}agent`;

class AgentProfileFetch {
  getRegisterDetails() {
    return axios
      .get(`${baseURL}/get-details`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  createProfile(token, payload) {
    return axios
      .post(`${baseURL}/profile/create`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updatePartnerPreference(token, payload) {
    return axios
      .post(`${baseURL}/profile/partner-preference`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfiles(payload, token) {
    return axios
      .post(`${baseURL}/profiles`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfileById(payload, token) {
    return axios
      .get(`${baseURL}/profiles/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAgentProfileMatches(profile_id, payload, token) {
    return axios
      .post(`${baseURL}/profile-matches/${profile_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}
export default new AgentProfileFetch();
