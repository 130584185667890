import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../style/contactinformation.css";
import ProfileApi from "../../Api/ProfileApi";
import SettingApi from "../../Api/SettingApi";

function ContactInformation() {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState({
    mobile: false,
    email: false,
  });
  const [formValues, setFormValues] = useState({
    mobile: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    mobile: "",
    email: "",
  });

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (!token) {
        navigate("/login");
        return;
      }
      const tokenData = parseToken(token);
      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenData.exp < currentTime) {
          clearToken();
          navigate("/login");
        }
      }
    };

    checkTokenExpiration();
  }, [navigate, token]);

  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }

  function clearToken() {
    localStorage.removeItem("authToken");
  }

  useEffect(() => {
    fetchUserProfileDetails();
  }, []);

  const fetchUserProfileDetails = async () => {
    try {
      const response = await ProfileApi.getEditProfile(token);
      const data = response.data;

      if (data.status) {
        setUserData(data?.data?.user_profile);
        setFormValues({
          mobile: data?.data?.user_profile?.mobile || "",
          email: data?.data?.user_profile?.email || "",
        });
      } else if (data.statusCode === 401) {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching user profile details:", error);
    }
  };

  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleSaveClick = async (field) => {
    if (formValues[field] === userData[field]) {
      setIsEditing((prev) => ({ ...prev, [field]: false }));
      return;
    }

    try {
      const updateData = { [field]: formValues[field] };
      const response = await SettingApi.ContactInformation(updateData, token);

      if (response.data.status) {
        setUserData((prev) => ({ ...prev, [field]: formValues[field] }));
        setIsEditing((prev) => ({ ...prev, [field]: false }));
        setErrors((prev) => ({ ...prev, [field]: "" }));
      } else {
        setErrors((prev) => ({ ...prev, [field]: response.data.message }));
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="profileprivacy-explanation space1">
      <p style={{ padding: "0" }} className="change-resion changeh1">
        Edit your contact information
      </p>
      <Form>
        <Form.Group
          as={Row}
          className="mb-3 editcontact"
          controlId="formPlaintextMobile"
        >
          <Form.Label column sm="3">
            Phone Number<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Col sm="9" className="div1">
            <Form.Control
              type="text"
              name="mobile"
              value={formValues.mobile}
              onChange={handleInputChange}
              readOnly={!isEditing.mobile}
            />
            {isEditing.mobile ? (
              <>
                <Button onClick={() => handleSaveClick("mobile")}>Save</Button>
                {errors.mobile && (
                  <p className="error-message" style={{ color: "red" }}>
                    {errors.mobile}
                  </p>
                )}
              </>
            ) : (
              <Button onClick={() => handleEditClick("mobile")}>Edit</Button>
            )}
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 editcontact"
          controlId="formPlaintextEmail"
        >
          <Form.Label column sm="3">
            E-mail <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Col sm="9" className="div1">
            <Form.Control
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              readOnly={!isEditing.email}
            />
            {isEditing.email ? (
              <>
                <Button onClick={() => handleSaveClick("email")}>Save</Button>
                {errors.email && (
                  <p className="error-message" style={{ color: "red" }}>
                    {errors.email}
                  </p>
                )}
              </>
            ) : (
              <Button onClick={() => handleEditClick("email")}>Edit</Button>
            )}
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ContactInformation;
