import React, { useEffect, useState } from "react";
import Navbars from "../Navbars";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSide from "../EditProfile/ProfileSide";
import api from "../../Api/ProfileApi";
import { useParams } from "react-router-dom";
import Details from "./Details";
import Footer from "../Footer";
import ProfileSideBar from "../ProfileSideBar";
import InterationSection from "../Interations/InterationSection";

const NewProfileDetails = () => {
  const token = localStorage.getItem("authToken");
  const { userId } = useParams();
  const [userImages, setUserImages] = useState([]);
  const [similarProfiles, setSimilarProfiles] = useState([]);
  const [userData, setUserData] = useState({});
  const [partnerPreference, setPartnerPreference] = useState([]);
  const [isHideProfile, setIsHideProfile] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  useEffect(() => {
    fetchProfileDetails();
  }, []);

  const fetchProfileDetails = () => {
    api.getProfileDetails(userId, token).then(({ data }) => {
      if (data?.status) {
        if (data?.data?.user_profile[0]?.isHide) {
          setIsHideProfile(true);
          setUserImages(data?.data?.images);
          setSimilarProfiles(data?.data?.similar_profiles);
          setUserData(data?.data?.user_profile[0]);
          setPartnerPreference(data?.data?.partner_preference);
        } else {
          setIsHideProfile(false);
          setUserImages(data?.data?.images);
          setSimilarProfiles(data?.data?.similar_profiles);
          setUserData(data?.data?.user_profile[0]);
          setPartnerPreference(data?.data?.partner_preference);
        }
      }
    });
  };
  return (
    <div className="matches-background">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      <Container>
        {selectedTab === "Home" ? (
          <MDBRow className="profileedit-matches-all">
            { isBlocked ? (
              <div
                className="d-flex justify-content-center fs-5 text-secondary  align-items-center"
                style={{ height: "70vh" }}
              >
                <p style={{ color: "#640449", marginTop: "1rem" }}>
                  {" "}
                  This profile was blocked
                </p>
              </div>
            ) : (
              <>
                <ProfileSide
                  userImages={userImages}
                  userData={userData}
                  similarProfiles={similarProfiles}
                />
                <Details
                  userId={userId}
                  userData={userData}
                  userImages={userImages}
                  partnerPreference={partnerPreference}
                  setIsHideProfile={setIsHideProfile}
                  setIsBlocked={setIsBlocked}
                />
              </>
            )}
          </MDBRow>
        ) : (
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
            <InterationSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              titleDiscription={titleDiscription}
              setTitleDiscription={setTitleDiscription}
            />
          </MDBRow>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default NewProfileDetails;
