import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
const AgentMatchProgress = ({ value, size, fontSizes }) => {
  return (
    <>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {/* Progress Circle */}
        <CircularProgress
          thickness={4.5}
          size={size}
          variant="determinate"
          value={100}
          sx={{
            color: "#D0E2FF",
            position: "absolute",
            left: 0,
          }}
        />
        {/* Background Circle */}
        <CircularProgress
          thickness={4.5}
          size={size}
          variant="determinate"
          value={70}
          sx={{
            color: "#0AB93B",
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* First line with "Matching" */}
            <Typography
              variant="caption"
              component="div"
              sx={{
                textAlign: "center",
                color: "#525252",
                fontSize: `${fontSizes[0]}`,
              }}
            >
              Matching
            </Typography>

            {/* Second line with the percentage value */}
            <Typography
              variant="caption"
              component="div"
              sx={{
                textAlign: "center",
                color: "#262626",
                fontSize: `${fontSizes[1]}`,
              }}
            >
              {`${value}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AgentMatchProgress;
