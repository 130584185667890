import { MDBCol } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import "../../style/notification.css";
import api from "../../Api/GeneralApi";
import Notification from "./Notification";

const List = () => {
  const token = localStorage.getItem("authToken");
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    api.getAllNotifications({}, token).then(({ data }) => {
      if (data.status) {
        setNotifications(data.data);
      }
    });
  };
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="notification_container">
        <h3 className="heading">Notifications</h3>

        <hr />

        <div className="notification_inner_container">
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Notification notification={notification} key={index} />
            ))
          ) : (
            <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center ">
              <p style={{ color: "#640449", marginTop: "1rem" }}>
                {" "}
                No notifications for you
              </p>
            </div>
          )}
        </div>
      </div>
    </MDBCol>
  );
};

export default List;
