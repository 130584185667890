import { MDBCol } from 'mdb-react-ui-kit';
import React from 'react'

const NewmatchSlider= ({ id, image, name, age }) => {
    return (
      <div className="newdaily-slider text-center">
        <a href={`profile-details/${id}`}>
          <img
            src={image || "default-photo-url.png"}
            className="dailyImgage"
            height={160}
            alt={name}
          />
  
          <p className="sebastian-full">{name}</p>
          <p className="sebastian-years">
            {age} years | {id}
          </p>
        </a>
      </div>
    );
  };

export default NewmatchSlider