import "../../style/searchprofile.css";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import InterationSection from "../Interations/InterationSection";
import QuestionsContainer from "./QuestionsContainer";


function Faq({
  selectedTab,
  titleDiscription,
  setSelectedTab,
  setTitleDiscription,
}) {
  const token = localStorage.getItem("authToken");
  return (
    <MDBRow className="myhome-matches-all" style={{ justifyContent: "center" }}>
     <QuestionsContainer />
    </MDBRow>
  );
}

export default Faq;
