// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchprofile h4 {
  text-align: center !important;
  color: #303a42 !important;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 2%;
  font-family: "Lato", sans-serif;
  padding-top: 3%;
}

.termsHeadMain {
  color: #303a42 !important;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: left;
}

.termsHead {
  color: #303a42 !important;
  font-size: 22px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
}
.tremsContent{ 
  text-align: justify;
}

.liPadding{
    padding-left: 14px !important;
    text-align: justify;
}
.termsliSpan{
    font-weight: 500;
}
.termsAddress{
    padding-top: 13px;
    font-weight: 500;
    line-height: normal;
}

/* FAQs */
.card {
  --bs-card-border-color: none !important;
  box-shadow : none !important;
  --bs-card-cap-bg : none !important;
  --mdb-card-box-shadow : none !important;
  border-bottom: 1px solid var(--bs-border-color-translucent) !important;
  border-radius: 0px !important;
  margin-top: 37px !important;
}`, "",{"version":3,"sources":["webpack://./src/style/terms_faq.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA,SAAS;AACT;EACE,uCAAuC;EACvC,4BAA4B;EAC5B,kCAAkC;EAClC,uCAAuC;EACvC,sEAAsE;EACtE,6BAA6B;EAC7B,2BAA2B;AAC7B","sourcesContent":[".searchprofile h4 {\n  text-align: center !important;\n  color: #303a42 !important;\n  font-size: 28px;\n  font-weight: bold;\n  padding-bottom: 2%;\n  font-family: \"Lato\", sans-serif;\n  padding-top: 3%;\n}\n\n.termsHeadMain {\n  color: #303a42 !important;\n  font-size: 25px;\n  font-weight: 600;\n  line-height: 37.5px;\n  text-align: left;\n}\n\n.termsHead {\n  color: #303a42 !important;\n  font-size: 22px;\n  font-weight: 400;\n  line-height: 37.5px;\n  text-align: left;\n}\n.tremsContent{ \n  text-align: justify;\n}\n\n.liPadding{\n    padding-left: 14px !important;\n    text-align: justify;\n}\n.termsliSpan{\n    font-weight: 500;\n}\n.termsAddress{\n    padding-top: 13px;\n    font-weight: 500;\n    line-height: normal;\n}\n\n/* FAQs */\n.card {\n  --bs-card-border-color: none !important;\n  box-shadow : none !important;\n  --bs-card-cap-bg : none !important;\n  --mdb-card-box-shadow : none !important;\n  border-bottom: 1px solid var(--bs-border-color-translucent) !important;\n  border-radius: 0px !important;\n  margin-top: 37px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
