import React, { useEffect, useState } from "react";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { useParams } from "react-router-dom";
import AgentMatchProfileCard from "../Matches/AgentMatchProfileCard";
import ReactPaginate from "react-paginate";

const ShortlistedAgentProfileList = ({ activeTab }) => {
  const { profileId } = useParams();
  const token = localStorage.getItem("authToken");
  const [pagination, setPagination] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    const element = document.getElementById("agent-profile-match");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    fetchShortlistedProfiles();
  }, [pageDetails, activeTab]);
  const fetchShortlistedProfiles = () => {
    let payload = {
      page: pageDetails?.page,
    };
    api
      .getShortlistAgentProfiles(profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setPagination(data?.data?.pagination);
          setProfiles(data?.data?.shortlist);
        }
      });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  return (
    <>
      <div className="member-who-waches">
        <p className="member-watches">
          <span className="new-matches-only">My Shortlist</span> - Members Who
          have Shortlisted by you!
        </p>
      </div>
      {profiles.length > 0 ? (
        profiles.map((profile, index) => (
          <AgentMatchProfileCard key={index} user={profile} from="accepted" />
        ))
      ) : (
        <div className="profile-editprofile">
          <div className="d-flex justify-content-center fs-5 text-secondary align-items-center h-100">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              No profiles shortlisted
            </p>
          </div>
        </div>
      )}
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShortlistedAgentProfileList;
