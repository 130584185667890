import {
    MDBBtn,
    MDBInput,
    MDBModal,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
  } from "mdb-react-ui-kit";
  import React, { useEffect, useState } from "react";
  import api from "../../../Api/AuthApi";
  
  const AgentOtpValidation = ({
    modalType,
    setModalType,
    otpExpiration,
    setOtpExpiration,
    forgotPassword,
    setForgotPassword,
  }) => {
    const [formData, setFormData] = useState({
      otp: "",
      error: "",
    });
    const [timer, setTimer] = useState(0);
  
    useEffect(() => {
      setTimer(otpExpiration);
    }, [otpExpiration]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTimer((prevValue) => prevValue - 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const handleOtp = (e) => {
      const value = e.target.value;
      if (value) {
        setFormData((prevValue) => ({
          ...prevValue,
          error: "",
        }));
      } else {
        setFormData((prevValue) => ({
          ...prevValue,
          error: "Please enter OTP",
        }));
      }
      setFormData((prevValue) => ({
        ...prevValue,
        otp: value,
      }));
    };
  
    // Convert remaining seconds to mm:ss format
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  
    const onSubmit = (e) => {
      e.preventDefault();
      try {
        if (!formData?.otp) {
          setFormData((prevValue) => ({
            ...prevValue,
            error: "Please enter a valid OTP.",
          }));
        }
  
        if (formData?.otp) {
          const payload = {
            user: forgotPassword?.user,
            otp: formData?.otp,
          };
          api.validateOTP(payload).then(({ data }) => {
            if (data.status) {
              setForgotPassword((prevValue) => ({
                ...prevValue,
                authToken: data?.token,
              }));
              setModalType("password");
            } else {
              setFormData((prevValue) => ({
                ...prevValue,
                error: data?.message,
              }));
            }
          });
        }
      } catch (err) {
        console.log("err", err);
        setFormData((prevValue) => ({
          ...prevValue,
          error: "Sorry, something went wrong",
        }));
      }
    };
  
    const resendOtp = (e) => {
      e.preventDefault();
      if (forgotPassword?.user) {
        const payload = {
          user: forgotPassword?.user.trim(),
        };
        api.forgotPassword(payload).then(({ data }) => {
          if (data.status) {
            setOtpExpiration(data?.otpExpirationTime * 60);
          } else {
            setFormData((prevValue) => ({
              ...prevValue,
              error: data?.message,
            }));
          }
        });
      }
    };
  
    return (
      <MDBModal
        show={modalType === "otp" ? true : false}
        onHide={() => {
          setModalType("");
        }}
        tabIndex="-1"
        className="model-full"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="forgotpassword-header">
              <div className="forgot-header">
                <MDBModalTitle>Login Via OTP</MDBModalTitle>
              </div>
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                onClick={() => {
                  setModalType("");
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <p className="forgot-paragraph">Enter your OTP.</p>
            <div className="box-size-forgot">
              <MDBInput
                wrapperClass="mb-0 "
                className="varificationid"
                label="Enter your OTP"
                id="formControlLg-login "
                type="text"
                size="lg"
                value={formData?.otp}
                onChange={handleOtp}
              />
            </div>
            <div>
              {formData?.error && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    paddingLeft: "10%",
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingBottom: "2%",
                  }}
                >
                  {formData?.error}
                </p>
              )}
              {timer > 0 ? (
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    marginTop: "3px",
                  }}
                >
                  OTP expires in: {formattedTime}
                </div>
              ) : (
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    marginTop: "3px",
                  }}
                >
                  Didn't Get OTP?{" "}
                  <button className="forgot-submit-otp" onClick={resendOtp}>
                    Resend OTP
                  </button>
                </div>
              )}
            </div>
  
            <MDBModalFooter>
              <MDBBtn type="submit" className="forgot-submit" onClick={onSubmit}>
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  };
  
  export default AgentOtpValidation;
  