import React, { useRef } from "react";
//import '../style/displaypage.css'
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

function Slidermatches({ selectedTab, setSelectedTab }) {
  const menuRef = useRef(null);
  //background//
  const location = useLocation();

  const scrollRight = () => {
    //menuRef.current.scrollLeft += 300;
    menuRef.current.scrollBy(300, 0);
    console.log(menuRef.current);
  };
  const scrollLeft = () => {
    // menuRef.current.scrollLeft -= 300;
    menuRef.current.scrollBy(-300, 0);
  };

  return (
    <div className="menu-wrapper">
      <div className="menu-item" ref={menuRef}>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "All Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("All Matches")}
        >
          <Button type="button" as={Link}>
            All Matches
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "New Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("New Matches")}
        >
          <Button type="button" as={Link}>
            New Matches
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Premium Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Premium Matches")}
        >
          <Button type="button" as={Link}>
            Premium Matches
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Location Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Location Matches")}
        >
          <Button type="button" as={Link}>
            Location Matches
          </Button>
        </Link>
        <Link
          to="#"
          className={`matches-buttons ${
            selectedTab === "Education Matches" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("Education Matches")}
        >
          <Button type="button" as={Link}>
            Education Matches
          </Button>
        </Link>
      </div>
      <span className="pointer left-pointer dis" onClick={scrollLeft}>
        <i className="fa fa-chevron-left"></i>
      </span>
      <span className="pointer right-pointer" onClick={scrollRight}>
        <i className="fa fa-chevron-right"></i>
      </span>
    </div>
  );
}

export default Slidermatches;
