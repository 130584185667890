import { axios, constants } from "../Config";

class InterationApiFetch {
  getInterestedProfiles(payload, token) {
    return axios
      .post(`${constants.baseURL}view-interest-by-me`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getInterestRecived(payload, token) {
    return axios
      .post(`${constants.baseURL}/view-interest-received`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getAcceptedbyOther(payload, token) {
    return axios
      .post(`${constants.baseURL}view-interest-accepted-by-other`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getAcceptedProfile(payload, token) {
    return axios
      .post(`${constants.baseURL}view-interest-accepted-by-me`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getRejectedProfile(payload, token) {
    return axios
      .post(`${constants.baseURL}view-interest-rejected-by-me`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getRejectedbyOther(payload, token) {
    return axios
      .post(`${constants.baseURL}view-interest-rejected-by-other`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getShortlistedProfile(payload, token) {
    return axios
      .post(`${constants.baseURL}view-shortlist-by-other`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getMyShortlist(payload, token) {
    return axios
      .post(`${constants.baseURL}view-shortlist-by-me`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getWhoVisited(payload, token) {
    return axios
      .post(`${constants.baseURL}view-visitors`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getHideProfile(payload, token) {
    return axios
      .post(`${constants.baseURL}/view-hide-users`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  InterestReply(profileId, payload, token) {
    return axios
      .post(`${constants.baseURL}/inerest-accepted/${profileId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  UnhideProfile(params, token) {
    return axios
      .post(
        `${constants.baseURL}hide-user/${params}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }

  DeleteProfiles(profileId, payload, token) {
    return axios
      .post(`${constants.baseURL}reject-reply/${profileId} `, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  RejectedDelete(profileId, payload, token) {
    return axios
      .post(`${constants.baseURL}reject-reply/${profileId} `, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getBlockedProfiles(payload, token) {
    return axios
      .post(`${constants.baseURL}view-blocked-users`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
}

export default new InterationApiFetch();
