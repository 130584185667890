import React from "react";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

const PartnerPreference = ({ userData, partnerPreference }) => {
  const getUserDetails = useSelector(getProfileDetails);
  return (
    <>
      <p className="partner-preference-profileedit">
        Partner Preference Matches
      </p>
      <div className="importentdetails-editprofile">
        <p className="matching-arrow">
          <span
            style={{ position: "relative", top: "15px" }}
            className="matching-percentage"
          >
            Profile Matching
            <span style={{ fontWeight: "600" }} className="colorpercentage">
              {" "}
              {partnerPreference?.partnerPercentage || "0"}%{" "}
            </span>
          </span>
          <br></br>
          {getUserDetails?.profile_image ? (
            <>
              <img
                src={getUserDetails?.profile_image}
                className=" searchemployees-profiledetails"
                alt="User"
                style={{ borderRadius: "40px", width: "40px" }}
              />
              {/* <p>{userData.partner_name}</p> */}
            </>
          ) : (
            <>
              <img
                src="/default-photo-url.png"
                className=" searchemployees-profiledetails"
                alt="Default"
                style={{ borderRadius: "40px", width: "40px" }}
              />
              {/*  <p>{userData.partner_name}</p> */}
            </>
          )}

          <img src="../Images/Line 21.png" className="side-arrow"></img>
          {partnerPreference?.partner_image ? (
            <>
              <img
                src={partnerPreference?.partner_image}
                className=" searchemployees-profiledetails"
                alt="User"
                style={{ borderRadius: "40px", width: "40px" }}
              />
              {/*   <p>{userData.name}</p> */}
            </>
          ) : (
            <>
              <img
                src="/default-photo-url.png"
                className=" searchemployees-profiledetails"
                alt="Default"
                style={{ borderRadius: "40px", width: "40px" }}
              />
              {/* <p>{userData.name}</p> */}
            </>
          )}
        </p>

        <div>
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Your partner preferences match this member's basic details.
          </p>
        </div>
        <MDBRow className="margin_d">
          <p className="profile-description">Basic Preference</p>
          <MDBCol className=" col-12 col-md-6 preference-all">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Age
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{""}{" "}
                {userData?.partner_age
                  ? `${userData?.partner_age} years`
                  : "Not defined"}
              </div>
              {partnerPreference?.partner_age_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Height
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{""}
                {userData?.partner_height
                  ? `${userData?.partner_height}`
                  : "Not defined"}
              </div>
              {partnerPreference?.partner_height_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Eating Habits
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                : {userData?.partner_eating_habit || "Not defined"}
              </div>
              {partnerPreference?.partner_eating_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Smoking Habits
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{""}
                {userData?.partner_smoking_habit || "Not defined"}
              </div>
              {partnerPreference?.partner_smoking_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>
          </MDBCol>
          <MDBCol className=" col-12 col-md-6 preference-all">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Drinking Habits
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{""} {userData?.partner_drinking_habit || "Not defined"}
              </div>
              {partnerPreference?.partner_drinking_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Marital Status
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{userData?.partner_marital_status || "Not defined"}
              </div>
              {partnerPreference?.partner_matrial_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Physical status
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{userData?.partner_physical_status || "Not defined"}
              </div>
              {partnerPreference?.partner_physical_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>

            {/* ANNUAL INCOME */}

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 2 }} className="full-bolder first_d">
                Annual Income
              </div>
              <div className="sec_d" style={{ flex: 2 }}>
                :{userData?.partner_annual_income || "Not defined"}
              </div>
              {partnerPreference?.partner_income_status ? (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="green tick"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_tick.png"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 1 }}
                  role="img"
                  aria-label="red mark"
                  className="check-image"
                >
                  <img
                    style={{ width: "20px" }}
                    src="../Images/preference_close.png"
                    alt=""
                  />
                </div>
              )}
            </div>
          </MDBCol>

          {/*  religion */}
          {userData &&
            userData?.partner_religion &&
            userData?.partner_religion.length > 0 && (
              <>
                <p className="profile-description">Religion</p>
                <ul className="ks-cboxtags">
                  {userData?.partner_religion.map((religion, index) => {
                    const isMatching =
                      partnerPreference.partner_religion_status
                        .matchingReligionId === religion.id;

                    return (
                      <li key={index}>
                        <input type="checkbox" checked onChange={() => {}} />
                        <label
                          htmlFor="checkboxOne"
                          style={{
                            cursor: "auto",
                            backgroundColor: isMatching ? "green" : "auto",
                          }}
                        >
                          {religion?.religion}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

          {/*  caste */}
          {userData &&
            userData?.partner_caste &&
            userData?.partner_caste.length > 0 && (
              <>
                <p className="profile-description">Caste</p>
                <ul className="ks-cboxtags">
                  {userData?.partner_caste.map((caste, index) => {
                    const isMatching =
                      partnerPreference.partner_caste_status.matchingCasteId ===
                      caste.id;
                    return (
                      <li key={index}>
                        <input type="checkbox" checked onChange={() => {}} />
                        <label
                          htmlFor="checkboxOne"
                          style={{
                            cursor: "auto",
                            backgroundColor: isMatching ? "green" : "auto",
                          }}
                        >
                          {caste?.caste}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

          {userData &&
            userData?.partner_location &&
            userData?.partner_location.length > 0 && (
              <>
                <p className="profile-description">Locations</p>
                <ul className="ks-cboxtags">
                  {userData?.partner_location.map((location, index) => {
                    const isMatching =
                      partnerPreference.partner_location_status
                        .matchingLocationId === location.id;
                    return (
                      <li key={index}>
                        <input type="checkbox" checked onChange={() => {}} />
                        <label
                          htmlFor="checkboxOne"
                          style={{
                            cursor: "auto",
                            backgroundColor: isMatching ? "green" : "auto",
                          }}
                        >
                          {location?.location}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          {userData &&
            userData?.partner_star &&
            userData?.partner_star.length > 0 && (
              <>
                <p className="profile-description">Stars</p>
                <ul className="ks-cboxtags">
                  {userData?.partner_star.map((star, index) => {
                    const isMatching =
                      partnerPreference.partner_star_status.matchingStarId ===
                      star.id;
                    return (
                      <li key={index}>
                        <input type="checkbox" checked onChange={() => {}} />
                        <label
                          htmlFor="checkboxOne"
                          style={{
                            cursor: "auto",
                            backgroundColor: isMatching ? "green" : "auto",
                          }}
                        >
                          {star?.star}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

          {userData &&
            userData?.partner_professional_status &&
            userData?.partner_professional_status.length > 0 && (
              <>
                <p className="profile-description">
                  Partner professional status
                </p>
                <ul className="ks-cboxtags">
                  {userData?.partner_professional_status.map(
                    (profession, index) => {
                      const isMatching =
                        partnerPreference.partner_professional_status
                          .matchingprofessionalId === profession.id;
                      return (
                        <li key={index}>
                          <input type="checkbox" checked onChange={() => {}} />
                          <label
                            htmlFor="checkboxOne"
                            style={{
                              cursor: "auto",
                              backgroundColor: isMatching ? "green" : "auto",
                            }}
                          >
                            {profession?.occupation}
                          </label>
                        </li>
                      );
                    }
                  )}
                </ul>
              </>
            )}
        </MDBRow>
      </div>
    </>
  );
};

export default PartnerPreference;
