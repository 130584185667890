import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const ProfileDetails = ({ profileData }) => {
  const [hobbies, setHobbies] = useState([]);

  useEffect(() => {
    if (profileData?.hobbies) {
      setHobbies(profileData.hobbies);
    }
  }, [profileData]);
  return (
    <>
      <h4 style={{ textAlign: "center", margin: "20px 0px" }}>
        Profile Details
      </h4>
      <div className="profile-editprofile">
        <p className="profile-description-profiledetails">
          Profile Description
        </p>
        <p className="profile-des">
          {profileData?.profileDescription || "not defined"}
        </p>
        <hr />
        <p className="profile-description">Basic Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Name
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.name}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Age
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.age ? `${profileData?.age} Years` : "not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Gender
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.gender || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Height
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.heightInFeet
                  ? `${profileData?.heightInFeet} feet`
                  : "not defined"}{" "}
                ,{" "}
                {profileData?.heightInCm
                  ? `${profileData?.heightInCm} cm`
                  : "height not defined"}
              </div>
            </div>

            {/* WEIGHT */}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Weight
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.weight
                  ? `${profileData?.weight} Kg`
                  : "not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Marital Status
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.maritalStatus
                  ? profileData?.maritalStatus
                  : "not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Physical Status
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.physicalStatus
                  ? profileData?.physicalStatus
                  : "not defined"}
              </div>
            </div>
            {/*  BODY TYPE */}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Body Type
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.bodyType
                  ? `${profileData?.bodyType} `
                  : "not defined"}
              </div>
            </div>

            {/* COMPLEXION */}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Complexion
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {profileData?.complexion
                  ? `${profileData?.complexion} `
                  : "not defined"}
              </div>
            </div>

            {/* STAR */}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Star
              </div>
              <div style={{ flex: 1 }}>
                : {profileData?.star ? `${profileData?.star} ` : "not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Religious Details </p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            {" "}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Religion
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.religion || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Caste
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.caste || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Professional Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Highest Education
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.mainQualification || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Employed in
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.employedIn || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Annual income
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.annualIncome || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Qualification
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.subQualification || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Occupation details
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.occupation || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Location Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                State
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.state || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                District
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.district || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                City
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.city || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Family Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family value
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.familyValue || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Father's Occupation
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.fatherOccupation || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                No. of Brothers
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.noOfBrothers || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Type
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.familyType || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Status
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.familyStatus || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Mother's Occupation
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.motherOccupation || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                No. of Sisters
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.noOfSisters || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Location
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.familyLocation || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Interest & Hobbies</p>
        <MDBRow>
          <div className="hobbies_div" style={{ display: "flex" }}>
            {hobbies.length > 0 ? (
              <ul className="ks-cboxtags view_dh">
                {hobbies.map((hobby) => (
                  <li key={hobby.id}>
                    <label>{hobby.hobbies}</label>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Not Defined</p>
            )}
          </div>
        </MDBRow>

        <hr />
        <p className="profile-description">LifeStyle</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Eating Habits
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.eatingHabit || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Smoking Habit
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.smokingHabit || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Drinking Habit
              </div>
              <div style={{ flex: 1 }}>
                :{""} {profileData?.drinkingHabit || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default ProfileDetails;
