import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import ProfileDescription from "./RightSide/ProfileDescription";
import BasicDetails from "./RightSide/BasicDetails";
import ReligiousDetails from "./RightSide/ReligiousDetails";
import ProfessionalDetails from "./RightSide/ProfessionalDetails";
import LocationDetails from "./RightSide/LocationDetails";
import FamilyDetails from "./RightSide/FamilyDetails";
import Habits from "./RightSide/Habits";
import Hobbies from "./RightSide/Hobbies";
import PreferenceMatches from "./RightSide/PreferenceMatches";

const RightSide = ({
  userData,
  setUserData,
  listHeights,
  listStars,
  religionCaste,
  educationList,
  employedInList,
  occupationList,
  annualIncomeList,
  stateAndDistricts,
  sports,
  arts,
  hobbies,
}) => {
  return (
    <MDBCol size="6" sm="9" className="profile-edit-danial">
      <p className="profile-editheading">Profile Edit</p>
      <p className="danils">
        {userData?.name}
        <span className="online">
          <img src="../Images/online.png"></img>
        </span>
        <span className="active-online">
          <span className="dot"></span> online
        </span>
      </p>

      <MDBRow className="profile-row">
        <MDBCol>
          <p className="mother-tongue">
            {userData?.age} Yrs | {userData?.profile_id}
          </p>
          <p className="mother-tongue">
            {" "}
            {userData?.height_cm
              ? `${userData?.height_cm} cm / ${userData?.height_feet}`
              : "height not defined"}
          </p>

          <p className="mother-tongue">
            {userData?.religion || "Religion not defined"} /{" "}
            {userData?.caste || "Caste not defined"}
          </p>
          <p className="mother-tongue">
            Occupation in {userData?.occupation || "Not defined"}
          </p>
        </MDBCol>
        <MDBCol>
          <p className="mother-tongue">
            Star is {userData?.star || "Not defined"}
          </p>

          <p className="mother-tongue">
            Lives in {userData?.district || "Not defined"}
          </p>
          <p className="mother-tongue">
            Studied {userData?.qualification_main || "Not defined"}
          </p>
        </MDBCol>
      </MDBRow>

      <div className="profile-editprofile">
        <ProfileDescription userData={userData} setUserData={setUserData} />
        <BasicDetails
          userData={userData}
          listHeights={listHeights}
          listStars={listStars}
        />
        <hr></hr>
        <ReligiousDetails userData={userData} religionCaste={religionCaste} />
        <hr></hr>
        <ProfessionalDetails
          userData={userData}
          educationList={educationList}
          employedInList={employedInList}
          occupationList={occupationList}
          annualIncomeList={annualIncomeList}
        />
        <hr></hr>
        <LocationDetails
          userData={userData}
          stateAndDistricts={stateAndDistricts}
        />
        <hr />
        <FamilyDetails
          userData={userData}
          stateAndDistricts={stateAndDistricts}
        />
        <hr />
        <Habits userData={userData} />
        <hr />
        <Hobbies
          userData={userData}
          sports={sports}
          arts={arts}
          hobbies={hobbies}
        />
      </div>
      <PreferenceMatches
        userData={userData}
        listHeights={listHeights}
        occupationList={occupationList}
        stateAndDistricts={stateAndDistricts}
        listStars={listStars}
        annualIncomeList={annualIncomeList}
        religionCaste={religionCaste}
      />
    </MDBCol>
  );
};

export default RightSide;
