import React from "react";
import { MDBCol } from "mdb-react-ui-kit";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import NewmatchSlider from "./NewmatchSlider";

const MatchSliderComponent = ({ newMatches }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <Slider className="second-slider" {...sliderSettings}>
      {newMatches?.length <= 0
        ? ["*", "*", "*", "*"].map((_, index) => (
            <MDBCol className="text-center p-2" key={index}>
              <div className="">
                <Skeleton height={160} />
              </div>

              <p className="sebastian-full">
                <Skeleton />
              </p>
              <p className="sebastian-years">
                <Skeleton />
              </p>
            </MDBCol>
          ))
        : newMatches.map((match) => (
            <NewmatchSlider
              key={match?.profile_id}
              id={match?.profile_id}
              name={match?.name}
              age={match?.age}
              image={match?.profile_image}
            />
          ))}
    </Slider>
  );
};

export default MatchSliderComponent;
