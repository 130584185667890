import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";

const AgentHabits = ({ registerData, handleRegisterData, register }) => {
  const [isEditingHabbits, setIsEditingHabbits] = useState(true);
  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Lifestyle</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Eating Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="eating_habit"
                name="eating_habit"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={registerData?.eating_habit}
                {...register("eating_habit")}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>

                <option value="Vegetarian">Vegetarian</option>
                <option value="Non vegetarian">Non vegetarian</option>
                <option value="Eggetarian">Eggetarian</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Eating Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.eating_habit || "Not defined"}
            </div>
          </div>
        )}
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Smoking Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="smoking_habit"
                name="smoking_habit"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={registerData?.smoking_habit}
                {...register("smoking_habit")}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>
                <option value="Never smokes">Never smokes</option>
                <option value="Smoke occasionally">Smoke occasionally</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Smoking Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.smoking_habit || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Driking Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="drinking_habit"
                name="drinking_habit"
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.drinking_habit}
                {...register("drinking_habit")}
                onChange={handleRegisterData}
              >
                <option value="">Select</option>
                <option value="Drinks socially">Drinks socially</option>
                <option value="Never drinks">Never drinks</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Driking Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.drinking_habit || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default AgentHabits;
