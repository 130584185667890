import { axios, constants } from "../Config";

class GeneralApiFetch {
  getHome(token) {
    return axios
      .get(`${constants.baseURL}home`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }

  getSearchById(id, token) {
    const config = {
      params: {
        id: id,
      },
    };
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return axios
      .get(`${constants.baseURL}search-id`, config)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }

  getSearchByValue(searchData, token) {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    return axios
      .post(`${constants.baseURL}search-value`, {
        ...searchData,
      }, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getAllNotifications(payload, token) {
    return axios
      .post(`${constants.baseURL}view-notification`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  blockUser(payload, token) {
    return axios
      .post(`${constants.baseURL}block-user/${payload}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }

  postContact(payload) {      
    return axios
      .post(`${constants.baseURL}contact-us`, payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
}

export default new GeneralApiFetch();
