// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0;
  font-family: 'Lato', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: none !important;

}

code {
  font-family: 'Lato', sans-serif;

}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAGA;EACE,SAAS;EACT,+BAA+B;;EAE/B,mCAAmC;EACnC,kCAAkC;EAClC,4BAA4B;;AAE9B;;AAEA;EACE,+BAA+B;;AAEjC","sourcesContent":["\r\n@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: 'Lato', sans-serif;\r\n\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  line-height: none !important;\r\n\r\n}\r\n\r\ncode {\r\n  font-family: 'Lato', sans-serif;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
