import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getInteractionCount } from "../../redux/features/profile";

function SliderInteration({
  selectedTab,
  setSelectedTab,
  setTitleDiscription,
}) {
  const menuRef = useRef(null);

  const scrollRight = () => {
    //menuRef.current.scrollLeft += 300;
    menuRef.current.scrollBy(300, 0);
    console.log(menuRef.current);
  };
  const scrollLeft = () => {
    // menuRef.current.scrollLeft -= 300;
    menuRef.current.scrollBy(-300, 0);
  };

  const getCounts = useSelector(getInteractionCount);
  return (
    <div>
      <div className="menu-wrapper">
        <div className="menu-item" ref={menuRef}>
          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Interest Sent" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Interest Sent");
              setTitleDiscription("Members you have interest ");
            }}
          >
            <Button type="button" as={Link}>
              Interested Profile {""}
              {getCounts?.interestedProfiles > 0 && (
                <span>({getCounts?.interestedProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Interested Received" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Interested Received");
              setTitleDiscription(" Members who sent has interest in you! ");
            }}
          >
            <Button type="button" as={Link}>
              Interested Received {""}
              {getCounts?.interestedRecieved > 0 && (
                <span>({getCounts?.interestedRecieved})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Accepted Profile" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Accepted Profile");
              setTitleDiscription("Members you have accepted ");
            }}
          >
            <Button type="button" as={Link}>
              Accepted Profile {""}
              {getCounts?.acceptedProfiles > 0 && (
                <span>({getCounts?.acceptedProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Accepted By Other" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Accepted By Other");
              setTitleDiscription("Members you have accepted! ");
            }}
          >
            <Button type="button" as={Link}>
              Accepted By Other {""}
              {getCounts?.acceptedByOtherProfiles > 0 && (
                <span>({getCounts?.acceptedByOtherProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Rejected Profile" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Rejected Profile");
              setTitleDiscription("Members who rejected you!");
            }}
          >
            <Button type="button" as={Link}>
              Rejected Profile {""}
              {getCounts?.rejectedProfiles > 0 && (
                <span>({getCounts?.rejectedProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Rejected By Other" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Rejected By Other");
              setTitleDiscription("Members Who have Rejected  you! ");
            }}
          >
            <Button type="button" as={Link}>
              Rejected by Other {""}
              {getCounts?.rejectedByOtherProfiles > 0 && (
                <span>({getCounts?.rejectedByOtherProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Shortlisted By Other" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Shortlisted By Other");
              setTitleDiscription("Members Who have Shortlisted you! ");
            }}
          >
            <Button type="button" as={Link}>
              Shortlisted By Other {""}
              {getCounts?.shortlistedMe > 0 && (
                <span>({getCounts?.shortlistedMe})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "My Shortlist" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("My Shortlist");
              setTitleDiscription("Members Who have Shortlisted by you! ");
            }}
          >
            <Button type="button" as={Link}>
              My Shortlist {""}
              {getCounts?.shortlistedByOther > 0 && (
                <span>({getCounts?.shortlistedByOther})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Who Visited My Profile" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Who Visited My Profile");
              setTitleDiscription("Members Visited! ");
            }}
          >
            <Button type="button" as={Link}>
              Who Visited {""}
              {getCounts?.visitedProfiles > 0 && (
                <span>({getCounts?.visitedProfiles})</span>
              )}
            </Button>
          </Link>

          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Hide Profile" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Hide Profile");
              setTitleDiscription("Members Hide! ");
            }}
          >
            <Button type="button" as={Link}>
              Hide Profile
            </Button>
          </Link>
          <Link
            to="#"
            className={`matches-buttons ${
              selectedTab === "Blocked Profile" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Blocked Profile");
              setTitleDiscription("Members Who Blocked! ");
            }}
          >
            <Button type="button" as={Link}>
              Blocked Profile
            </Button>
          </Link>
        </div>
        <span className="pointer left-pointer dis" onClick={scrollLeft}>
          <i className="fa fa-chevron-left"></i>
        </span>
        <span className="pointer right-pointer" onClick={scrollRight}>
          <i className="fa fa-chevron-right"></i>
        </span>
      </div>
    </div>
  );
}

export default SliderInteration;
