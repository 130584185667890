import { MDBCol, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../../Api/GeneralApi";
import DialyMatches from "../../../Home/RightSide/DialyMatches";
import NewMatches from "../../../Home/RightSide/NewMatches";
import { Button, Container } from "react-bootstrap";
import SuccessStories from "../../../Home/RightSide/SuccessStories";

const AgentRightSide = () => {
  const navigate = useNavigate();
  const [dialyMatches, setDialyMatches] = useState([]);
  const [newMatches, setNewMatches] = useState([]);
  const [successStories, setSuccessStories] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    const fetchHomeDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          document.cookie =
            "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          // Clear the local storage item (if you use it for storing the token)
          localStorage.removeItem("authToken");
          navigate("/login");
          return;
        }

        await api.getHome(token).then(({ data }) => {
          if (data.status) {
            setDialyMatches(data.data?.daily_matches);
            setNewMatches(data?.data?.all_matches);
            setSuccessStories(data?.data?.sucess_stories);
          } else {
            if (data.statusCode === 404) {
              setErrorMessage(data.message);
            } else if (data.statusCode === 401) {
              localStorage.clear();
              navigate("/login");
            }
          }
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    return () => {
      fetchHomeDetails();
    };
  }, []);

  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <DialyMatches dialyMatches={dialyMatches} />

     <NewMatches newMatches={newMatches} errorMessage={errorMessage} />


      
      <MDBListGroup className="lg-hid myhome">
        <MDBListGroupItem style={{ padding: "0%" }}>
          <img
            src="../Images/Groupbanner.png"
            className="img-fluid "
            alt="..."
            style={{ width: "100%" }}
          />
          <div className="cption">
            <Container>
              <div className="myhome-cap">
                <p className="smart">
                  Make the smart decision to find your perfect match faster.
                  Hurry
                </p>
                <p className="pack">
                  Get a flat Rs.2000 off on 3 months twin pack
                </p>
                <div className="premium">
                  <Button className="me-1 " as={Link} to="/Upgrade">
                    Get Premium{" "}
                  </Button>
                </div>
                <p>Offer Expires on 31 April 2022</p>
              </div>
            </Container>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
      {successStories && successStories.length > 0 ? (
        <SuccessStories successStories={successStories} />
      ) : (
        <></>
      )}

      <div className="need-help-all">
        <MDBListGroup className="lg-hid need-help">
          <MDBListGroupItem style={{ padding: "0%" }}>
            <img
              src="../Images/sucesskaliyanam.png"
              className="img-fluid "
              alt="..."
              style={{ width: "100%" }}
            />
            <div className="cption">
              <Container>
                <div className="myhome-service">
                  <p className="find-partner">
                    {" "}
                    Need help to find a life partner?
                  </p>

                  <p className="personalized">
                    Get personalized service from a dedicated <br></br>
                    Relationship Manager
                  </p>
                </div>
              </Container>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </div>
    </MDBCol>
  );
};

export default AgentRightSide;