import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

import { Select, Space } from "antd";
import "antd/dist/reset.css";
const { Option } = Select;

const PreferenceMatches = ({
  userData,
  listHeights,
  occupationList,
  stateAndDistricts,
  listStars,
  annualIncomeList,
  religionCaste,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  const [selectedReligions, setSelectedReligions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [selectedCastes, setSelectedCastes] = useState([]);
  const [selectedReligionIds, setSelectedReligionIds] = useState([]);
  const [selectedCasteIds, setSelectedCasteIds] = useState([]);

  useEffect(() => {
    if (selectedReligions.includes("0")) {
      setCasteOptions([]);
      setSelectedCastes([]);
      setSelectedCasteIds([0]);
      setSelectedReligionIds([0]);
    } else if (religionCaste) {
      const newCasteOptions = religionCaste
        .filter((rel) => selectedReligions.includes(rel.religion))
        .flatMap((rel) => rel.caste)
        .map((caste) => ({
          label: caste.caste,
          value: caste.id,
        }));
      setCasteOptions(newCasteOptions);
      const validCastes = newCasteOptions.map((caste) => caste.value);
      setSelectedCastes((prevCastes) =>
        prevCastes.filter((caste) => validCastes.includes(caste))
      );
      setSelectedCasteIds((prevCastes) =>
        prevCastes.filter((caste) => validCastes.includes(caste))
      );
    }
  }, [selectedReligions, religionCaste]);

  const handleReligionChange = (values) => {
    if (values.includes("0")) {
      // Assuming "0" is the value for "Any"
      setSelectedReligions(["0"]); // Select "Any" and clear other selections
      setSelectedReligionIds([0]);
      setCasteOptions([]); // Clear caste options if "Any" is selected
      setSelectedCastes([]);
      setSelectedCasteIds([0]);
    } else {
      setSelectedReligions(values);
      const religionIdMap = religionCaste.reduce((acc, rel) => {
        acc[rel.religion] = rel.id;
        return acc;
      }, {});
      setSelectedReligionIds(values.map((religion) => religionIdMap[religion]));
    }
  };

  const handleCasteChange = (values) => {
    setSelectedCastes(values);
    setSelectedCasteIds(values);
  };

  const [preferenceMatches, setPreferenceMatches] = useState({
    min_age: 18,
    max_age: 18,
    min_height: "",
    max_height: "",
    minIncome: "",
    maxIncome: "",
    marital_status: "",
    drinking_habit: "",
    eating_habit: "",
    physical_status: "",
    smoking_habit: "",
    professional_status: "",
    professional_status_id: "",
    min_income: "",
    max_income: "",
    min_income_id: "",
    max_income_id: "",
    selectedReligions: [],
    selectedCastes: [],
  });

  const [isEditingPreferenceMatches, setIsEditingPreferenceMatches] =
    useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStars, setSelectedStars] = useState([]);

  const [selectedProfessions, setSelectedProfessions] = useState([]);

  const selectedOccupation = occupationList.find(
    (occupation) =>
      occupation.occupation == userData?.partner_professional_status
  );

  const selectedAnnual_min = annualIncomeList.find(
    (annual_income) =>
      annual_income.annual_income == userData?.partner_min_income
  );
  const selectedAnnual_max = annualIncomeList.find(
    (annual_income) =>
      annual_income.annual_income == userData?.partner_max_income
  );

  useEffect(() => {
    // Initialize the form values based on `userData`
    const initialReligions =
      userData?.partner_religion?.map((rel) => rel.religion) || [];
    const initialReligionIds =
      userData?.partner_religion?.map((rel) => rel.id) || [];

    const initialCasteIds =
      userData?.partner_caste?.map((caste) => caste.id) || [];
    const initialCastes =
      userData?.partner_caste?.map((caste) => caste.caste) || [];

    setSelectedReligions(initialReligions);
    setSelectedReligionIds(initialReligionIds);

    setSelectedCastes(initialCastes);
    setSelectedCasteIds(initialCasteIds);

    setPreferenceMatches({
      ...preferenceMatches,
      selectedReligions: initialReligions,
      selectedCastes: initialCastes,
      min_age: userData?.partner_min_age,
      max_age: userData?.partner_max_age,
      min_height: userData?.partner_min_height,
      max_height: userData?.partner_max_height,
      minIncome: userData?.partner_min_income,
      maxIncome: userData?.partner_max_income,
      marital_status: userData?.partner_marital_status,
      drinking_habit: userData?.partner_drinking_habit,
      eating_habit: userData?.partner_eating_habit,
      physical_status: userData?.partner_physical_status,
      smoking_habit: userData?.partner_smoking_habit,

      min_income: userData?.partner_min_income,
      max_income: userData?.partner_max_income,
      min_income_id: selectedAnnual_min ? selectedAnnual_min?.id : "",
      max_income_id: selectedAnnual_max ? selectedAnnual_max?.id : "",
    });
    if (userData?.partner_location && userData?.partner_location.length > 0) {
      const selectd = userData?.partner_location.map((item) => item.id);
      setSelectedLocations(selectd);
    }
    if (userData?.partner_star && userData?.partner_star.length > 0) {
      const selectd = userData?.partner_star.map((item) => item.id);
      setSelectedStars(selectd);
    }
    if (
      userData?.partner_professional_status &&
      userData?.partner_professional_status.length > 0
    ) {
      const selectd = userData?.partner_professional_status.map(
        (item) => item.id
      );
      setSelectedProfessions(selectd);
    }
  }, [userData]);

  const handleEditClickPartner = () => {
    setIsEditingPreferenceMatches(true);
  };

  useEffect(() => {
    console.log("selectedReligions :", selectedReligions);
  }, [selectedReligions]);

  useEffect(() => {
    console.log("Selected Religions after update:", selectedReligions);
  }, [selectedReligions]);

  useEffect(() => {
    console.log("Preference Matches:", preferenceMatches.selectedReligions);
  }, [preferenceMatches]);

  useEffect(() => {
    if (stateAndDistricts) {
      const allLocations = stateAndDistricts[0]?.districts || [];
      setLocations(allLocations);
    }
  }, [stateAndDistricts]);

  const handleSaveClickPartner = async () => {
    const payload = {
      age_from: preferenceMatches?.min_age,
      age_to:
        preferenceMatches?.max_age === "any"
          ? "Any"
          : preferenceMatches?.max_age,
      height_from: preferenceMatches?.min_height,
      height_to: preferenceMatches?.max_height,
      income_from: preferenceMatches?.minIncome,
      income_to: preferenceMatches?.maxIncome,
      matrial_status: preferenceMatches?.marital_status,
      eating_habit: preferenceMatches?.eating_habit,
      drinking_habit: preferenceMatches?.drinking_habit,
      smoking_habit: preferenceMatches?.smoking_habit,
      physical_status: preferenceMatches?.physical_status,
      selectedLocation: [...selectedLocations],
      selectedStar: [...selectedStars],
      selectedProfession: [...selectedProfessions],
      selectedReligions:
        selectedReligionIds.length > 0 ? selectedReligionIds : [0],
      selectedCastes: selectedCasteIds.length > 0 ? selectedCasteIds : [0],
    };

    try {
      const data = await api.updatePreferMatches(payload, token);
      if (data.status) {
        setPreferenceMatches((prevState) => ({
          ...prevState,
          selectedReligions: selectedReligions.map((rel) =>
            typeof rel === "string" ? rel.trim() : rel
          ),
          selectedCastes: selectedCasteIds
            .map((casteId) => {
              const caste = casteOptions.find(
                (option) => option.value === casteId
              );
              return caste ? caste.label : "";
            })
            .filter((caste) => caste), // Filter out any empty strings
        }));
        setIsEditingPreferenceMatches(false);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  const handleSelectLocation = (value) => {
    const findLocation = selectedLocations.find((item) => item === value);
    let newLocation = [];
    if (findLocation) {
      const filteredLocation = selectedLocations.filter(
        (location) => location !== value
      );
      newLocation = [...filteredLocation];
    } else {
      newLocation = [...selectedLocations, value];
    }
    setSelectedLocations([...newLocation]);
  };

  const handleSelectStar = (value) => {
    const findStar = selectedStars.find((item) => item === value);
    let newStar = [];
    if (findStar) {
      const filteredStar = selectedStars.filter((star) => star !== value);
      newStar = [...filteredStar];
    } else {
      newStar = [...selectedStars, value];
    }
    setSelectedStars([...newStar]);
  };

  const handleSelectProfession = (value) => {
    const findProfession = selectedProfessions.find((item) => item === value);
    let newProfessions = [];
    if (findProfession) {
      const filteredProfessions = selectedProfessions.filter(
        (profession) => profession !== value
      );
      newProfessions = [...filteredProfessions];
    } else {
      newProfessions = [...selectedProfessions, value];
    }
    setSelectedProfessions([...newProfessions]);
  };

  return (
    <>
      <p className="partner-preference-profileedit">Partner Preferences</p>
      <div className="importentdetails-editprofile">
        <MDBRow>
          <div className="user-description">
            <p className="profile-description">Basic Details</p>
            <div className="editing-religious-save">
              {isEditingPreferenceMatches ? (
                <button onClick={handleSaveClickPartner}>Save</button>
              ) : (
                <button onClick={handleEditClickPartner}>
                  <MDBIcon far icon="edit" /> Edit
                </button>
              )}
            </div>
          </div>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Age From</div>:
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="ageFrom"
                      name="ageFrom"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.min_age || ""}
                      onChange={(e) => {
                        const selectedAgeFrom =
                          e.target.value === "any"
                            ? "any"
                            : parseInt(e.target.value);
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          min_age: selectedAgeFrom,
                          max_age:
                            selectedAgeFrom === "any"
                              ? "any"
                              : selectedAgeFrom >= prev.max_age
                              ? selectedAgeFrom + 1
                              : prev.max_age,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {Array.from({ length: 83 }, (_, index) => (
                        <option key={index} value={18 + index}>
                          {18 + index} years
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Age To</div>:
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="ageTo"
                      name="ageTo"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.max_age || ""}
                      onChange={(e) => {
                        const selectedAgeTo =
                          e.target.value === "any"
                            ? "any"
                            : parseInt(e.target.value);
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          max_age: selectedAgeTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {preferenceMatches?.min_age !== "any" &&
                        Array.from(
                          {
                            length:
                              60 - (parseInt(preferenceMatches?.min_age) || 18),
                          },
                          (_, index) => (
                            <option
                              key={index}
                              value={
                                (parseInt(preferenceMatches?.min_age) || 18) +
                                1 +
                                index
                              }
                            >
                              {(parseInt(preferenceMatches?.min_age) || 18) +
                                1 +
                                index}{" "}
                              years
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Age From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.min_age || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Age To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.max_age || "Not defined"}
                  </div>
                </div>
              </>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Marital Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="maritalStatus"
                    name="maritalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.marital_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        marital_status: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="Marital Status">
                      {" "}
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never Married">Never Married</option>
                    <option value="widowed">widowed</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Seperated">Seperated</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Marital Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.marital_status || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Eating Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="eating"
                    name="eating"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.eating_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        eating_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      {" "}
                      Select
                    </option>

                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non vegetarian">Non vegetarian</option>
                    <option value="Eggetarian">Eggetarian</option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Eating Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.eating_habit || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Smoking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="smoke"
                    name="smoke"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.smoking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        smoking_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      {" "}
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never smokes">Never smokes</option>
                    <option value="Smoke occasionally">
                      Smoke occasionally
                    </option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Smoking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.smoking_habit || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Driking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="drink"
                    name="drink"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.drinking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        drinking_habit: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Drinks socially">Drinks socially</option>
                    <option value="Never drinks">Never drinks</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Driking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.drinking_habit || "Not defined"}
                </div>
              </div>
            )}
          </MDBCol>

          <MDBCol size="6" sm="6" className="partner-profileedit">
            {/* HEIGHT */}
            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Height From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="height_from"
                      name="height_from"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.min_height || ""}
                      onChange={(e) => {
                        const selectedHeightFrom =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          min_height: selectedHeightFrom,
                          max_height:
                            selectedHeightFrom === "any"
                              ? "any"
                              : prev.max_height !== "any" &&
                                selectedHeightFrom >= prev.max_height
                              ? ""
                              : prev.max_height,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {listHeights.map((height) => (
                        <option
                          key={height.id}
                          value={`${height.height_cm} cm`}
                        >
                          {`${height.height_cm} cm / ${height.height_feet}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Height To
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="height_to"
                      name="height_to"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.max_height || ""}
                      onChange={(e) => {
                        const selectedHeightTo =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          max_height: selectedHeightTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {preferenceMatches?.min_height === "any" ? (
                        <option key="any" value="any">
                          Any
                        </option>
                      ) : (
                        <>
                          <option key="any" value="any">
                            Any
                          </option>
                          {listHeights
                            .filter(
                              (height) =>
                                parseInt(height.height_cm) >
                                parseInt(preferenceMatches?.min_height)
                            )
                            .map((height) => (
                              <option
                                key={height.id}
                                value={`${height.height_cm} cm`}
                              >
                                {`${height.height_cm} cm / ${height.height_feet}`}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Height From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.min_height || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Height To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.max_height || "Not defined"}
                  </div>
                </div>
              </>
            )}

            {/* PHYSICAL STATUS */}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Physical Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="physicalStatus"
                    name="physicalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.physical_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        physical_status: e.target.value,
                      });
                    }}
                  >
                    <option value="" disable>
                      Select
                    </option>

                    <option value="Normal">Normal</option>
                    <option value="Physically Challenged">
                      Physically Challenged
                    </option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Physical Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.physical_status || "Not defined"}
                </div>
              </div>
            )}

            {/*  test annual income  */}

            {isEditingPreferenceMatches ? (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Annual Income From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="income_from"
                      name="income_from"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.minIncome || ""}
                      onChange={(e) => {
                        const selectedHeightFrom =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          minIncome: selectedHeightFrom,
                          maxIncome:
                            selectedHeightFrom === "any"
                              ? "any"
                              : prev.max_height !== "any" &&
                                selectedHeightFrom >= prev.maxIncome
                              ? ""
                              : prev.maxIncome,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      <option key="any" value="any">
                        Any
                      </option>
                      {annualIncomeList.map((height) => (
                        <option
                          key={height.id}
                          value={`${height.annual_income}`}
                        >
                          {`${height.annual_income} `}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Annual Income From
                  </div>
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <select
                      id="income_to"
                      name="income_to"
                      className="SelectBoxs-searchpage-profileedit"
                      value={preferenceMatches?.maxIncome || ""}
                      onChange={(e) => {
                        const selectedHeightTo =
                          e.target.value === "any" ? "any" : e.target.value;
                        setPreferenceMatches((prev) => ({
                          ...prev,
                          maxIncome: selectedHeightTo,
                        }));
                      }}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {preferenceMatches?.minIncome === "any" ? (
                        <option key="any" value="any">
                          Any
                        </option>
                      ) : (
                        <>
                          <option key="any" value="any">
                            Any
                          </option>
                          {annualIncomeList
                            .filter(
                              (height) =>
                                parseInt(height.annual_income) >
                                parseInt(preferenceMatches?.minIncome)
                            )
                            .map((height) => (
                              <option
                                key={height.id}
                                value={`${height.annual_income}`}
                              >
                                {`${height.annual_income}`}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Annual Income From
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.minIncome || "Not defined"}
                  </div>
                </div>
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="full-bolder">
                    Annual Income To
                  </div>
                  <div style={{ flex: 1 }}>
                    :{""} {preferenceMatches?.maxIncome || "Not defined"}
                  </div>
                </div>
              </>
            )}
          </MDBCol>

          {/* Religion */}
          <MDBRow>
            <MDBCol size="6" sm="6" className="partner-profileedit">
              {isEditingPreferenceMatches ? (
                <div className="annual-income" style={{ display: "flex" }}>
                  <div style={{ flex: 1 }} className="">
                    Religion
                  </div>{" "}
                  :
                  <div className="basic-profiles" style={{ flex: 1 }}>
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select religions"
                      value={selectedReligions}
                      onChange={handleReligionChange}
                      options={[
                        { label: "Any", value: "0" },
                        ...religionCaste.map((rel) => ({
                          label: rel.religion,
                          value: rel.religion,
                          disabled: selectedReligions.includes("0"),
                        })),
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="annual-income" style={{ display: "flex" }}>
                    <div style={{ flex: 1 }} className="full-bolder">
                      Religion
                    </div>
                    <div style={{ flex: 1 }}>
                      :{" "}
                      {preferenceMatches.selectedReligions.length === 0
                        ? "Not defined"
                        : preferenceMatches.selectedReligions
                            .map((rel) =>
                              typeof rel === "string" ? rel : rel.religion
                            )
                            .join(", ")}
                    </div>
                  </div>
                </>
              )}
            </MDBCol>
          </MDBRow>

          {/* Caste */}
          <MDBRow>
            <MDBCol size="8" sm="8" className="partner-profileedit">
              {isEditingPreferenceMatches
                ? selectedReligions.length > 0 &&
                  !selectedReligions.includes("0") && (
                    <div className="annual-income" style={{ display: "flex" }}>
                      <div style={{ flex: 0.3 }} className="">
                        Caste
                      </div>{" "}
                      :
                      <div className="basic-profiles" style={{ flex: 1 }}>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select castes"
                          value={selectedCastes}
                          onChange={handleCasteChange}
                          options={casteOptions}
                        />
                      </div>
                    </div>
                  )
                : preferenceMatches.selectedReligions.length > 0 &&
                  !preferenceMatches.selectedReligions
                    .map((rel) => rel.religion)
                    .includes("Any") && (
                    <div className="annual-income" style={{ display: "flex" }}>
                      <div style={{ flex: 0.3 }} className="full-bolder">
                        Caste
                      </div>
                      <div style={{ flex: 1 }}>
                        :{""}{" "}
                        {preferenceMatches.selectedCastes.length === 0
                          ? "Not defined"
                          : preferenceMatches.selectedCastes
                              .map((rel) =>
                                typeof rel === "string" ? rel : rel.caste
                              )
                              .join(", ")}
                      </div>
                    </div>
                  )}
            </MDBCol>
          </MDBRow>

          {/* LOCATION */}
          {selectedLocations.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Locations</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {locations.map((location, index) => {
                  const isSelected = selectedLocations.includes(
                    location.dist_id
                  );
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectLocation(location?.dist_id)}
                      >
                        {location?.district}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedLocations.map((locationId, index) => {
                  const location = locations.find(
                    (item) => item.dist_id === locationId
                  );
                  return location ? (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">{location.district}</label>
                    </li>
                  ) : null;
                })}
              </>
            )}
          </ul>

          {/* STAR */}

          {selectedStars.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Stars</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {listStars.map((star, index) => {
                  const findStar = selectedStars.find(
                    (item) => item === star.id
                  );
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectStar(star?.id)}
                      >
                        {star?.star}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedStars.map((star, index) => {
                  const findStar = listStars.find((item) => item.id === star);
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">{findStar?.star}</label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>

          {/* PROFESSIONAL STATUS */}
          {selectedProfessions.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Professional Status</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {occupationList.map((occupation, index) => {
                  const findProfession = selectedProfessions.find(
                    (item) => item === occupation.id
                  );
                  let isSelected = false;
                  if (findProfession) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectProfession(occupation?.id)}
                      >
                        {occupation.occupation}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedProfessions.map((professionId, index) => {
                  const findProfession = occupationList.find(
                    (item) => item.id === professionId
                  );
                  let isSelected = false;
                  if (findProfession) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => {}}
                      />
                      <label htmlFor={`profession_${findProfession?.id}`}>
                        {findProfession?.occupation}
                      </label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </MDBRow>
      </div>
    </>
  );
};

export default PreferenceMatches;
