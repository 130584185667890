import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const AgentProfessionalDetails = ({
  userData,
  registerData,
  setRegisterData,
  handleRegisterData,
  educationList,
  employedInList,
  occupationList,
  annualIncomeList,
  register,
}) => {
  const [courseList, setCourseList] = useState([]);
  const [professional_details, setProfessionalDetails] = useState({
    education_id: "",
    education: "",
    course: "",
    course_id: "",
    qualification: "",
    employed_in_id: "",
    employed_in: "",
    occupation: "",
    occupation_id: "",
    annual_income: "",
    annual_income_id: "",
  });
  const [isEditingProfessional, setIsEditingProfessional] = useState(true);
  //   useEffect(() => {
  //     const selectedEducation = educationList.find(
  //       (education) =>
  //         education.main_qualification == userData?.qualification_main
  //     );

  //     let course_data = "";

  //     if (selectedEducation) {
  //       const selectedCourse = selectedEducation.sub_qualification.find(
  //         (course) => course.sub_qualification == userData?.qualification_sub
  //       );

  //       setCourseList([...selectedEducation.sub_qualification]);
  //       if (selectedCourse) {
  //         course_data = selectedCourse;
  //       }
  //     }

  //     const selectedEmp = employedInList.find(
  //       (employed_in) => employed_in.employed_in == userData?.employed_in
  //     );

  //     const selectedOccupation = occupationList.find(
  //       (occupation) => occupation.occupation == userData?.occupation
  //     );

  //     const selectedAnnual = annualIncomeList.find(
  //       (annual_income) => annual_income.annual_income == userData?.annual_income
  //     );

  //     setProfessionalDetails({
  //       education_id: selectedEducation ? selectedEducation?.id : "",
  //       education: userData?.qualification_main,
  //       course_id: course_data?.id,
  //       course: userData?.qualification_sub,
  //       employed_in_id: selectedEmp ? selectedEmp?.id : "",
  //       employed_in: userData?.employed_in,
  //       occupation: userData?.occupation,
  //       occupation_id: selectedOccupation ? selectedOccupation?.id : "",
  //       annual_income: userData?.annual_income,
  //       annual_income_id: selectedAnnual ? selectedAnnual?.id : "",
  //     });
  //   }, [userData]);

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Professional Details</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Education
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="education"
                name="education_main"
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.education_main}
                {...register("education_main")}
                onChange={(e) => {
                  const selectedEducationId = e.target.value;

                  if (selectedEducationId) {
                    const selectedEducation = educationList.find(
                      (education) => education.id == selectedEducationId
                    );
                    setRegisterData((prevValue) => ({
                      ...prevValue,
                      education_main: selectedEducationId,
                      education_sub: "",
                    }));
                    setCourseList([...selectedEducation.sub_qualification]);
                  } else {
                    setRegisterData((prevValue) => ({
                      ...prevValue,
                      education_main: selectedEducationId,
                      education_sub: "",
                    }));
                    setCourseList([]);
                  }
                }}
              >
                <option key="" value="">
                  Select
                </option>
                {educationList.map((education) => (
                  <option key={education.id} value={education.id}>
                    {education.main_qualification}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Highest Education
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.education}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Qualification
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="course"
                name="education_sub"
                className="SelectBoxs-searchpage-profileedit"
                {...register("education_sub")}
                value={registerData?.education_sub}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>
                {courseList.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.sub_qualification}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Course
            </div>
            <div style={{ flex: 1 }}>
              :{""} {professional_details?.course}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Employed in
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="employedin"
                name="employed_in"
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.employed_in}
                {...register("employed_in")}
                onChange={handleRegisterData}
              >
                <option value="">Select</option>

                {/* Remove the selected attribute from here */}
                {employedInList.map((employeed_in) => (
                  <option key={employeed_in.id} value={employeed_in?.id}>
                    {employeed_in?.employed_in}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Employed in
            </div>
            <div style={{ flex: 1 }}>: {registerData?.employed_in}</div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Occupation
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="occupation"
                name="occupation"
                className="SelectBoxs-searchpage-profileedit"
                {...register("occupation")}
                style={{ width: 350 }}
                value={registerData?.occupation}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>
                {occupationList.map((occupation) => (
                  <option key={occupation?.id} value={occupation?.id}>
                    {occupation.occupation}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Occupation
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.occupation}
            </div>
          </div>
        )}
        {isEditingProfessional ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Annual income
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="annualincome"
                name="annual_income"
                className="SelectBoxs-searchpage-profileedit"
                {...register("annual_income")}
                value={registerData?.annual_income}
                onChange={handleRegisterData}
              >
                <option key="" value="">
                  Select
                </option>
                {annualIncomeList.map((annual_income) => (
                  <option key={annual_income.id} value={annual_income.id}>
                    {annual_income.annual_income}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Annual income
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.annual_income}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default AgentProfessionalDetails;
