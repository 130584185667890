import {
  and,
  collection,
  getFirestore,
  onSnapshot,
  or,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firebaseApp } from "../../firebase/firebase";
import Chat from "../Chat";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/features/profile";

const History = ({ setChatData }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const getUserDetails = useSelector(getProfileDetails);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const unSub = onSnapshot(
      query(
        collection(firebaseDB, "lastMessages"),
        and(
          where("status", "==", "ACCEPTED"),
          or(
            where("receiverId", "==", getUserDetails?.profile_id),
            where("senderId", "==", getUserDetails?.profile_id)
          )
        )
      ),
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) =>  new Date(b.time) - new Date(a.time));
        setMessages(data);
      }
    );

    return () => {
      unSub();
    };
  }, []);

  const onReply = (user_id, user_name, user_image) => {
    setChatData({
      status: true,
      userId: user_id,
      name: user_name,
      image: user_image,
      from: "",
    });
  };

  return (
    <>
      {messages.length > 0 ? (
        <>
          {messages.map((msg, index) => (
            <MDBRow className="client-format" key={index}>
              <MDBCol size="6" sm="3" className="client-images">
                <a
                  href={`/profile-details/${
                    getUserDetails?.profile_id === msg?.senderId
                      ? msg?.receiverId
                      : msg?.receiverId
                  }`}
                >
                  <img
                    src={
                      getUserDetails?.profile_id === msg?.senderId
                        ? msg?.receiverImage
                          ? msg?.receiverImage
                          : "default-photo-url.png"
                        : msg?.senderImage
                        ? msg?.senderImage
                        : "default-photo-url.png"
                    }
                    width={150}
                    alt={`msgimg${index}`}
                  />
                </a>
              </MDBCol>
              <MDBCol size="6" sm="9" className="client-alldetails">
                <p>
                  {getUserDetails?.profile_id === msg?.senderId ? (
                    <span className="text-capitalize">
                      {msg?.receiverName} (
                      <a
                        href={`/profile-details/${msg?.receiverId}`}
                        style={{ color: "#F39200" }}
                      >
                        {msg?.receiverId}
                      </a>
                      )
                    </span>
                  ) : (
                    <>
                      <span className="text-capitalize">
                        {msg?.senderName} (
                        <a
                          href={`/profile-details/${msg?.senderId}`}
                          style={{ color: "#F39200" }}
                        >
                          {msg?.senderId}
                        </a>
                        )
                      </span>{" "}
                      sent you a message!
                    </>
                  )}
                </p>
                <p className="send-details"></p>
                <p className="client-message">{msg?.message}</p>
                <hr />
                <p className="client-intraction">
                  {getUserDetails?.profile_id === msg?.senderId
                    ? "Do you want to continue to the message?"
                    : "Do you want to reply to the message?"}
                  <button
                    className="client-replay"
                    onClick={() =>
                      onReply(
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverId
                          : msg?.senderId,
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverName
                          : msg?.senderName,
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverImage
                          : msg?.senderImage
                      )
                    }
                  >
                    {getUserDetails?.profile_id === msg?.senderId
                      ? "Message"
                      : "Reply"}
                  </button>
                </p>
              </MDBCol>
            </MDBRow>
          ))}
        </>
      ) : (
        <p className="nomessages">
          You don't have any new messages.
        </p>
      )}
      {messages.map((msg, index) => (
        <div className="intrest_container mobile_intrest" key={index}>
          <div className="mobleft">
            <div className="left_img">
              <div className="img_new">
                <div className="message-mobileresponse">
                <div  style={{ position: "relative"}}>
                  <a
                    href={`/profile-details/${
                      getUserDetails?.profile_id === msg?.senderId
                        ? msg?.receiverId
                        : msg?.receiverId
                    }`}
                  >
                    <img
                      src={
                        getUserDetails?.profile_id === msg?.senderId
                          ? msg?.receiverImage
                            ? msg?.receiverImage
                            : "default-photo-url.png"
                          : msg?.senderImage
                          ? msg?.senderImage
                          : "default-photo-url.png"
                      }
                      width={150}
                      alt={`msgimg${index}`}
                    />
                  </a>
                </div>
                </div>
              </div>
            </div>
            <div className="message-profiles">
            <div className="right_details" style={{ color: "#F39200" }}>
              <p>
                {getUserDetails?.profile_id === msg?.senderId ? (
                  <span className="text-capitalize">
                    {msg?.receiverName} (
                    <a
                      href={`/profile-details/${msg?.receiverId}`}
                      style={{ color: "#F39200" }}
                    >
                      {msg?.receiverId}
                    </a>
                    )
                  </span>
                ) : (
                  <>
                    <span className="text-capitalize">
                      {msg?.senderName} (
                      <a
                        href={`/profile-details/${msg?.senderId}`}
                        style={{ color: "#F39200" }}
                      >
                        {msg?.senderId}
                      </a>
                      )
                    </span>{" "}
                    sent you a message!
                  </>
                )}
              </p>
              <p className="send-details"></p>
              <p className="client-message">{msg?.message}</p>
            </div>
            </div>
          </div>
          <hr></hr>
          <p className="client-intraction">
            {getUserDetails?.profile_id === msg?.senderId
              ? "Do you want to continue to the message?"
              : "Do you want to reply to the message?"}
            <button
              className="client-replay"
              onClick={() =>
                onReply(
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverId
                    : msg?.senderId,
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverName
                    : msg?.senderName,
                  getUserDetails?.profile_id === msg?.senderId
                    ? msg?.receiverImage
                    : msg?.senderImage
                )
              }
            >
              {getUserDetails?.profile_id === msg?.senderId
                ? "Message"
                : "Reply"}
            </button>
          </p>
        </div>
      ))}
    </>
  );
};

export default History;
