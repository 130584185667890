import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const FamilyDetails = ({ userData, stateAndDistricts }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [familyDetails, setFamilyDetails] = useState({
    family_value: "",
    father_name: "",
    father_occupation: "",
    mother_name: "",
    mother_occupation: "",
    no_brothers: "",
    no_sisters: "",
    family_type: "",
    family_status: "",
    family_location: "",
  });
  const [locations, setLocations] = useState([]);
  const [isEditingFamily, setIsEditingFamily] = useState(false);

  useEffect(() => {
    setFamilyDetails({
      family_value: userData?.family_value,
      father_name: userData?.father_name === "Not Defined" ? "" : userData?.father_name,
      father_occupation: userData?.father_occupation,
      mother_name: userData?.mother_name === "Not Defined" ? "" : userData?.mother_name,
      mother_occupation: userData?.mother_occupation,
      no_brothers: userData?.no_of_brothers,
      no_sisters: userData?.no_of_sisters,
      family_type: userData?.family_type,
      family_status: userData?.family_status,
      family_location: userData?.family_location,
    });
  }, [userData]);

  useEffect(() => {
    if (stateAndDistricts.length > 0) {
      const districts = stateAndDistricts
        ? [...stateAndDistricts[0].districts]
        : [];
      setLocations([...districts]);
    }
  }, [stateAndDistricts]);

  const handleEditClickFamily = () => {
    setIsEditingFamily(true);
  };

  const handleFatherNameChange = (e) => {
    let value = e.target.value;
    // Remove non-alphabet characters and limit the input to 20 characters
    value = value.replace(/[^A-Za-z ]/g, "").slice(0, 20);
    setFamilyDetails({
      ...familyDetails,
      father_name: value,
    });
  };

  const handleMotherNameChange = (e) => {
    let value = e.target.value;
    // Remove non-alphabet characters and limit the input to 20 characters
    value = value.replace(/[^A-Za-z ]/g, "").slice(0, 20);
    setFamilyDetails({
      ...familyDetails,
      mother_name: value,
    });
  };

  const handleSaveClickFamily = async () => {
    const payload = {
      family_value: familyDetails?.family_value,
      family_status: familyDetails?.family_status,
      father_name: familyDetails?.father_name,
      mother_name: familyDetails?.mother_name,
      father_occupation: familyDetails?.father_occupation,
      mother_occupation: familyDetails?.mother_occupation,
      no_brothers: familyDetails?.no_brothers,
      no_sisters: familyDetails?.no_sisters,
      family_type: familyDetails?.family_type,
      family_location: familyDetails?.family_location,
    };
    await api.updateFamilyDetails(payload, token).then((data) => {
      if (data.status) {
        setIsEditingFamily(false);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    });
  };
  return (
    <MDBRow>
       <div className="user-description">
      <p className="profile-description">Family Details</p>
      <div className="editing-religious">
        {isEditingFamily ? (
          <button onClick={handleSaveClickFamily}>Save</button>
        ) : (
          <button onClick={handleEditClickFamily}>
            <MDBIcon far icon="edit" /> Edit
          </button>
        )}
      </div>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingFamily ? (
          <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family value
          </div>:
          <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="family_value"
            name="family_value"
            className="SelectBoxs-searchpage-profileedit"
            style={{ width: 350 }}
            value={familyDetails?.family_value}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                family_value: e.target.value,
              });
            }}
          >
            <option value=""> Select</option>
            <option value="Orthodox">Orthodox</option>
            <option value="Moderate">Moderate</option>
            <option value="Traditional">Traditional</option>
            <option value="Liberal">Liberal</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Family value
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.family_value || "Not defined"}
            </div>
          </div>
        )}
        {isEditingFamily ? (
          <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Father Name
          </div>:
          <div className="basic-profiles" style={{ flex: 1 }}>
          <input
            type="text"
            id="fathername"
            name="fathername"
            className="TextInput-searchpage-profileedit"
            placeholder="Enter Father name"
            value={familyDetails?.father_name}
            onChange={handleFatherNameChange}
          />
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Father Name
            </div>
            <div style={{ flex: 1 }}>
              :{" "}
              {familyDetails.father_name
                ? familyDetails.father_name
                : "Father Name"}
            </div>
          </div>
        )}

        {isEditingFamily ? (
           <div className="annual-income" style={{ display: "flex" }}>
           <div style={{ flex: 1 }} className="">
             Father's Occupation
           </div>:
           <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="fatheroccupation"
            name="fatheroccupation"
            className="SelectBoxs-searchpage-profileedit"
           
            value={familyDetails?.father_occupation}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                father_occupation: e.target.value,
              });
            }}
          >
            <option value="">Select</option>
            <option value="Goverment">Goverment</option>
            <option value="Business">Business</option>
            <option value="Defence">Defence</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Private">Private</option>
            <option value="Not Working">Not Working</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Father's Occupation
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.father_occupation || "Not defined"}
            </div>
          </div>
        )}

        {isEditingFamily ? (
           <div className="annual-income" style={{ display: "flex" }}>
           <div style={{ flex: 1 }} className="">
             No. of Brothers
           </div>:
           <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="no_brothers"
            name="no_brothers"
            className="SelectBoxs-searchpage-profileedit"
            style={{ width: 350 }}
            value={familyDetails?.no_brothers}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                no_brothers: e.target.value,
              });
            }}
          >
            <option value="">
              Select
            </option>
            <option value="0">None</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              No. of Brothers
            </div>
            <div style={{ flex: 1 }}>
              : {""} {familyDetails?.no_brothers || "Not defined"}
            </div>
          </div>
        )}
        {isEditingFamily ? (
          <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Family Type
          </div>:
          <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="family_type"
            name="family_type"
            className="SelectBoxs-searchpage-profileedit"
            style={{ width: 350 }}
            value={familyDetails?.family_type}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                family_type: e.target.value,
              });
            }}
          >
            <option value="">Select </option>
            <option value="Nuclear">Nuclear</option>
            <option value="Joint">Joint</option>
          </select>
          </div>
          </div>
          

        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Family Type
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.family_type || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingFamily ? (
           <div className="annual-income" style={{ display: "flex" }}>
           <div style={{ flex: 1 }} className="">
             Family Status
           </div>:
           <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="family_status"
            name="family_status"
            className="SelectBoxs-searchpage-profileedit"
          
            value={familyDetails?.family_status}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                family_status: e.target.value,
              });
            }}
          >
            <option value=""> Select</option>
            <option value="Rich Class">Rich Class</option>
            <option value="Upper Class">Upper Class</option>
            <option value="Middle Class">Middle Class</option>
            <option value="Lower Class">Lower Class</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Family Status
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.family_status || "Not defined"}
            </div>
          </div>
        )}

        {isEditingFamily ? (
           <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Mother Name
            </div>:
            <div className="basic-profiles" style={{ flex: 1 }}>
          <input
            type="text"
            id="mothername"
            name="mothername"
            className="TextInput-searchpage-profileedit"
            placeholder="Enter Mother name"
            value={familyDetails?.mother_name}
            onChange={handleMotherNameChange}
          />
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Mother Name
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.mother_name || "Not defined"}
            </div>
          </div>
        )}

        {isEditingFamily ? (
          <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Mother's Occupation
          </div>:
          <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="motheroccupation"
            name="motheroccupation"
            className="SelectBoxs-searchpage-profileedit"
            style={{ width: 350 }}
            value={familyDetails?.mother_occupation}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                mother_occupation: e.target.value,
              });
            }}
          >
            <option value=""> Select</option>
            <option value="Goverment">Goverment</option>
            <option value="Business">Business</option>
            <option value="Defence">Defence</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Private">Private</option>
            <option value="House Wife">House Wife</option>
            <option value="Not Working">Not Working</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Mother's Occupation
            </div>
            <div style={{ flex: 1 }}>
              :{""} {familyDetails?.mother_occupation || "Not defined"}
            </div>
          </div>
        )}
        {isEditingFamily ? (
          <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            No. of Sisters
          </div>:
          <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="no_sisters"
            name="no_sisters"
            className="SelectBoxs-searchpage-profileedit"
          
            value={familyDetails?.no_sisters}
            onChange={(e) => {
              setFamilyDetails({
                ...familyDetails,
                no_sisters: e.target.value,
              });
            }}
          >
            <option value="">Select</option>
            <option value="0">None</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              No. of Sisters
            </div>
            <div style={{ flex: 1 }}>
              : {""} {familyDetails.no_sisters || "Not defined"}
            </div>
          </div>
        )}
        {isEditingFamily ? (
           <div className="annual-income" style={{ display: "flex" }}>
           <div style={{ flex: 1 }} className="">
             Family Location
           </div>:
           <div className="basic-profiles" style={{ flex: 1 }}>
          <select
            id="cities"
            name="cities"
            className="SelectBoxs-searchpage-profileedit"
          
            value={familyDetails?.family_location} // Set value to basicDetails.fam_city_id
            onChange={(e) => {
              const selectedfamCity = e.target.value;
              setFamilyDetails({
                ...familyDetails,
                family_location: selectedfamCity,
              });
            }}
          >
            <option value="">Select</option>
            {locations.map((city) => (
              <option key={city.district} value={city.district}>
                {city.district}
              </option>
            ))}
          </select>
          </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Family Location
            </div>
            <div style={{ flex: 1 }}>
              : {familyDetails?.family_location || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default FamilyDetails;
