import React, { useEffect, useState } from "react";
import api from "../../../Api/MatchApi";
import UserCard from "../../UserCard/Index";
import AdCard from "../../AdCards/AdCard";
import ReactPaginate from "react-paginate";
import "../../../style/dropdown.css";
import Chat from "../../Chat";
import GeneralApi from "../../../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";

function EducationMatch() {
  const token = localStorage.getItem("authToken");
  const [educationMatches, setEducationMatches] = useState([]);
  const [educations, setEducations] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [openEducation, setOpenEducation] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchEducationMatches();
  }, [pageDetails, selectedEducation]);

  const fetchEducationMatches = async () => {
    setEducationMatches([]);
    const educationIds = await selectedEducation.map(
      (education) => education?.id
    );
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
      selectedEducation: educationIds,
    };
    await api.getEducationMatches(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setEducationMatches(data?.data?.educationMatches);
        setEducations(data?.data?.education_list);
      }
    });
  };

  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
    setCurrentPage(selected);
  };

  const handleEducationChange = (education) => {
    setOpenEducation(false);
    setCurrentPage(0);
    const findEducation = selectedEducation.find(
      (item) => item?.id === education?.id
    );
    if (!findEducation) {
      setSelectedEducation([...selectedEducation, education]);
    } else {
      const filterEducation = selectedEducation.filter(
        (item) => item?.id !== education?.id
      );
      setSelectedEducation([...filterEducation]);
    }
  };

  const hideProfile = (userId) => {
    const matches = educationMatches.filter(
      (user) => user?.profile_id !== userId
    );
    if (matches.length > 0) {
      setEducationMatches(matches);
    } else {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page !== 1 ? pageDetails?.page - 1 : 1,
      }));
    }
  };

  const handleBlockUser = () => {
    GeneralApi.blockUser(chatData?.userId, token).then(({ data }) => {
      if (data?.status) {
        hideProfile(chatData?.userId);
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setChatData({
          status: false,
          userId: "",
          name: "",
          image: "",
        });
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pictures-client">
        {educations.length > 0 && (
          <div className="full_d">
            <div className="selectplaces">
              {selectedEducation.map((education, index) => (
                <div className="placename" key={index}>
                  <h5>{education?.education}</h5>
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      handleEducationChange(education);
                    }}
                  >
                    <img src="Images/close.svg" alt="" />
                  </button>
                </div>
              ))}
            </div>
            <div className="hero">
              <div className="button-drop-container">
                <form className="button-group">
                  <div className="drop-button-wrap">
                    <input
                      id="db1"
                      type="checkbox"
                      checked={openEducation}
                      onChange={() => setOpenEducation(!openEducation)}
                    />
                    <label htmlFor="db1" className="drop-button">
                      <img
                        style={{ width: "30px" }}
                        src="Images/dropi.svg"
                        alt=""
                      />
                    </label>
                    <div className="drop-pop">
                      <div className="education_name">
                        <h2>Select Education</h2>
                        {educations.map((education, index) => {
                          const isSelected = selectedEducation.find(
                            (item) => item?.id === education?.id
                          );
                          return (
                            <div className="place_d" key={index}>
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                value={education?.id}
                                checked={isSelected ? true : false}
                                onChange={() =>
                                  handleEducationChange(education)
                                }
                              />
                              <h6 style={{ marginBottom: "0" }}>
                                {education?.education}
                              </h6>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {educationMatches?.length > 0 ? (
          educationMatches.map((match, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={match}
                hideProfile={hideProfile}
                chatData={chatData}
                setChatData={setChatData}
              />
              {index === 3 && <AdCard />}
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              {" "}
              No data found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
              forcePage={currentPage}
            />
          </div>
        )}
      </div>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from="PROFILE"
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
}

export default EducationMatch;
