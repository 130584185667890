import React, { useState, useRef, useEffect } from 'react';
//import '../style/displaypage.css'

import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import instance from '../axios';


function Slidermobile() {
  const navigate = useNavigate()
  const menuRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [menuScrollLeft, setMenuScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    menuRef.current.classList.add('active');
    setStartX(e.pageX - menuRef.current.offsetLeft);
    setMenuScrollLeft(menuRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    menuRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    menuRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - menuRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    menuRef.current.scrollLeft = menuScrollLeft - walk;
    if (menuRef.current.scrollLeft <= 0) {
      document.querySelector('.pointer.left-pointer').classList.add('dis');
      setMenuScrollLeft(menuRef.current.scrollLeft);
    } else {
      document.querySelector('.pointer.left-pointer').classList.remove('dis');
    }
    if (menuRef.current.scrollLeft + menuRef.current.offsetWidth >= menuRef.current.scrollWidth) {
      document.querySelector('.pointer.right-pointer').classList.add('dis');
    } else {
      document.querySelector('.pointer.right-pointer').classList.remove('dis');
    }
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);

  const scrollRight = () => {
    menuRef.current.scrollBy(300, 0);
  };

  const scrollLeft = () => {
    menuRef.current.scrollBy(-300, 0);
  };

  const location = useLocation();
  const pathname = location.pathname;

  const handleButtonClick = (e, index) => {
    const button = e.target;
    const scrollLeft = button.offsetLeft - menuRef.current.offsetWidth / 2 + button.offsetWidth / 2;
    menuRef.current.scrollLeft = scrollLeft;
  };

  //counts

  // in single useEffect
  const [profiles, setProfiles] = useState([]);
  const [userNotFound, setUserNotFound] = useState(false);
  const [profilesLength, setProfilesLength] = useState(0);

  const [profiles1, setProfiles1] = useState([]);
  const [userNotFound1, setUserNotFound1] = useState(false);
  const [profilesLength1, setProfilesLength1] = useState(0);

  const [profiles2, setProfiles2] = useState([]);
  const [userNotFound2, setUserNotFound2] = useState(false);
  const [profilesLength2, setProfilesLength2] = useState(0);

  const [profiles3, setProfiles3] = useState([]);
  const [userNotFound3, setUserNotFound3] = useState(false);
  const [profilesLength3, setProfilesLength3] = useState(0);

  const [profiles4, setProfiles4] = useState([]);
  const [userNotFound4, setUserNotFound4] = useState(false);
  const [profilesLength4, setProfilesLength4] = useState(0);

  const [profiles5, setProfiles5] = useState([]);
  const [userNotFound5, setUserNotFound5] = useState(false);
  const [profilesLength5, setProfilesLength5] = useState(0);

  const [profiles6, setProfiles6] = useState([]);
  const [userNotFound6, setUserNotFound6] = useState(false);
  const [profilesLength6, setProfilesLength6] = useState(0);

  const [profiles7, setProfiles7] = useState([]);
  const [userNotFound7, setUserNotFound7] = useState(false);
  const [profilesLength7, setProfilesLength7] = useState(0);

  useEffect(() => {
    const fetchUserDetails = async (endpoint, setState, setUserNotFound, setProfilesLength) => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setState(response.data.data);
          setProfilesLength(response.data.data.length);
        } else if (response.status === 404) {
          setUserNotFound(true);
        } else {
          console.error('Unknown error occurred');
        }
      } catch (error) {
        setUserNotFound(true);
        console.error('Error fetching user details:', error);
      }
    };



    // interest send by me
    fetchUserDetails('/api/pulayakalyanam/interestedProfile', setProfiles, setUserNotFound, setProfilesLength);
    // interest send by others
    fetchUserDetails('/api/pulayakalyanam/interestReceived', setProfiles1, setUserNotFound1, setProfilesLength1);
    // accepted profile by me
    fetchUserDetails('/api/pulayakalyanam/interestAccepted', setProfiles2, setUserNotFound2, setProfilesLength2);
    // accepted profile by me
    fetchUserDetails('/api/pulayakalyanam/interestAcceptedOther', setProfiles3, setUserNotFound3, setProfilesLength3);
    // rejected by me
    fetchUserDetails('/api/pulayakalyanam/interestRejectedOther', setProfiles4, setUserNotFound4, setProfilesLength4);
    // rejected by others
    fetchUserDetails('/api/pulayakalyanam/interestRejected', setProfiles5, setUserNotFound5, setProfilesLength5);
    // shortlist by me
    fetchUserDetails('/api/pulayakalyanam/shortlistedProfile', setProfiles6, setUserNotFound6, setProfilesLength6);
    // shortlist by others
    fetchUserDetails('/api/pulayakalyanam/shortlistother', setProfiles7, setUserNotFound7, setProfilesLength7);

  }, []);


  //new//


  return (
    <div className='menu-wrapper-mobile'>
      <div className="menu-item" ref={menuRef}>
        <Link to="/Matches" >
          <Button type="button"
            as={Link}
            to="/Matches"
            style={{
              borderBottom: pathname === '/Matches' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
              // Set the font size to 14px
            }}
          >  All Matches</Button>
        </Link>

        <Link to="/Newmatches" >
          <Button type="button"
            as={Link}
            to="/Newmatches"
            style={{
              borderBottom: pathname === '/Newmatches' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px', // Set the font size to 14px
            }}
          >New Matches</Button>
        </Link>
        <Link to="/Premiummaches"
          className='matches-buttons'>
          <Button type="button" as={Link} to="/Premiummaches"
            style={{
              borderBottom: pathname === '/Premiummaches' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}
          >Premium Matches</Button>
        </Link>
        <Link to="/Location" >
          <Button type="button" as={Link} to="/Location"
            style={{
              borderBottom: pathname === '/Location' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }} >Location Matches</Button>
        </Link>
        <Link to="/Educationmatches" >
          <Button type="button" as={Link} to="/Educationmatches"
            style={{
              borderBottom: pathname === '/Educationmatches' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}
          >Education Matches</Button>

        </Link>


        <Link to="/Interestedprofiles">
          <Button type="button" as={Link} to="/Interestedprofiles"
            style={{
              borderBottom: pathname === '/Interestedprofiles' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}
          >Interested by Me <span className='count_matches'>({profilesLength})</span></Button>

        </Link>

        <Link to="/Interestrecived">
          <Button type="button" as={Link} to="/Interestrecived"
            style={{
              borderBottom: pathname === '/Interestrecived' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}
          >Intereste Received<span className='count_matches'>({profilesLength1})</span></Button>

        </Link>

        <Link to="/Rejectedprofiles">
          <Button type="button" as={Link} to="/Rejectedprofiles"
            style={{
              borderBottom: pathname === '/Rejectedprofiles' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}>Rejected by Me <span className='count_matches'>({profilesLength4})</span></Button>

        </Link>
        <Link to="/Rejectedbyother">
          <Button type="button" as={Link} to="/Rejectedbyother"
            style={{
              borderBottom: pathname === '/Rejectedbyother' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}>Rejected By Other <span className='count_matches'>({profilesLength5})</span></Button>

        </Link>
        <Link to="/Acceptedprofile">
          <Button type="button" as={Link} to="/Acceptedprofile"

            style={{
              borderBottom: pathname === '/Acceptedprofile' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}>Accepted by Me <span className='count_matches'>({profilesLength2})</span></Button>

        </Link>
        <Link to="/Accetedbyother">
          <Button type="button" as={Link} to="/Accetedbyother"

            style={{
              borderBottom: pathname === '/Accetedbyother' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }}>Accepted By Other <span className='count_matches'>({profilesLength3})</span></Button>

        </Link>
        <Link to="/add-photo">
          <Button type="button" as={Link} to="/add-photo"
            style={{
              borderBottom: pathname === '/add-photo' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }} >Add/Editphoto</Button>

        </Link>
        <Link to="/Shortlistprofiles">
          <Button type="button" as={Link} to="/Shortlistprofiles"
            style={{
              borderBottom: pathname === '/Shortlistprofiles' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }} >shortlisted Me<span className='count_matches'>({profilesLength6})</span></Button>

        </Link>
        <Link to="/Shortlistedbyother">
          <Button type="button" as={Link} to="/Shortlistedbyother"
            style={{
              borderBottom: pathname === '/Shortlistedbyother' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }} >shortlisted By Other<span className='count_matches'>({profilesLength7})</span></Button>

        </Link>
        <Link to="/Hide">
          <Button type="button" as={Link} to="/Hide"
            style={{
              borderBottom: pathname === '/Hide' ? '2px solid #F7AEE2' : 'none',
              fontSize: '14px',
            }} >Hide Profiles</Button>

        </Link>
      </div>
      <span className="pointer left-pointer dis" onClick={scrollLeft}>
        <i className="fa fa-chevron-left" style={{ color: 'black' }}></i>
      </span>
      <span className="pointer right-pointer" onClick={scrollRight}>
        <i className="fa fa-chevron-right" style={{ color: 'black' }}></i>
      </span>

    </div>
  )
}

export default Slidermobile