import React from "react";
import "../style/slider.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Messagemenu = ({ tabActive, changeTabs }) => {
  return (
    <div>
      <div className="menu-wrapper">
        <div className="menu-item">
          <Link
            to="#"
            className={`matches-buttons ${
              tabActive === "requests" ? "active" : ""
            }`}
            onClick={() => changeTabs("requests")}
          >
            <Button type="button" as={Link}>
              Message Requests<span className="count_matches"></span>
            </Button>
          </Link>
          <Link
            to="#"
            className={`matches-buttons ${
              tabActive === "history" ? "active" : ""
            }`}
            onClick={() => changeTabs("history")}
          >
            <Button type="button" as={Link}>
              Chat History<span className="count_matches"></span>{" "}
            </Button>
          </Link>
          <Link
            to="#"
            className={`matches-buttons ${
              tabActive === "ignored" ? "active" : ""
            }`}
            onClick={() => changeTabs("ignored")}
          >
            <Button type="button" as={Link}>
              Ignored Messages<span className="count_matches"></span>{" "}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Messagemenu;
