import React, { useEffect, useState } from "react";
import api from "../../../../Api/InterationApi";
import ReactPaginate from "react-paginate";
import UserCard from "./UserCard";

function Hide() {
  const token = localStorage.getItem("authToken");
  const [hideProfile, setHideProfile] = useState([]);
  
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  useEffect(() => {
   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchHideProfile();
    console.log("da", pageDetails)
  }, [pageDetails]);

  const fetchHideProfile = async () => {
    setHideProfile([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getHideProfile(payload, token).then(({ data }) => {
      if (data.status) {
       setPagination(data?.data?.pagination);
        setHideProfile(data?.data?.hide_user);
      }
    });
  };

  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  const profileHide = (userId) => {
    const newProfiles = hideProfile.filter((user) => user.profile_code !== userId)
    if(newProfiles.length > 0) {
      setHideProfile(newProfiles)
    } else {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page !== 1 ? pageDetails?.page - 1 : 1
      }))
    }
  }

  return (
    <>
      <div className="pictures-client">
        {hideProfile?.length > 0 ? (
          hideProfile.map((interation, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={{
                  name: interation?.name,
                  userId: interation?.profile_code,
                  image: interation?.profile_image,
                  createdBy: interation?.profile_created_for,
                  age: interation?.age,
                  height_cm: interation?.height_cm,
                  height_feet: interation?.height_feet,
                  religion: interation?.religion,
                  education: interation?.education,
                  district: interation?.district,
                  date: interation?.date,
                  occupation: interation?.occupation,
                  gender: interation?.gender,
                }}
                hideProfile={profileHide}
              />
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#640449", marginTop: "1rem" }}>
              {" "}
              No data found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Hide;
