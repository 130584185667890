import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBRipple,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../Api/StoryApi";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function Story({ setSelectTab }) {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [stories, setStory] = useState("");
  const [success_status, setSuccessStatus] = useState("")
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 8,
  });
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchStory();
  }, [pageDetails]);

  const fetchStory = async () => {
    setStory([]);
    const screenWidth = window.innerWidth;

    const payload = {
      page: pageDetails?.page,
      pageSize: screenWidth < 991 && screenWidth > 767 ? 9 : pageDetails.limit,
    };

    await api.getStory(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setStory(data?.data?.success_stories);
        setSuccessStatus(data?.data?.success_status)
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  const handleSelectTab = () => {
    if (!token) {
      navigate("/Login");
    } else {
      setSelectTab("post");
    }
  };

  return (
    <>
      <div className="success-heading">
        <p className="success-headings">Success Story</p>
        <hr className="post-line"></hr>
        {token && !success_status && (
          <p className="post-story cursor-pointer" onClick={handleSelectTab}>
            Post Your Success Story
            <MDBIcon className="pluesborder" fas icon="plus" />
          </p>
        )}
      </div>
      <MDBRow className="sucess-photos">
        {stories.length > 0 ? (
          stories.map((story, index) => (
            <MDBCol
              sm="6"
              md="4"
              lg="3"
              className="sucessfull-cuples"
              key={index}
            >
              <MDBCard className="success-card">
                <MDBRipple
                  rippleColor="light"
                  rippleTag="div"
                  className="bg-image hover-overlay"
                >
                  <MDBCardImage
                      src={
                        story.success_status
                          ? `https://admin.ibgmatrimony.com/assets/success_stories_img/${story.photo}`
                          : `https://ibgmatrimony.com/api/uploads/${story.photo}`
                      }
                    fluid
                    alt="..."
                    className=" success-couplesstory"
                  />
                </MDBRipple>
                <MDBCardBody className="success-card-only text-center">
                  <MDBCardTitle className="success-details">
                    {story.bride_name} & {story.groom_name}
                  </MDBCardTitle>
                  <MDBCardText className="success-details-date">
                    {story.address}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </MDBRow>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Story;
