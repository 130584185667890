import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import UserCardBottom from "./UserCardBottom";

const UserCard = ({ user, hideProfile, chatData, setChatData }) => {
  return (
    <>
      {/* showing in lg */}
      <MDBRow className="matches-container d-none d-lg-flex">
        <MDBCol size="3" className="position-relative">
       
          <div className="phone">
            <a href={`tel:${user?.mobile}`}>
              <img src="/Images/call.png" alt="Call" />
            </a>
          </div>
          <div className={`${user?.profile_image && "matches-border-img"}`}>
          <a href={`profile-details/${user?.profile_id}`}>
            {user?.profile_image ? (
              <img
                src={user?.profile_image}
                alt="Profile Image"
                className="img-fluid"
              />
            ) : (
              <img
                src="default-photo-url.png"
                alt="Default Image"
                className="matches-profileimage"
              />
            )}
            </a>
          </div>
          
        </MDBCol>
        <MDBCol size="1"></MDBCol>
        <MDBCol size="8">
          <div className="danil-sebastine">
            <a href={`profile-details/${user?.profile_id}`}>
              <p className="danil">
                {user?.name}
                <span className="online">
                  <img src="../Images/online.png"></img>
                </span>
              </p>
              <p className="danil-details">
                {user?.profile_id} | Profile Created For {user?.profile_for}
              </p>
              <p className="danil-details">
                {" "}
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              <p className="danil-details">
                {" "}
                {user?.religion}/{user?.caste}
              </p>
              <p className="danil-details">
                {user?.qualification || "education not defined"} ,
                {user?.occupation || "occupation not defined"} ,{user?.district}
              </p>
            </a>
            <hr />
            <UserCardBottom
              user={user}
              hideProfile={hideProfile}
              setChatData={setChatData}
              chatData={chatData}
            />
          </div>
        </MDBCol>
      </MDBRow>
      {/* for responsive */}

      <div className="client_top">
        <div className="client">
          {/*  <div className="phone">
        <a href={`tel:${user?.mobile}`}>
              <img src="/Images/call.png" alt="Call" />
            </a>
          </div> */}
          {/*   {user?.profile_image ? (
            <img
              src={user?.profile_image}
              alt="Profile Image"
              className="img-fluid matches-mobileprofileimage"
            />
          ) : (
            <img src="default-photo-url.png" alt="Default Image" />
          )} */}
          <div className="img_new">
            <div style={{ position: "relative" }}>
              {user?.profile_image ? (
                <img
                  src={user?.profile_image}
                  alt="Profile Image"
                  className=" matches-mobileprofileimage"
                />
              ) : (
                <img src="default-photo-url.png" alt="Default Image" />
              )}
              <a href={`tel:${user?.mobile}`}>
                <img
                  style={{
                    width: "auto",
                    position: "absolute",
                    right: "-1px",
                    bottom: "0px",
                    width: "15px",
                  }}
                  src="Images/call.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="danil-sebastine">
            <a href={`profile-details/${user?.profile_id}`}>
              <p className="danil">
                {user?.name}
                <span className="online-mobile">
                  <img src="../Images/online.png"></img>
                </span>
                <span className="partion"> | </span>{" "}
                <span className="active-online">
                  <span className="dot"></span> online
                </span>{" "}
              </p>
              <p className="danil-details-mobile">
                {user?.profile_id}{" "}
                {/* | Profile Created For {user?.profile_for} */}
              </p>
              <p className="danil-details-mobile">
                {user?.age} Yrs,{" "}
                {user?.height_feet || user?.height_cm
                  ? `${user?.height_feet || "height"} / ${
                      user?.height_cm || "height"
                    } cm`
                  : "height not defined"}
              </p>
              {/*  <p className="danil-details-mobile">
                {user?.religion}/{user?.caste}
              </p> */}
              <p className="danil-details-mobile">
                {user?.qualification || "education not defined"} ,
                {user?.occupation || "occupation not defined"} ,{user?.district}
              </p>
            </a>
          </div>
        </div>
        <hr></hr>
        <UserCardBottom
          user={user}
          hideProfile={hideProfile}
          setChatData={setChatData}
          chatData={chatData}
        />
      </div>
    </>
  );
};

export default UserCard;
