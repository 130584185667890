import React, { useEffect, useState } from "react";

import "../style/noimage.css";
import instance from "../axios";
import CustomModal from "./CustomModel";
import CropPhoto from "./Modals/CropPhoto";

const NoImages = ({ fetchUserImages, setImageData }) => {
  const [selectedImage, setSelectedImage] = useState({
    imageUrl: "",
    file: "",
  });
  const [cropedImage, setCropedImage] = useState(null);
  const [noImageError, setNoImageError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [closeCropModal, setCloseCropModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [imageUrl, setImageurl] = useState("");

  useEffect(() => {
    console.log("cdd", selectedImage);
  }, [selectedImage]);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const isAllowedFileType = (file) => {
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes("." + fileExtension);
  };

  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!isAllowedFileType(file)) {
        setNoImageError("Only .jpeg, .jpg, and .png image files are allowed.");
        setSelectedImage({
          imageUrl: "",
          file: "",
        });
        return;
      }
      if (!isFileSizeValid(file)) {
        setNoImageError("File size should be no larger than 5MB.");
        setSelectedImage({
          imageUrl: "",
          file: "",
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage({
          imageUrl: reader.result,
          file: file,
        });
        setNoImageError("");
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUpload = async (event) => {
    event.preventDefault();

    try {
      if (!cropedImage) {
        setNoImageError("No image selected!");
        return;
      }

      const formData = new FormData();
      const profileImage = document.querySelector("#profileImage");
      const profileImageFile = profileImage.files[0];
      formData.append("photo", profileImageFile);

      const token = localStorage.getItem("authToken");

      const response = await instance.post("/api/photo-upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const imageURL = response.data.data.image;
        setImageurl(imageURL);
        setModalTitle("Success");
        setModalMessage(response.data.message);
      } else {
        setModalTitle("Error");
        setModalMessage(response.data.message);
      }

      setShowModal(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setModalTitle("Error");
      setModalMessage("Error uploading image. Please try again later.");
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    fetchUserImages();
    setShowModal(false);
  };

  return (
    <div className="noimagecontainer">
      <div className="right_image">
        <div className="file-upload">
          <input
            className="file-input form-control"
            type="file"
            name="images[]"
            id="profileImage"
            accept="image/x-png,image/jpg,image/jpeg"
            multiple=""
            required=""
            onChange={handleImageChange}
          />

          {cropedImage ? (
            <img
              style={{ width: "150px", height: "190px" }}
              src={cropedImage}
              alt=""
            />
          ) : (
            <img
              style={{ width: "150px", height: "190px" }}
              src="Images/upload.png"
              alt=""
            />
          )}

          <div style={{ color: "#A7A5A3" }} className="card-subtitle">
            Drag/Drop your image here
          </div>
          <br />
          {noImageError && (
            <label className="error_class">{noImageError}</label>
          )}
        </div>
      </div>
      <div className="left_image">
        <h3>Add your photo and get much better responses!</h3>
        <p>Upload Within 15 MB of size | jpg / gif / png </p>

        <button onClick={handleUpload}>Upload</button>
      </div>
      {selectedImage.file && (
        <CropPhoto
          selectedImage={selectedImage}
          onClose={() =>
            setSelectedImage({
              imageUrl: "",
              file: "",
            })
          }
          setCropedImage={setCropedImage}
        />
      )}
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        message={modalMessage}
      />
    </div>
  );
};

export default NoImages;
