import Navbars from './Navbars'
import '../style/profileedit.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button, Container } from 'react-bootstrap';

import Footer from './Footer'
import DatePicker from 'react-datepicker';
import '../style/datePicker.css';

import { useNavigate, useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import instance from '../axios';
import { MDBIcon } from 'mdb-react-ui-kit';
import Chat from './Chat';
import Footerinner from './Footerinner';
import axios from 'axios';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebase';


function Profiledetails() {
  const firebaseDB = getFirestore(firebaseApp)
  const { userId } = useParams();
  const [userPhoto, setuserPhoto] = useState(null);
  const [isUserOnline, setIsUserOnline] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/login');
        return;
      }

      const tokenData = parseToken(token);

      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (tokenData.exp < currentTime) {
          // Token has expired, clear it and navigate to login
          clearToken();
          navigate('/login');
        }
      }

    };

    checkTokenExpiration();
  }, [navigate]);

  useEffect(() => {
    const onGetOnlineStatus = onSnapshot(
      doc(firebaseDB, "users", userId),
      (docData) => {
        if (docData.exists()) {
          if (docData.data().status === "ONLINE") {
            setIsUserOnline(true)
          } else {
            setIsUserOnline(false)
          }
        } else {
          setIsUserOnline(false)
        }
      }
    )

    return () => {
      onGetOnlineStatus()
    }
  }, [])


  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }


  function clearToken() {
    localStorage.removeItem('authToken');

  }

  // DATE PICKER TESTING
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const minDate = new Date(today.getFullYear() - 90, today.getMonth(), today.getDate());

  const [selectedDate, setSelectedDate] = useState(null); // State to manage selected date
  const [validationError, setValidationError] = useState(false); // State for validation error




  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    // Any additional logic related to date change
  };

  // backend
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewedUserProfile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        const fetchedUserData = response.data.userData;

        // Set the fetched user data to the state
        setUserDetails(fetchedUserData);

        // Log the user data
       // console.log('Fetched User Data:', fetchedUserData);

      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const [userData, setUserData] = useState(null);
  const [partnerpercentage, setPartnerpercentage] = useState(null);
  //view other user details
  //console.log("user id :", userId);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get(`/api/pulayakalyanam/viewotherprofileDetails/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        const fetchedUserData = response.data.userData;
        console.log('User Names:', fetchedUserData.c_name);

        // Set the fetched user data to the state
        setUserData(fetchedUserData);

      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [userId]);

  // photos of other profile details

  const [userPhotos, setUserPhotos] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          localStorage.removeItem('authToken');
          navigate('/login');
          return;
        }

        const response = await instance.get(`/api/pulayakalyanam/viewallphoto_otherse/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        
        setUserPhotos(response.data.data);
       

        // Assuming you have a way to determine the selected user (e.g., userId)
        setSelectedUserId(userId);

      } catch (error) {
        //console.error('Error fetching user newmatches details:', error);
      }
    };

    fetchUserDetails();
  }, [userId]);



  // partner percentage//
  useEffect(() => {
    const fetchPercentage = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get(`/api/pulayakalyanam/partnerpercentage/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('partner percentage', response);
        const fetchedPercentageData = response.data;

        // Set the fetched user data to the state//
        setPartnerpercentage(fetchedPercentageData);
        
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    if (userId) {
      fetchPercentage();
    }
  }, [userId]);


  // like profile
  const [isProfileLiked, setIsProfileLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch liked profiles when the component mounts
    const fetchLikedProfiles = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/getLikedProfiles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          // Check if the current profile is in the list of liked profiles
          const likedProfileIds = response.data.likedProfileIds;
          setIsProfileLiked(likedProfileIds.includes(userId));
        } 
      } catch (error) {
        //console.error('Error fetching liked profiles', error);
      }
    };

    fetchLikedProfiles();
  }, [userId]);

  const likeProfile = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const token = localStorage.getItem('authToken');
      if (!token) {
        
        return;
      }

      const response = await instance.post(`/api/pulayakalyanam/interestSend/${userId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

      });

      if (response.status === 200) {
        // Toggle the liked state
        setIsProfileLiked(!isProfileLiked);
      } 
    } catch (error) {
      //console.error('Error liking/unliking the profile', error);
    } finally {
      setIsLoading(false);
    }
  };

  //shortlist
  const [isLoadingShort, setIsLoadingShort] = useState(false);
  const [isProfileShortlist, setIsProfileShortlist] = useState(false);


  useEffect(() => {
    const fetchShortProfiles = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/getShortlistProfiles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const likedProfilesData = response.data.likedProfilesData;
         

          // Check if userId (c_usercode) exists in the array
          const isProfileShortlisted = likedProfilesData.some(profile => profile.c_usercode === userId);


          setIsProfileShortlist(isProfileShortlisted);
         
        }
      } catch (error) {
        //console.error('Error fetching shortlist profiles', error);
      }
    };

    fetchShortProfiles();
  }, [userId]);

  const shortProfile = async () => {
    if (isLoadingShort) {
      return;
    }

    try {
      setIsLoadingShort(true);

      const token = localStorage.getItem('authToken');
      if (!token) {
       
        return;
      }

      const response = await instance.post(`/api/pulayakalyanam/shortlist/${userId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Toggle the shortlisted status in the state
        setIsProfileShortlist(!isProfileShortlist);
      } 
    } catch (error) {
      //console.error('Error shortlisting the profile', error);
    } finally {
      setIsLoadingShort(false);
    }
  };

  //hide user
  const [isLoadingHide, setIsLoadingHide] = useState(false);
  const [isProfileHide, setIsProfileHide] = useState(false);

  const hideProfile = async () => {
    if (isLoadingHide) {
      return;
    }

    try {
      setIsLoadingHide(true);

      const token = localStorage.getItem('authToken');
      if (!token) {
       
        return;
      }

      const response = await instance.post(`/api/pulayakalyanam/hideUser/${userId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setIsProfileHide(true);
      } 
    } catch (error) {
      //console.error('Error hiding the profile', error);
    } finally {
      setIsLoadingHide(false);
    }
  };


  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/dailymatches', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        
        setProfiles(response.data.data); // Assuming profiles are in response.data.data

      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);


  // partner matrial matches 
  const [matchStatus, setMatchStatus] = useState(null)

  useEffect(() => {
    const fetchmatchMatrialStatus = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }

        const response = await instance.get(`/api/pulayakalyanam/matrialStatus_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { status } = response.data;
        
        setMatchStatus(status);
       
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    if (userId) {
      fetchmatchMatrialStatus();
    }
  }, [userId]);

  // partner eating match
  const [eatStatus, seteatStatus] = useState(null)

  useEffect(() => {
    const fetchmatchEat = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          
          return;
        }

        const response = await instance.get(`/api/pulayakalyanam/eating_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { status } = response.data;
        seteatStatus(status);
        
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    if (userId) {
      fetchmatchEat();
    }
  }, [userId]);

  // partner drinking match
  const [drinkStatus, setdrinkStatus] = useState(null)

  useEffect(() => {
    const fetchmatchDrink = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/drink_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setdrinkStatus(status);
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchDrink();
    }
  }, [userId]);

  // partner smoking match
  const [smokeStatus, setsmokeStatus] = useState(null)

  useEffect(() => {
    const fetchmatchSmoke = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/smoke_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setsmokeStatus(status);
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchSmoke();
    }
  }, [userId]);

  // partner physical match
  const [physicalStatus, setphysicalStatus] = useState(null)

  useEffect(() => {
    const fetchmatchPhysical = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/physical_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setphysicalStatus(status);
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchPhysical();
    }
  }, [userId]);

  // partner professional match
  const [professionalStatus, setprofessionalStatus] = useState(null)

  useEffect(() => {
    const fetchmatchProfessional = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/professional_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setprofessionalStatus(status);
      } catch (error) {
       // console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchProfessional();
    }
  }, [userId]);

  // partner height match
  const [heightMatch, setHeightmatch] = useState(null)

  useEffect(() => {
    const fetchmatchHeight = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/height_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setHeightmatch(status);
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchHeight();
    }
  }, [userId]);

  // partner age match
  const [ageMatch, setAgematch] = useState(null)

  useEffect(() => {
    const fetchmatchAge = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          
          return;
        }
        const response = await instance.get(`/api/pulayakalyanam/age_match/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const { status } = response.data;
        setAgematch(status);
      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchmatchAge();
    }
  }, [userId]);
  //liked//

  const [isLiked, setIsLiked] = useState(false);
  //chat//



  const [activeChatProfile, setActiveChatProfile] = useState(null);

  const handleImageClick = (profileId) => {
    setActiveChatProfile(profileId);
  };

  const handleCloseChat = () => {
    setActiveChatProfile(null);
  };

  //photo//
  //photo view
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
         
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewphoto', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

       
        setuserPhoto(response.data.data);
       

      } catch (error) {
        //console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  // shortlist
  const [shortlistProfileIds, setShortlistProfileIds] = useState([]);
  const [isLoadingshortlist, setIsLoadingshortlist] = useState(false);


  useEffect(() => {
    const fetchShortlistProfiles = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
         
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/getShortlistProfilesView', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setShortlistProfileIds(response.data.likedProfileIds);
         

        } 
      } catch (error) {
        //console.error('Error fetching shortlisted profiles', error);
      }
    };

    fetchShortlistProfiles();
  }, []);

  const shortlistProfile = async (userCode) => {
    if (isLoadingshortlist) {
      return;
    }

    try {
      setIsLoadingshortlist(true);

      const token = localStorage.getItem('authToken');
      if (!token) {
       
        return;
      }

      const response = await instance.post(`/api/pulayakalyanam/shortlist/${userCode}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {

        if (shortlistProfileIds.includes(userCode)) {

          setShortlistProfileIds(shortlistProfileIds.filter((id) => id !== userCode));
        } else {

          setShortlistProfileIds([...shortlistProfileIds, userCode]);
        }
      } 
    } catch (error) {
      //console.error('Error shortlisting/unshortlisting the profile', error);
    } finally {
      setIsLoadingshortlist(false);
    }
  };
  //new//
  const [imageData, setImageData] = useState();
  const [isProfileImageUploaded, setIsProfileImageUploaded] = useState(false);
  const [isFullImageUploaded, setIsFullImageUploaded] = useState(false);
  const [isHeadshotImageUploaded, setIsHeadshotImageUploaded] = useState(false);


  const [imageDatastatus, setImageDatastatus] = useState(null);
  const fetchUserImages = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
       
        navigate('/login');
        return;
      }

      const response = await instance.get(
        'api/pulayakalyanam/photo/viewuploadPhoto',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const fetchedImagesData = response.data.data
      setImageData(fetchedImagesData);
      setImageDatastatus(fetchedImagesData[0].p_status)
      

      if (fetchedImagesData.length >= 3) {
        const profileImageUrl = fetchedImagesData[0].c_profile_image
        const fullImageUrl = fetchedImagesData[1].c_profile_image
        const headshotImageUrl = fetchedImagesData[2].c_profile_image
      }
    
    } catch (error) {
      //console.error('Error fetching user images:', error);
    }
  };

  useEffect(() => {
    fetchUserImages();
  }, []);

  



  return (
    <div className='matches-background'>
      <Navbars></Navbars>
      <Container >

        <MDBRow className='profileedit-matches-all'>
          <MDBCol size='6' sm='3' className=' profiledetails-full'>

            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  {/* <div className='phone-call'>
        <img src='../Images/call.png'></img>
        </div> */}
                  <Carousel className='similar-photos'>
                  
                    {userPhotos ? (
                      userPhotos.map((photo) => (
                        <img

                          src={photo.c_profile_image}
                          className='img-fluid searchemployee'
                          alt='User'
                          style={{ maxWidth: '80%', maxHeight: '100%',marginTop:'15%' }}
                        />
                      ))
                    ) : (
                      <img
                        src='/default-photo-url.png'
                        className='img-fluid searchemployee'
                        alt='Default'
                        style={{ maxWidth: '80%', maxHeight: '100%', marginTop:'15%'  }}
                      />
                    )}
                  </Carousel>


                  <div className=' editprofiletyping similer-profile-profileedit'>
                    <p className='similer-properties'>Similar Profiles</p><hr></hr>

                    {profiles.length > 0 ? (
                      profiles.map((profile) => (

                        <MDBRow className='similer-profile-profileedit'>
                          <MDBCol size='6' sm='4' >
                            <img src={profile.c_profile_image} className='similer-images img-fluid'></img>
                            <img src='/default-photo-url.png' className='similer-images img-fluid' />
                          </MDBCol>
                          <MDBCol size='6' sm='8' className='editprofile-only'>
                            <p className='editprofile-name'>{profile.c_name}</p>
                            <p className='editprofile-id'>{profile.c_usercode}|Profile Created by {profile.profile_for},<br></br>
                              {profile.n_age}Yrs, {profile.n_feet || profile.n_cm ? `${profile.n_feet || 'height'} / ${profile.n_cm || 'height'} cm` : 'height not defined'}</p>
                            <a className='editprofile-seemore' href={`${profile.c_usercode}`}>See More</a>


                            {/* <p className='editprofile-seemore'>See More</p> */}
                          </MDBCol>
                        </MDBRow>

                      ))
                    ) : (
                      <p>Loading...</p>
                    )}

                    <hr></hr>

                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>


          <MDBCol size='6' sm='9' className='profiledetails-full'>
            <p className='profile-editheading'>Profile View</p>
            {userData && (
              <>
                <p className='danils'>
                  {userData.c_name}<span className='online'><img src='../Images/online.png'></img></span>
                  {isUserOnline ? <span className='active-online'><span className="dot"></span> Online</span> : <span className='active-offline'><span className="active-offline-dot"></span> Offline</span>}
                </p>
                <MDBRow className='profile-row'>
                  <MDBCol >

                    <p className='mother-tongue'>{userData.n_age} Yrs | {userData.c_usercode}</p>
                    <p className='mother-tongue'>   {userData.n_cm ? `${userData.n_cm} cm / ${userData.n_feet}` : 'height not defined'}</p>

                    <p className='mother-tongue'>{userData.religion || 'Religion not defined'} / {userData.caste || 'Caste not defined'}</p>
                    <p className='mother-tongue'>Occupation in {userData.occupation || 'Not defined'}</p>
                  </MDBCol>
                  <MDBCol >
                    <p className='mother-tongue'>Star is {userData.c_star_name || 'Not defined'}</p>

                    <p className='mother-tongue'>Lives in {userData.c_district || 'Not defined'}</p>
                    <p className='mother-tongue'>Studied {userData.qualification || 'Not defined'}</p>
                  </MDBCol>
                </MDBRow>
                <p className='short-links'><a href='#' onClick={(e) => { e.preventDefault(); handleImageClick(userData.c_usercode) }}>
                  Chat
                  <span className='chat'>
                    <img src='../Images/chat (2).png' alt='Chat Icon' />
                  </span>
                </a>
                  {activeChatProfile === userData.c_usercode && (
                    <Chat 
                      chatData={{
                        userId: userData?.c_usercode,
                        name: userData?.c_name,
                        image: userPhotos[0]?.c_profile_image
                    } } 
                    onClose={handleCloseChat}
                    from="PROFILE" />
                  )}

                  <a href='#' onClick={(e) => { e.preventDefault(); shortlistProfile(userData.c_usercode) }}>
                    <span className={`shortlist ${shortlistProfileIds.includes(userData.c_usercode) ? 'shortlisted' : ''}`}>
                      {isLoadingshortlist ? 'Shortlisting...' : shortlistProfileIds.includes(userData.c_usercode) ? 'Shortlisted' : 'Shortlist?'}
                      <span className="chat">
                        {shortlistProfileIds.includes(userData.c_usercode) ? (
                          <MDBIcon fas icon="heart" /> // Shortlisted
                        ) : (
                          <MDBIcon far icon="heart" /> // Not Shortlisted
                        )}
                      </span>
                    </span>
                  </a>

                  <a href='#' onClick={(e) => { e.preventDefault(); hideProfile(userData.c_usercode); }}>
                    <span className={`shortlist ${isProfileHide ? 'hide' : ''}`}>
                      {isLoading ? 'hiding...' : isProfileHide ? 'Hide' : 'Hide?'}
                      <span className='chat'>
                        <img src='../Images/hide (2).png' />
                      </span>
                    </span>
                  </a>


                  <a href='#' onClick={likeProfile}>
                    <span className={`shortlist ${isProfileLiked ? 'liked' : ''}`}>
                      {isLoading ? 'Liking...' : isProfileLiked ? 'Liked' : 'Like His Profile?'}
                      <span className='chat'>
                        {isLoading ? (
                          'Liking...'
                        ) : isLiked ? (
                          <MDBIcon icon="thumbs-up" />
                        ) : (
                          <MDBIcon far icon="thumbs-up" />
                        )}
                      </span>
                    </span>
                  </a>
                </p>
                <div className='profile-editprofile'>
                  <p className='profile-description-profiledetails'>Profile Description</p>
                  <p className='profile-des'>{userData.c_prof_description || 'not defined'}</p>
                  <p className='profile-description' >Basic Details</p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Name
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_name}
                        </div>
                      </div>

                      {/*  <p className="basicdetails">Name <span className="namespace">: {userData.c_name}</span></p> */}
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Age
                        </div>
                        <div style={{ flex: 1 }}>
                          :{' '}
                          {userData && userData.n_age ? `${userData.n_age} Years` : 'not defined'}
                        </div>
                      </div>
                      {/*  <p className="basicdetails">Age <span className="agespace">: {userData.n_age || ' not defined'} Years</span></p> */}
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Height
                        </div>
                        <div style={{ flex: 1 }}>
                          :{' '}
                          {userData && userData.n_feet ? `${userData.n_feet} feet` : 'not defined'} ,{' '}
                          {userData && userData.n_cm ? `${userData.n_cm} cm` : 'height not defined'}
                        </div>
                      </div>
                      {/*  <p className="basicdetails">Height <span className="heightspace">: {userData.n_feet || 'not defined'} , {userData.n_cm || 'height not defined'} cm</span></p> */}
                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Gender
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_gender || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Marital Status
                        </div>
                        <div style={{ flex: 1 }}>
                          :{' '}
                          {userData && userData.c_marital_status ? userData.c_marital_status : 'not defined'}
                        </div>
                      </div>
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Physical Status
                        </div>
                        <div style={{ flex: 1 }}>
                          :{' '}
                          {userData && userData.c_physical_status ? userData.c_physical_status : 'not defined'}
                        </div>
                      </div>



                    </MDBCol>
                  </MDBRow><hr></hr>
                  <p className='profile-description'>Religious Details </p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6"> {/* Use col-12 for mobile view and col-md-6 for larger screens */}

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Religion
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.religion || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Caste
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.caste || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                  </MDBRow><hr></hr>
                  <p className='profile-description'>Professional Details</p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Education
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.qualification || ' not defined'}
                        </div>
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Employed in
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.employed_in || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Occupation details
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.occupation || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Annual income
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.annual_income || ' not defined'}
                        </div>
                      </div>


                    </MDBCol>
                  </MDBRow><hr></hr>
                  <p className='profile-description'>Location Details</p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">


                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          State
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_state || ' not defined'}
                        </div>
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          District
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_district || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          City
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_area_name || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                  </MDBRow><hr></hr>
                  <p className='profile-description'>Family Details</p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Family value
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_family_value || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Father's Occupation
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_father_occupation || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          No. of Brothers
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.n_no_brothers || ' not defined'}
                        </div>
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Family Type
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.c_family_type || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Family Status
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.c_family_status || ' not defined'}
                        </div>
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Mother's Occupation
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.c_mother_occupation || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          No. of Sisters
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {userData.n_no_sisters || ' not defined'}
                        </div>
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Family Location
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.c_family_loc || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>

                  </MDBRow>
                  <hr></hr>
                  <p className='profile-description'>Hobbies</p>
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Sports
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.sports || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Art
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''}  {userData.arts || ' not defined'}
                        </div>
                      </div>

                    </MDBCol>
                  </MDBRow>

                </div>
              </>
            
            )}

            {partnerpercentage ? (
              <>
                <p className='partner-preference-profileedit'>Partner Preference Matches</p>

                <div className='importentdetails-editprofile'>

                  <p className='matching-arrow'><span className='matching-percentage'>Profile Matching
                    <span className='colorpercentage'> {partnerpercentage.percentage || '0'}% </span></span><br></br>
                    {userPhoto ? (
                      <img src={userPhoto.c_profile_image} className='img-fluid searchemployee'
                        alt='User'
                        style={{ borderRadius: '50%', width: '40px' }} />
                    ) : (
                      <img src='/default-photo-url.png' className='img-fluid searchemployee'
                        alt='Default'
                        style={{ borderRadius: '50%', width: '40px' }} />
                    )}

                    <img src='../Images/Line 21.png' className='side-arrow'></img>
                    {userPhotos ? (
  <img
    src={userPhotos.c_profile_image}
    className='img-fluid searchemployee'
    alt='User'
    style={{ borderRadius: '50%', width: '40px' }}
  />
) : (
  <img
    src='/default-photo-url.png'
    className='img-fluid searchemployee'
    alt='Default'
    style={{ borderRadius: '50%', width: '40px' }}
  />
)}</p>


                  <MDBRow >

                    <p className='profile-description'>Basic Preference</p>
                    <MDBCol className=' col-12 col-md-6 preference-all'>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        {ageMatch === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Age
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.n_age || userData.partner_age}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {ageMatch === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Age
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.n_age || 'not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        {heightMatch === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Height
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.n_cm || userData.partner_height}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {heightMatch === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Height
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.n_cm || 'not defined'} cm
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        {eatStatus === 'green_tick' && (
                          <>
                            <div style={{ flex: 2 }} className="full-bolder">
                              Eating Habits
                            </div>
                            <div style={{ flex: 2 }}>
                              :{' '}
                              {userData && userData.c_eating_habit !== null
                                ? userData.c_eating_habit || userData.partner_eatingHabit || userDetails.partner_eatingHabit
                                : 'Not defined'}
                            </div>
                            <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                              ✅
                            </div>
                          </>
                        )}

                        {eatStatus === 'red_mark' && (
                          <>
                            <div style={{ flex: 2 }} className="full-bolder">
                              Eating Habits
                            </div>
                            <div style={{ flex: 2 }}>
                              :{' '}
                              {userData && userData.c_eating_habit !== null
                                ? userData.c_eating_habit || userData.partner_eatingHabit
                                : 'Not defined'}
                            </div>
                            <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                              ❌
                            </div>
                          </>
                        )}
                      </div>



                      <div className='annual-income' style={{ display: 'flex' }}>
                        {smokeStatus === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Smoking Habits
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''}
                            {userData && userData.c_smoking_habit !== null
                              ? userData.c_smoking_habit || userData.partner_smokingHabit || userDetails.partner_smokingHabit
                              : 'Not defined'}


                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {smokeStatus === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Smoking Habits
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {/* {userData.c_smoking_habit || 'not defined'}  */}
                            {userData && userData.c_smoking_habit !== null
                              ? userData.c_smoking_habit || userData.partner_smokingHabit
                              : 'Not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>


                    </MDBCol>
                    <MDBCol className=' col-12 col-md-6 preference-all'>
                      <div className='annual-income' style={{ display: 'flex' }}>
                        {drinkStatus === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Drinking Habits
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.c_drinking_habit || userData.partner_drinkingHabit || userDetails.partner_drinkingHabit}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {drinkStatus === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Drinking Habits
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.c_drinking_habit || 'not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>

                      <div className='annual-income' style={{ display: 'flex' }}>
                        {matchStatus === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Marital Status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.c_marital_status || userData.partner_matrialStaus || userDetails.partner_matrialStatus}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {matchStatus === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Matrial Status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''}  {userData.c_marital_status || 'not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>




                      <div className='annual-income' style={{ display: 'flex' }}>
                        {physicalStatus === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Physical status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.c_physical_status || userData.partner_physicalStatus || userDetails.partner_physical_status}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {physicalStatus === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Physical status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.c_physical_status || 'not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>


                      <div className='annual-income' style={{ display: 'flex' }}>
                        {professionalStatus === 'green_tick' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Professional status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.occupation || userData.partner_professionalStatus || userDetails.partner_professional_Status}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="green tick" className='check-image'>
                            ✅
                          </div>
                        </>)}


                        {professionalStatus === 'red_mark' && (<>
                          <div style={{ flex: 2 }} className="full-bolder">
                            Professional status
                          </div>
                          <div style={{ flex: 2 }}>
                            :{''} {userData.occupation || 'not defined'}
                          </div>
                          <div style={{ flex: 1 }} role="img" aria-label="red mark" className='check-image'>
                            ❌
                          </div>
                        </>)}
                      </div>






                    </MDBCol>
                  </MDBRow>



                </div>
              </>
            ) : (
              <p></p>
            )}
          </MDBCol>



        </MDBRow>
      </Container>
      <Footerinner></Footerinner>

    </div>
  )
}

export default Profiledetails