import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBRipple } from "mdb-react-ui-kit";
import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

const SuccessStories = ({ successStories }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="success-cuples">
      <p className="who-matches">
        <span className="new-matches-only"> Success Stories</span>- We organise
        more marriages than anyone else in the world!
      </p>
      {/* <Container className="slider-highlight">
        <Slider className="third-slider " {...settings}>
          {successStories.map((story, index) => (
            <MDBCard
              className="cardonly-highlight"
              style={{ width: "50%" }}
              key={index}
            >
              <MDBCardImage
                src={story}
                position="top"
                className="home-successphoto"
                alt={`Success Story ${index}`}
              />
            </MDBCard>
          ))}
        </Slider>
      </Container> */}


<Container className="slider-highlight">
              <Slider className="third-slider " {...settings} >
              {successStories.map((story, index) => (
                  <MDBCol
                    size="6"
                    sm="3"
                    className="cardonly-highlight"
                    key={index}
                  >
                
                        <MDBCardImage
                         src={
                          story.status
                            ? `https://admin.ibgmatrimony.com/assets/success_stories_img/${story.photo}`
                            : `https://ibgmatrimony.com/api/uploads/${story.photo}`
                        }
                         
                          fluid
                          alt={`Success Story ${index}`}
                          className="success-couples"
                        />
                     
                  </MDBCol>
                ))}
              </Slider>
            </Container>
    </div>
  );
};

export default SuccessStories;
