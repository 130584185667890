import React from "react";
import {
  MDBCol,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
} from "mdb-react-ui-kit";
import { Button, Container } from "react-bootstrap";
import "../../style/searchprofile.css";

function SearchValueResult({ data }) {
  if (!data === 0) {
    return <div>No data availables</div>;
  }

  return (
    <div>
      <Container className="search-border-new">
        {data.map((userData, index) => (
          <>
            <MDBRow
              key={index}
              className="liked-profile"
              style={{ marginLeft: "3%" }}
            >
              <MDBCol size="3" className="linked-with-allitems">
                {userData.profile_image ? (
                  <img
                    src={userData.profile_image}
                    alt="Profile Image"
                    className="profile-image matches-profileimage"
                  />
                ) : (
                  <img
                    src="default-photo-url.png"
                    alt="Default Image"
                    className="matches-profileimage"
                  />
                )}
              </MDBCol>
              <MDBCol size="9" className="linked-with-all">
                <div className="danil-sebastine">
                  <p className="danil">
                    {userData.name}
                    <span className="online">
                      <img src="../Images/online.png" alt="Online" />
                    </span>
                  </p>
                  <p className="danil-details">
                    {userData.profile_code} | Profile Created For{" "}
                    {userData.profile_created_for}
                  </p>
                  <p className="danil-details">
                    {userData.age || "Age in"}Yrs,{" "}
                    {userData.height_feet || userData.height_cm
                      ? `${userData.height_feet || "height"} / ${
                          userData.height_cm || "height"
                        }`
                      : "Height not defined"}
                  </p>
                  <p className="danil-details">
                    {userData.religion}/{userData.caste}
                    {userData.star &&
                      userData.star !== "Not Defined" &&
                      `/${userData.star}`}
                  </p>
                  <p className="danil-details">
                    {userData.education || "Education"},{" "}
                    {userData.occupation || "Occupation"},{" "}
                    {userData.district || "District"}{" "}
                  </p>

                  <a href={`profile-details/${userData.profile_code}`} target="#blank">
                    <p className="singlefulldetails">View Full Profile</p>
                  </a>
                </div>
              </MDBCol>
            </MDBRow>

            <div className="client_top">
              <div className="client">
                {userData.profile_image ? (
                  <img src={userData.profile_image} alt="Profile Image" />
                ) : (
                  <img src="default-photo-url.png" alt="Default Image" />
                )}
                <div className="danil-sebastine">
                  <p className="danil">
                    {userData.name}
                    <span className="online-mobile">
                      <img src="../Images/online.png"></img>
                    </span>
                  </p>
                  <p className="danil-details">
                    {" "}
                    {userData.profile_code} | Profile Created For{" "}
                    {userData.profile_created_for}{" "}
                  </p>
                  <p className="danil-details">
                    {userData.age || "Age in"}Yrs,{" "}
                    {userData.height_feet || userData.height_cm
                      ? `${userData.height_feet || "height"} / ${
                          userData.height_cm || "height"
                        }`
                      : "Height not defined"}
                  </p>
                  <p className="danil-details">
                    {" "}
                    {userData.religion}/{userData.caste}{" "}
                    {userData.star &&
                      userData.star !== "Not Defined" &&
                      `/${userData.star}`}
                  </p>
                  <p className="danil-details">
                    {userData.education || "Education"},{" "}
                    {userData.occupation || "Occupation"},{" "}
                    {userData.district || "District"}{" "}
                  </p>

                  <a href={`profile-details/${userData.profile_code}`} target="#blank">
                    <p className="singlefulldetails">View Full Profile</p>
                  </a>
                </div>
              </div>
            </div>
            {/*end*/}
          </>
        ))}
      </Container>
    </div>
  );
}

export default SearchValueResult;
