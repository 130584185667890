import { MDBCol } from "mdb-react-ui-kit";
import React, { useState } from "react";
import Slidermatches from "./SliderMatches";
import All from "./Match/All";
import NewMatch from "./Match/NewMatch";
import PremiumMatch from "./Match/PremiumMatch";
import LocationMatch from "./Match/LocationMatch";
import EducationMatch from "./Match/EducationMatch";

const MatchSection = () => {
  const [selectedTab, setSelectedTab] = useState("All Matches");
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="member-who-waches">
        <p className="member-watches">
          <span className="new-matches-only">{selectedTab}</span> - Members who
          matches your preference
        </p>
      </div>
      <Slidermatches
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === "All Matches" && <All />}
      {selectedTab === "New Matches" && <NewMatch />}
      {selectedTab === "Premium Matches" && <PremiumMatch />}
      {selectedTab === "Location Matches" && <LocationMatch />}
      {selectedTab === "Education Matches" && <EducationMatch />}
    </MDBCol>
  );
};

export default MatchSection;
