import React from "react";

const ImageModal = ({ onClose, children }) => {
  return (
    <div
      className="modal fade show d-flex justify-content-center"
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ backgroundColor: "#282828" }}>
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              type="button"
              className="close border-0"
              style={{
                color: "white",
                background: "none",
                fontSize: "24px",
                lineHeight: "10px",
              }}
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
